<div class="header">
    <img src="../../../assets/images/logo/Login_logo.png" alt="Going Places" class="responsive" 
        (click)="goHome()" style="cursor: pointer;">
    <nav class="navbar">
        <button type="button" class="btn btn-primary btn-nav-bar" (click)="logout()" *ngIf="showLogout">Log Out</button>
        <button type="button" class="btn btn-primary btn-nav-bar" (click)="goHome()" *ngIf="showBack">Exit</button>
        <button type="button" class="btn btn-primary btn-nav-bar" (click)="goResults()" *ngIf="showResults" >Results</button>
    </nav>
</div>
<div class="app-name" *ngIf="showTitle">
    CoDriS
</div>
