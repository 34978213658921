import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

// const AUTH_API = environment.wordPressUrl + '/oauth/authorize'; //'http://localhost/api/auth/';
// const OAUTH_CLIENT = 'tKIVStbziqzeQfrSVYZSMJxM7qmuiF8VRjCusC62';
// const OAUTH_SECRET = 'NvDrXp3mMtCJ54SVTkET650IZbtXE9ovIYWfBSBV';

const AUTH_API = environment.apiUrl + 'assets/api/'

// client ID tKIVStbziqzeQfrSVYZSMJxM7qmuiF8VRjCusC62
// client Secret NvDrXp3mMtCJ54SVTkET650IZbtXE9ovIYWfBSBV

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json'})
};

// const HTTP_OPTIONS = {
//   headers: new HttpHeaders({
//     'Content-Type': 'application/x-www-form-urlencoded',
//     Authorization: 'Basic ' + Buffer.from(OAUTH_CLIENT + ':' + OAUTH_SECRET).toString('base64')
//   })
// };

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  constructor(private http: HttpClient) { }

  login(username: string, password: string): Observable<any> {
    return this.http.post(AUTH_API + 'auth.php', {
      username,
      password
    }, httpOptions);
  }

  register(email: string, password: string): Observable<any> {
    return this.http.post(AUTH_API + 'register.php', {
      email,
      password
    }, httpOptions);
  }

  logout(username: string): Observable<any> {
    return this.http.post(AUTH_API + 'authLogout.php', {
      username
    }, httpOptions);
  }

  resetPasswordToken(username: string, passwordResetUrl: string): Observable<any> {
    return this.http.post(AUTH_API + 'resetPasswordToken.php', {
      username,
      passwordResetUrl
    }, httpOptions);
  }

  validatePasswordToken(token: string): Observable<any> {
    return this.http.post(AUTH_API + 'validatePasswordToken.php', {
      token
    }, httpOptions);
  }

  resetPassword(token: string, password: string): Observable<any> {
    return this.http.post(AUTH_API + 'resetPassword.php', {
      token,
      password
    }, httpOptions);
  }

  validateEmail(email: string): Observable<any> {
    return this.http.post(AUTH_API + 'validateEmail.php', {
      email
    }, httpOptions);
  }
  
}
