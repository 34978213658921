import { Component, Input } from '@angular/core';
import { IDrivingFitness } from '../results.component';

@Component({
  selector: 'app-driving-fitness',
  styles: [
    `
    .pane{
      padding: 1em;
    }
  `
  ],
  template: `
    <table class="table table-bordered" style="visibility: visible;">
          <thead class="thead-dark">
          <tr>
              <th>Date</th>
              <th>ClientRefNo</th>
              <th>OTT</th>
              <th>TC</th>
              <th>MM</th>
              <th>FC</th>
              <th>D</th>
              <th *ngIf="reportType === 'CoDriS'">OAT</th>
              <th *ngIf="reportType !== 'CoDriS'">TI</th>
              <th>ITS</th>
              <th>VM</th>
              <th>AM</th>
              <th>FRTS</th>
              <th>I</th>
              <th>LN</th>
              <th>RR</th>
              <th>RF</th>
              <th>DM</th>
              <th>SA</th>
              <th>TP15</th>
              <th>TP%</th>
              <th>DA30</th>
              <th>DA%</th>
              <th>DF45</th>
              <th>DF%</th>
              <th>Insight%</th>
          </tr>
          </thead>
          <tbody>
          <tr *ngFor="let item of drivingFitness; let i = index;">
              <td>{{item.dDateCreated}}</td>
              <td>{{item.sToken}}</td>
              <td>{{item.tto}}</td>
              <td>{{item.tc}}</td>
              <td>{{item.mm}}</td>
              <td>{{item.fc}}</td>
              <td>{{item.direction}}</td>
              <td>{{item.oat}}</td>
              <td>{{item.irts}}</td>
              <td>{{item.vmemory}}</td>
              <td>{{item.amemory}}</td>
              <td>{{item.frts}}</td>
              <td>{{item.intersection}}</td>
              <td>{{item.landmark}}</td>
              <td>{{item.rr}}</td>
              <td>{{item.rf}}</td>
              <td>{{item.dm}}</td>
              <td>{{item.sa}}</td>
              <td>{{item.tp}}</td>
              <td>{{item.tpPerc}}</td>
              <td>{{item.da}}</td>
              <td>{{item.daPerc}}</td>
              <td>{{item.df}}</td>
              <td>{{item.dfPerc}}</td>
              <td>{{item.insight}}</td>
          </tr>
          </tbody>
      </table>
  `
})
export class DrivingFitnessComponent {
  @Input() drivingFitness: IDrivingFitness[] = [];
  @Input() reportType: string = '';
}
