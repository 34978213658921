import { Component, Input } from '@angular/core';
import { ITestScores } from '../results.component';

@Component({
  selector: 'app-test-item',
  styles: [
    `
    .pane{
      padding: 1em;
    }
  `
  ],
  template: `
    <table class="table table-bordered" style="visibility: visible;">
        <thead class="thead-dark">
        <tr>
            <th rowspan="2">Date</th>
            <th rowspan="2" colspan="1">ClientRefNo</th>
            <th rowspan="1" colspan="7">OTT</th>
            <th rowspan="1" colspan="1">TC</th>
            <th rowspan="1" colspan="5">ITS</th>
            <th rowspan="1" colspan="5">D</th>
            <th rowspan="1" colspan="3">MM</th>
            <th rowspan="1">FC</th>
            <th rowspan="1" colspan="3">M</th>
            <th rowspan="1">FTS</th>
            <th rowspan="1" colspan="5">I</th>
            <th rowspan="1" colspan="4">LN</th>
            <th rowspan="1" colspan="5">RR</th>
            <th rowspan="1" colspan="3">RF</th>
            <th rowspan="1" colspan="3">RTTleft</th>
            <th rowspan="1" colspan="3">RTTright</th>
            <th rowspan="1" colspan="3">DM</th>
            <th rowspan="1" colspan="5" *ngIf="reportType === 'CoDriS'">OAT</th>
            <th rowspan="1" colspan="5" *ngIf="reportType !== 'CoDriS'">TI</th>
            <th rowspan="1" colspan="9">SA</th>
        </tr>
        <tr>
            <th>Q1 /0.5</th>
            <th>Q2 /0.5</th>
            <th>Q3 /0.5</th>
            <th>Q4 /0.5</th>
            <th>Q5 /0.5</th>
            <th>Q6 /0.5</th>
            <th>Total /3</th>
            <!--  -->
            <th>Q1 /1</th>
            <!--  -->
            <th>Q1 /1</th>
            <th>Q2 /1</th>
            <th>Q3 /1</th>
            <th>Q4 /1</th>
            <th>Total /4</th>
            <!--  -->
            <th>Q1 /1</th>
            <th>Q2 /1</th>
            <th>Q3 /1</th>
            <th>Q4 /1</th>
            <th>Total /4</th>
            <!--  -->
            <th>Q1 /1</th>
            <th>Q2 /1</th>
            <th>Total /2</th>
            <!--  -->
            <th>Q1 /1</th>
            <!--  -->
            <th>Q1 /2</th>
            <th>Q2 /2</th>
            <th>Total /4</th>
            <!--  -->
            <th>Q1 /3</th>
            <!--  -->
            <th>Q1 /1</th>
            <th>Q2 /1</th>
            <th>Q3 /1</th>
            <th>Q4 /1</th>
            <th>Total /4</th>
            <!--  -->
            <th>Q1 /1</th>
            <th>Q2 /1</th>
            <th>Q3 /1</th>
            <th>Total /3</th>
            <!--  -->
            <th>Q1 /1</th>
            <th>Q2 /1</th>
            <th>Q3 /1</th>
            <th>Q4 /1</th>
            <th>Total /4</th>
            <!--  -->
            <th>Q1 /1</th>
            <th>Q2 /3</th>
            <th>Total /4</th>
            <!--  -->
            <th>Q1 /1.5</th>
            <th>Q2 /1.5</th>
            <th>Total /3</th>
            <!--  -->
            <th>Q1 /1.5</th>
            <th>Q2 /1.5</th>
            <th>Total /3</th>
            <!--  -->
            <th>Q1 /1</th>
            <th>Q2 /3</th>
            <th>Total /4</th>
            <!--  -->
            <th>Q1 /1</th>
            <th>Q2 /1</th>
            <th>Q3 /1</th>
            <th>Q4 /1</th>
            <th>Total /4</th>
            <!--  -->
            <th>Q1 /2</th>
            <th>Q2 /2</th>
            <th>Q3 /2</th>
            <th>Q4 /2</th>
            <th>Q5 /2</th>
            <th>Q6 /2</th>
            <th>Q7 /2</th>
            <th>Q8 /2</th>
            <th>Total /16</th>
        </tr>
        </thead>
        <tbody>
        <tr *ngFor="let item of testScores; let i = index;">
            <td>{{item.dDateCreated}}</td>
            <td>{{item.sToken}}</td>
            <td>{{item.ott_q1}}</td>
            <td>{{item.ott_q2}}</td>
            <td>{{item.ott_q3}}</td>
            <td>{{item.ott_q4}}</td>
            <td>{{item.ott_q5}}</td>
            <td>{{item.ott_q6}}</td>
            <td>{{item.ott}}</td>
            <td>{{item.tc}}</td>
            <td>{{item.irts_q1}}</td>
            <td>{{item.irts_q2}}</td>
            <td>{{item.irts_q3}}</td>
            <td>{{item.irts_q4}}</td>
            <td>{{item.irts}}</td>
            <td>{{item.direction_1}}</td>
            <td>{{item.direction_2}}</td>
            <td>{{item.direction_3}}</td>
            <td>{{item.direction_4}}</td>
            <td>{{item.direction}}</td>
            <td>{{item.mm_q1}}</td>
            <td>{{item.mm_q2}}</td>
            <td>{{item.mm}}</td>
            <td>{{item.fc}}</td>
            <td>{{item.memory_1}}</td>
            <td>{{item.memory_2}}</td>
            <td>{{item.memory}}</td>
            <td>{{item.frts}}</td>
            <td>{{item.intersection_q1}}</td>
            <td>{{item.intersection_q2}}</td>
            <td>{{item.intersection_q3}}</td>
            <td>{{item.intersection_q4}}</td>
            <td>{{item.intersection}}</td>
            <td>{{item.lm_q1}}</td>
            <td>{{item.lm_q2}}</td>
            <td>{{item.lm_q3}}</td>
            <td>{{item.lm}}</td>
            <td>{{item.rr_q1}}</td>
            <td>{{item.rr_q2}}</td>
            <td>{{item.rr_q3}}</td>
            <td>{{item.rr_q4}}</td>
            <td>{{item.rr}}</td>
            <td>{{item.rf_q1}}</td>
            <td>{{item.rf_q2}}</td>
            <td>{{item.rf}}</td>
            <td>{{item.left_q1}}</td>
            <td>{{item.left_q2}}</td>
            <td>{{item.left}}</td>
            <td>{{item.right_q1}}</td>
            <td>{{item.right_q2}}</td>
            <td>{{item.right}}</td>
            <td>{{item.dm_q1}}</td>
            <td>{{item.dm_q2}}</td>
            <td>{{item.dm}}</td>
            <td>{{item.oat_q1}}</td>
            <td>{{item.oat_q2}}</td>
            <td>{{item.oat_q3}}</td>
            <td>{{item.oat_q4}}</td>
            <td>{{item.oat}}</td>
            <td>{{item.sa_q1}}</td>
            <td>{{item.sa_q2}}</td>
            <td>{{item.sa_q3}}</td>
            <td>{{item.sa_q4}}</td>
            <td>{{item.sa_q5}}</td>
            <td>{{item.sa_q6}}</td>
            <td>{{item.sa_q7}}</td>
            <td>{{item.sa_q8}}</td>
            <td>{{item.sa}}</td>
        </tr>
        </tbody>
    </table>
  `
})
export class TestItemComponent {
  @Input() testScores: ITestScores[] = [];
  @Input() reportType: string = '';
}
