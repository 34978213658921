import { Component, Input } from '@angular/core';
import { IRawData } from '../results.component';

@Component({
  selector: 'app-raw-data',
  styles: [
    `
    .pane{
      padding: 1em;
    }
  `
  ],
  template: `
    <table class="table table-bordered" style="visibility: visible;">
        <thead class="thead-dark">
        <tr>
            <th rowspan="2">Date</th>
            <th rowspan="2" colspan="1">ClientRefNo</th>
            <th rowspan="1" colspan="5">OTT</th>
            <th rowspan="1" colspan="1">TC</th>
            <th rowspan="1" colspan="4">ITS</th>
            <th rowspan="1" colspan="4">D</th>
            <th rowspan="1" colspan="2">MM</th>
            <th rowspan="1">FC</th>
            <th rowspan="1" colspan="2">M</th>
            <th rowspan="1">FTS</th>
            <th rowspan="1" colspan="4">I</th>
            <th rowspan="1" colspan="3">LN</th>
            <th rowspan="1" colspan="4">RR</th>
            <th rowspan="1" colspan="2">RF</th>
            <!-- <th rowspan="1" colspan="2">RTTleft</th>
            <th rowspan="1" colspan="2">RTTright</th> -->
            <th rowspan="1" colspan="2">DM</th>
            <th rowspan="1" colspan="4" *ngIf="reportType === 'CoDriS'">OAT</th>
            <th rowspan="1" colspan="4" *ngIf="reportType !== 'CoDriS'">TI</th>
            <th rowspan="1" colspan="8">SA</th>
        </tr>
        <tr>
            <!-- OTT -->
            <th>Q1</th>
            <th>Q2</th>
            <th>Q3</th>
            <th>Q4</th>
            <th>Q5</th>
            <!-- TC -->
            <th>Q1</th>
            <!-- ITS -->
            <th>Q1</th>
            <th>Q2</th>
            <th>Q3</th>
            <th>Q4</th>
            <!-- D -->
            <th>Q1</th>
            <th>Q2</th>
            <th>Q3</th>
            <th>Q4</th>
            <!-- MM -->
            <th>Q1</th>
            <th>Q2</th>
            <!-- FC -->
            <th>Q1</th>
            <!-- M -->
            <th>Q1</th>
            <th>Q2</th>
            <!-- FTS -->
            <th>Q1</th>
            <!-- I -->
            <th>Q1</th>
            <th>Q2</th>
            <th>Q3</th>
            <th>Q4</th>
            <!-- LN -->
            <th>Q1</th>
            <th>Q2</th>
            <th>Q3</th>
            <!-- RR -->
            <th>Q1</th>
            <th>Q2</th>
            <th>Q3</th>
            <th>Q4</th>
            <!-- RF -->
            <th>Q1</th>
            <th>Q2</th>
            <!-- RTTleft 
            <th>Q1</th>
            <th>Q2</th>-->
            <!-- RTTright 
            <th>Q1</th>
            <th>Q2</th>-->
            <!-- DM -->
            <th>Q1</th>
            <th>Q2</th>
            <!-- OAT -->
            <th>Q1</th>
            <th>Q2</th>
            <th>Q3</th>
            <th>Q4</th>
            <!-- SA -->
            <th>Q1</th>
            <th>Q2</th>
            <th>Q3</th>
            <th>Q4</th>
            <th>Q5</th>
            <th>Q6</th>
            <th>Q7</th>
            <th>Q8</th>
        </tr>
        </thead>
        <tbody>
        <tr *ngFor="let item of rawData; let i = index;">
            <td>{{item.dDateCreated}}</td>
            <td>{{item.sToken}}</td>
            <!-- OTT -->
            <td>{{item.q1}}</td>
            <td>{{item.q2}}</td>
            <td>{{item.q3}}</td>
            <td>{{item.q4}}</td>
            <td>{{item.q5}}</td>
            <!-- TC -->
            <td>{{item.q6}}</td>
            <!-- ITS -->
            <td>{{item.q7}}</td>
            <td>{{item.q8}}</td>
            <td>{{item.q9}}</td>
            <td>{{item.q10}}</td>
            <!-- D -->
            <td>{{item.q11}}</td>
            <td>{{item.q12}}</td>
            <td>{{item.q13}}</td>
            <td>{{item.q14}}</td>
            <!-- MM -->
            <td>{{item.q15}}</td>
            <td>{{item.q16}}</td>
            <!-- FC -->
            <td>{{item.q17}}</td>
            <!-- M -->
            <td>{{item.q18}}</td>
            <td>{{item.q19}}</td>
            <!-- FTS -->
            <td>{{item.q20}}</td>
            <!-- I -->
            <td>{{item.q21}}</td>
            <td>{{item.q22}}</td>
            <td>{{item.q23}}</td>
            <td>{{item.q24}}</td>
            <!-- LN -->
            <td>{{item.q25}}</td>
            <td>{{item.q26}}</td>
            <td>{{item.q27}}</td>
            <!-- RR -->
            <td>{{item.q28}}</td>
            <td>{{item.q29}}</td>
            <td>{{item.q30}}</td>
            <td>{{item.q31}}</td>
            <!-- RF -->
            <td>{{item.q32}}</td>
            <td>{{item.q33}}</td>
            <!-- RTTleft 
            <td>{{item.q34}}</td>
            <td>{{item.q35}}</td>-->
            <!-- RTTright 
            <td>{{item.q36}}</td>
            <td>{{item.q37}}</td>-->
            <!-- DM -->
            <td>{{item.q38}}</td>
            <td>{{item.q39}}</td>
            <!-- OAT -->
            <td>{{item.q40}}</td>
            <td>{{item.q41}}</td>
            <td>{{item.q42}}</td>
            <td>{{item.q43}}</td>
            <!-- SA -->
            <td>{{item.q44}}</td>
            <td>{{item.q45}}</td>
            <td>{{item.q46}}</td>
            <td>{{item.q47}}</td>
            <td>{{item.q48}}</td>
            <td>{{item.q49}}</td>
            <td>{{item.q50}}</td>
            <td>{{item.q51}}</td>
        </tr>
        </tbody>
    </table>
  `
})
export class RawDataComponent {
  @Input() rawData: IRawData[] = [];
  @Input() reportType: string = '';
}
