<app-header [showLogout]="false" [showTitle]="false" [showDescription]=false></app-header>
<div class="col-md-12">
  <div class="container">
    <!-- Welcome -->
    <div class="fade-in-text" *ngIf="showWelcomeText">
      <p>Welcome to CoDriS.</p>
    </div>
    
    <!-- Question 1 -->
    <div class="fade-in-text" *ngIf="showQuestion1">
      <p>Have you purchased a CoDriS token/s?</p>
    </div>
    <div class="buttons" *ngIf="showQuestion1">
      <button class="btn btn-primary" (click)="purchasedTokenYes()">
        Yes
      </button>
      <button class="btn btn-primary" (click)="purchasedTokenNo()">
        No
      </button>
    </div>

    <!-- Question 2 -->
    <div class="fade-in-text" *ngIf="showQuestion2">
      <p>Please enter the email address you used to purchase CoDriS tokens.</p>
      <form [formGroup]="frmSignup" (submit)="submitEmail()" #f="ngForm">
      <input class="emailInput" id="email" formControlName="email" type="email" class="form-control emailInput" [ngClass]="frmSignup.controls['email'].invalid ? 'is-invalid' : ''">
      <div class="buttons">
        <button class="btn btn-primary" [disabled]="frmSignup.invalid" type="submit">
          Submit
        </button>
      </div>
    </form>
    <div class="fade-in-text-small" [innerHTML]="validateEmailMessage">
    </div>
    <div class="buttons" *ngIf="showLoginButton">
      <button class="btn btn-primary" (click)="goToLogin()">
        Login
      </button>
    </div>
    <div class="buttons" *ngIf="showRegisterButton">
      <button class="btn btn-primary" (click)="goToRegister()">
        Sign up
      </button>
    </div>
    </div>
    
  </div>
</div>
