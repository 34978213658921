import { Component, OnInit } from '@angular/core';
import { AuthService } from '../_services/auth.service';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CustomValidators } from '../register/password-validators';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.css']
})
export class ResetPasswordComponent implements OnInit {

  form: any = {
    password: null,
    confirmPassword: null
  };

  errorMessage = '';
  isResetPasswordFailed = false;
  showForm = true;
  showFields = true;
  public frmSignup: FormGroup;
  passwordToken: string = '';

  constructor(private authService: AuthService, private router: Router, private activatedRoute: ActivatedRoute, private fb: FormBuilder) {
    this.frmSignup = this.createSignupForm();
   }

   createSignupForm(): FormGroup {
    return this.fb.group(
      {
        password: [
          null,
          Validators.compose([
            Validators.required,
            // check whether the entered password has a number
            CustomValidators.patternValidator(/\d/, {
              hasNumber: true
            }),
            // check whether the entered password has upper case letter
            CustomValidators.patternValidator(/[A-Z]/, {
              hasCapitalCase: true
            }),
            // check whether the entered password has a lower case letter
            CustomValidators.patternValidator(/[a-z]/, {
              hasSmallCase: true
            }),
            // check whether the entered password has a special character
            CustomValidators.patternValidator(
              /[ !@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/,
              {
                hasSpecialCharacters: true
              }
            ),
            Validators.minLength(8)
          ])
        ],
        confirmPassword: [null, Validators.compose([Validators.required])]
      },
      {
        // check whether our password and confirm password match
        validator: CustomValidators.passwordMatchValidator
      }
    );
  }

  ngOnInit(): void {
    const token = this.activatedRoute.snapshot.paramMap.get('token');
    if (token !== null) {
      this.passwordToken = token.toString();
    }

    if (this.passwordToken == '') {
      this.router.navigate(['login/']);
    } else {
      this.validatePasswordToken(this.passwordToken);
    }

  }

  validatePasswordToken(token: string): void {

    this.authService.validatePasswordToken(token).subscribe(
      data => {
        if (data == 'Valid token') {
         this.showForm = true;
        } else {
          this.showForm = false;
        }
      },
      err => {
        this.showForm = false;
      }
    );
  }

  onSubmit(): void {

    this.authService.resetPassword(this.passwordToken, this.frmSignup.value.password).subscribe(
      data => {
          this.isResetPasswordFailed = false;
          this.showFields = false;
      },
      err => {
        this.errorMessage = err.error.message;
        this.isResetPasswordFailed = true;
        this.showFields = true;
      }
    );
  }

  goToLogin(): void {
    this.router.navigate(['login/']);
  }
}
