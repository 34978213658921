<app-header [showLogout]="false" [showTitle]="true"></app-header>
<div class="col-md-12" style="margin-bottom: 200px;">
  <div class="card card-container">
    
    <form [formGroup]="frmSignup" (submit)="onSubmit()" #f="ngForm">
      <div class="form-group">
        <label for="email" [ngClass]="frmSignup.controls['email'].invalid ? 'text-normal' : ''">Email address</label>
        <input id="email" formControlName="email" type="email" class="form-control" [ngClass]="frmSignup.controls['email'].invalid ? 'is-invalid' : ''">
        <label class="text-normal" *ngIf="frmSignup.controls['email'].hasError('required')">
          Email is required.
        </label>
        <label class="text-normal" *ngIf="frmSignup.controls['email'].hasError('email')">
          Enter a valid email address.
        </label>
      </div>
      <div class="form-group">
        <label for="password" [ngClass]="frmSignup.controls['password'].invalid ? 'text-normal' : ''">Password:</label>
        <input id="password" formControlName="password" type="password" class="form-control" [ngClass]="frmSignup.controls['password'].invalid ? 'is-invalid' : ''">

        <label class="col" [ngClass]="frmSignup.controls['password'].hasError('required') || frmSignup.controls['password'].hasError('minlength')  ? 'text-normal' : 'text-success'">
          <i class="material-icons">{{ frmSignup.controls['password'].hasError('required') ||
            frmSignup.controls['password'].hasError('minlength') ? 'cancel' :
            'check_circle' }}</i>
          Must be at least 8 characters.
        </label>
        <label class="col" [ngClass]="frmSignup.controls['password'].hasError('required') || frmSignup.controls['password'].hasError('hasNumber')  ? 'text-normal' : 'text-success'">
          <i class="material-icons">{{ frmSignup.controls['password'].hasError('required') ||
            frmSignup.controls['password'].hasError('hasNumber') ? 'cancel' :
            'check_circle' }}</i>
          Must contain at least 1 number.
        </label>
        <label class="col" [ngClass]="frmSignup.controls['password'].hasError('required') || frmSignup.controls['password'].hasError('hasCapitalCase')  ? 'text-normal' : 'text-success'">
          <i class="material-icons">{{ frmSignup.controls['password'].hasError('required') ||
            frmSignup.controls['password'].hasError('hasCapitalCase') ? 'cancel' :
            'check_circle' }}</i>
          Must contain at least 1 letter in capital case.
        </label>
        <label class="col" [ngClass]="frmSignup.controls['password'].hasError('required') || frmSignup.controls['password'].hasError('hasSmallCase')  ? 'text-normal' : 'text-success'">
          <i class="material-icons">{{ frmSignup.controls['password'].hasError('required') ||
            frmSignup.controls['password'].hasError('hasSmallCase') ? 'cancel' :
            'check_circle' }}</i>
          Must contain at least 1 letter in small case.
        </label>
        <label class="col" [ngClass]="frmSignup.controls['password'].hasError('required') || frmSignup.controls['password'].hasError('hasSpecialCharacters') ? 'text-normal' : 'text-success'">
          <i class="material-icons">{{ frmSignup.controls['password'].hasError('required') ||
            frmSignup.controls['password'].hasError('hasSpecialCharacters') ? 'cancel' :
            'check_circle' }}</i>
          Must contain at least 1 special character.
        </label>
      </div>
      <div class="form-group">
        <label for="confirmPassword" [ngClass]="frmSignup.controls['confirmPassword'].invalid ? 'text-normal' : ''">
          Confirm password:</label>
        <input id="confirmPassword" formControlName="confirmPassword" type="password" class="form-control"
          [ngClass]="frmSignup.controls['confirmPassword'].invalid ? 'is-invalid' : ''">
        <label class="text-normal" *ngIf="frmSignup.controls['confirmPassword'].hasError('required')">
          Password is required.
        </label>
        <label class="text-normal" *ngIf="frmSignup.controls['confirmPassword'].hasError('NoPassswordMatch')">
          Confirmed password does not match.
        </label>
      </div>
      <div class="form-group text-center">
        <button class="btn btn-primary btn-block" [disabled]="frmSignup.invalid" type="submit">Sign Up</button>
        <button class="btn btn-link" (click)="goToLogin()">Login</button>
      </div>

      <div class="alert alert-warning" *ngIf="f.submitted && isSignUpFailed" [innerHTML]="errorMessage">
      </div>
    </form>

    <div class="alert alert-success text-center" *ngIf="isSuccessful">
      Your registration is successful.<br>
      <button class="btn btn-link" (click)="goToLogin()">Login</button>
    </div>
  </div>
</div>

<app-footer></app-footer>
