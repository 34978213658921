import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthService } from '../_services/auth.service';
import { TokenStorageService } from '../_services/token-storage.service';

@Component({
  selector: 'app-intro',
  templateUrl: './intro.component.html',
  styleUrls: ['./intro.component.css']
})
export class IntroComponent implements OnInit {
  
  showWelcomeText = true;
  showQuestion1 = true;
  showQuestion2 = false;
  showLoginButton = false;
  showRegisterButton = false;
  validateEmailMessage = '';

  public frmSignup: FormGroup;

  constructor(private authService: AuthService, 
              private router: Router, 
              private fb: FormBuilder, 
              private tokenStorage: TokenStorageService) { 
    this.frmSignup = this.createSignupForm();
  }

  createSignupForm(): FormGroup {
    return this.fb.group(
      {
        email: [
          null,
          Validators.compose([Validators.email, Validators.required])
        ],
      },
    );
  }

  ngOnInit(): void {
    // reset all values when coming here
    this.showWelcomeText = true;
    this.showQuestion1 = true;
    this.showQuestion2 = false;
    this.showLoginButton = false;
    this.showRegisterButton = false;
    this.validateEmailMessage = '';

    if (this.tokenStorage.getAuthToken()) {
      this.router.navigate(['home/']);
    }
  }

  purchasedTokenYes(): void {
    this.showQuestion1 = false;
    this.showQuestion2 = true;
    this.showWelcomeText = false;
  }

  purchasedTokenNo(): void {
    var url = 'https://going-places.co.za/cognitive-driving-screen-products/';
    const a = document.createElement("a");
    a.setAttribute('href', url);
    a.setAttribute('target', '_blank');
    a.click();
  }

  goToLogin(): void {
    window.localStorage.removeItem('intro_username');
    window.localStorage.setItem('intro_username', this.frmSignup.value.email);
    this.router.navigate(['login/']);
  }

  goToRegister(): void {
    window.localStorage.removeItem('intro_username');
    window.localStorage.setItem('intro_username', this.frmSignup.value.email);
    this.router.navigate(['register/']);
  }

  submitEmail(): void {

    if (this.frmSignup.value.email != '') {

      // Validate the email
      this.authService.validateEmail(this.frmSignup.value.email).subscribe(
        data => {
          this.validateEmailMessage = data;

          if (this.validateEmailMessage == 'You are signed up on the CoDriS testing platform. <br>Please continue to the login page to log onto the testing platform.') {
            this.showLoginButton = true;
            this.showRegisterButton = false;
          } else if (this.validateEmailMessage == 'No purchased orders found for email address. <br>CoDriS is available for purchase at <a href="https://going-places.co.za/cognitive-driving-screen-products/" target="_blank">Products</href>') {
            this.showRegisterButton = false;
            this.showLoginButton = false;
          } else if (this.validateEmailMessage == 'You have purchased tokens, but you are not signed up on the CoDriS testing platfrom. <br>Please continue to sign up on testing platform.') {
            this.showRegisterButton = true;
            this.showLoginButton = false;
          } else if (this.validateEmailMessage == 'You have used all your tokens. <br>More tokens can be purchased at <a href="https://going-places.co.za/cognitive-driving-screen-products/" target="_blank">Products</href>') {
            this.showLoginButton = true;
            this.showRegisterButton = false;
          }
        },
        err => {
          console.log('err', err);
        }
      );
    }

  }

}
