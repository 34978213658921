import { Injectable } from '@angular/core';
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  Router,
} from '@angular/router';
import { Observable } from 'rxjs';
import { TokenStorageService } from './token-storage.service';


@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanActivate {
  
  public loggedInUser: any;

  constructor(private router: Router, private tokenStorage: TokenStorageService) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): boolean | Observable<boolean> | Promise<boolean> {
    return new Promise((resolve, reject) => {
      this.loggedInUser = this.tokenStorage.getAuthToken();

      if (this.loggedInUser) {
        resolve(true);
      } else {
        // console.log('User is not logged in');
        this.router.navigate(['/login']);
        resolve(false);
      }
    });
  }
}
