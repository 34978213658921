import { Component, OnInit } from '@angular/core';
import { AuthService } from '../_services/auth.service';
import { TokenStorageService } from '../_services/token-storage.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  form: any = {
    username: null,
    password: null
  };
  isLoggedIn = false;
  isLoginFailed = false;
  errorMessage = '';
  // roles: string[] = [];

  constructor(private authService: AuthService, private tokenStorage: TokenStorageService, private router: Router) { }

  ngOnInit(): void {
    if (this.tokenStorage.getAuthToken()) {
      this.isLoggedIn = true;
    }

    if (this.isLoggedIn && this.router.url === '/login') {
      this.router.navigate(['home/']);
    }

    var intro_username = window.localStorage.getItem('intro_username');
    if (intro_username !== null || intro_username !== '') {
      this.form.username = intro_username;
    }
  }

  onSubmit(): void {
    const { username, password } = this.form;

    this.tokenStorage.saveUsername(username);

    this.authService.login(username, password).subscribe(
      data => {
        if (data === null) {
          return;
        }
        if (data === 'Incorrect email or password provided.') {
          this.errorMessage = data;
          this.isLoginFailed = true;
        } else {
          this.tokenStorage.saveToken(data);
          this.isLoginFailed = false;
          this.isLoggedIn = true;
          this.router.navigate(['home/']);
        }
      },
      err => {
        this.errorMessage = err.error.message;
        this.isLoginFailed = true;
      }
    );
  }

  reloadPage(): void {
    window.location.reload();
  }

  goToRegister(): void {
    this.router.navigate(['register/']);
  }

  goToResetPassword(): void {
    this.router.navigate(['forgot-password/']);
  }
}
