import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

@Injectable({
    providedIn: 'root'
})

export class ApiService {

    logoUrl = './assets/images/logo/GOINGPLACES_RGB no tag.jpg';
    logoSmallUrl = './assets/images/logo/GOINGPLACES_RGB no words.jpg';

    signLeftTurnUrl = './assets/images/signs/signLeftTurn.png';
    signNoEntryUrl = './assets/images/signs/signNoEntry.png';
    signNoParkingUrl = './assets/images/signs/signNoParking.png';
    signPedestrianCrossingUrl = './assets/images/signs/signPedestrianCrossing.png';
    signRightTurnUrl = './assets/images/signs/signRightTurn.png';
    signStopUrl = './assets/images/signs/signStop.png';
    signTrafficCircleUrl = './assets/images/signs/signTrafficCircle.png';
    signTrafficLightUrl = './assets/images/signs/signTrafficLight.png';

    photoPedestrianUrl = './assets/images/photos/Pedestrian-aligned_598x448.jpg';
    photoStopUrl = './assets/images/photos/Stop_532x400.jpg';
    photoCircleUrl = './assets/images/photos/Traffic-circle_600x450.jpg';
    photoRobotUrl = './assets/images/photos/Robots_579x435.jpg';
    photoRedLineUrl = './assets/images/photos/Red-line_653x490.jpg';
    photoNoRightTurnUrl = './assets/images/photos/No-right-turn_645x484.jpg';
    photoRoadsideFactorUrl = './assets/images/photos/Roadside Factors.jpg';

    direction1Url = './assets/images/directions/GOING-PLACES_DIRECTIONS_1-01.PNG';
    direction2Url = './assets/images/directions/GOING-PLACES_DIRECTIONS_1-02.PNG';
    direction3Url = './assets/images/directions/GOING-PLACES_DIRECTIONS_1-03.PNG';
    direction4Url = './assets/images/directions/GOING-PLACES_DIRECTIONS_1-04.PNG';
    direction5Url = './assets/images/directions/GOING-PLACES_DIRECTIONS_1-05.PNG';
    direction6Url = './assets/images/directions/GOING-PLACES_DIRECTIONS_1-06.PNG';
    direction7Url = './assets/images/directions/GOING-PLACES_DIRECTIONS_1-07.PNG';
    direction8Url = './assets/images/directions/GOING-PLACES_DIRECTIONS_1-08.PNG';
    direction9Url = './assets/images/directions/GOING-PLACES_DIRECTIONS_1-09.PNG';
    direction10Url = './assets/images/directions/GOING-PLACES_DIRECTIONS_1-10.PNG';
    direction11Url = './assets/images/directions/GOING-PLACES_DIRECTIONS_1-11.PNG';
    direction12Url = './assets/images/directions/GOING-PLACES_DIRECTIONS_1-12.PNG';
    direction13Url = './assets/images/directions/GOING-PLACES_DIRECTIONS_1-13.PNG';
    direction14Url = './assets/images/directions/GOING-PLACES_DIRECTIONS_1-14.PNG';
    direction15Url = './assets/images/directions/GOING-PLACES_DIRECTIONS_1-15.PNG';
    direction16Url = './assets/images/directions/GOING-PLACES_DIRECTIONS_1-16.PNG';

    roadtraffic1Url = './assets/images/roadsigns/ROAD_SIGNS1-01.png';
    roadtraffic2Url = './assets/images/roadsigns/ROAD_SIGNS1-02.png';
    roadtraffic3Url = './assets/images/roadsigns/ROAD_SIGNS1-03.png';
    roadtraffic4Url = './assets/images/roadsigns/ROAD_SIGNS1-04.png';
    roadtraffic5Url = './assets/images/roadsigns/ROAD_SIGNS1-05.png';
    roadtraffic6Url = './assets/images/roadsigns/ROAD_SIGNS1-06.png';
    roadtraffic7Url = './assets/images/roadsigns/ROAD_SIGNS2_resized.svg';

    intersection1Url = './assets/images/intersections/Intersection1aRotated.png';
    intersection2Url = './assets/images/intersections/Intersection2aRotated.png';
    intersection3Url = './assets/images/intersections/Intersection3aRotated.png';
    intersection4Url = './assets/images/intersections/Intersection4aRotated.png';

    taxiLogo1Url = './assets/images/taxi/taxi1.png';
    taxiLogo2Url = './assets/images/taxi/taxi2.png';
    taxiLogo3Url = './assets/images/taxi/taxi3.png';
    taxiLogo4Url = './assets/images/taxi/taxi4.png';

    audioUrl = './assets/audio/ccca55c4-57ff-4202-b81e-6f62e3797538.mp3';

    drivingManouever = './assets/images/photos/Driving Manoeuver Photo.jpg';
    landMarkMap = './assets/images/landmark/Map.png';

    arrowRightGrey = './assets/images/arrows/arrowRightGrey.png';
    arrowRightGreen = './assets/images/arrows/arrowRightGreen.png';
    arrowLeftGreen = './assets/images/arrows/arrowLeftGreen.png';
    arrowLeftGrey = './assets/images/arrows/arrowLeftGrey.png';
    arrowPlay = './assets/images/arrows/playArrow.png';

    surveyResults = {};
    surveyPages = [];
    surveyTime = 0;

    countryName = '';
    regionName = '';

    constructor(private router: Router) {
    }

    redirectToComplete(surveyResults: object, surveyPages: [], surveyTime: number, token: string): void {

        this.surveyResults = surveyResults;
        this.surveyPages = surveyPages;
        this.surveyTime = surveyTime;
        this.router.navigate(['complete/' + token]);
    }

     getSurveyData(token: string): any {

        const  surveyJSON =  {
            title: '',
            description: '',
            // logo: this.logoUrl,
            // logoPosition: 'right',
            // logoHeight: 80,
            showQuestionNumbers: 'off',
            showPageNumbers: false,
            goNextPageAutomatic: false,
            lazyRowsRendering: true,
            completedHtml: '',
            // loadingHtml: 'Please wait while the assessment is loading.',
            cookieName: token,
            pages: [
                {
                    name: 'page1',
                    title: ' ',
                    elements: this.getPage1()
                },
                {
                    name: 'page2',
                    title: 'DEMOGRAPHICS',
                    elements: this.getPage2()
                },
                {
                    name: 'page3',
                    title: 'CONSENT',
                    elements: this.getPage3()
                },
                {
                    name: 'page4',
                    title: 'SELF ASSESSMENT',
                    elements: this.getPage4()
                },
                {  // TRIP PLANNING
                    name: 'page6a',
                    title: 'ORIENTATION TO TIME',
                    elements: this.getPage6a()
                },
                { // TRIP PLANNING
                    name: 'page6b',
                    title: 'TIME CALCULATION',
                    elements: this.getPage6b()
                },
                { // DRIVING ABILITY
                    name: 'page7a',
                    title: 'IDENTIFYING TRAFFIC SIGNS',
                    elements: this.getPage7a()
                },
                { // DRIVING ABILITY
                    name: 'page7b',
                    title: 'IDENTIFYING TRAFFIC SIGNS ',
                    elements: this.getPage7b()
                },
                { // DRIVING ABILITY
                    name: 'page7c',
                    title: 'IDENTIFYING TRAFFIC SIGNS ',
                    elements: this.getPage7c()
                },
                { // DRIVING ABILITY
                    name: 'page7d',
                    title: 'IDENTIFYING TRAFFIC SIGNS ',
                    elements: this.getPage7d()
                },
                { // TRIP PLANNING
                    name: 'page8a',
                    title: 'DIRECTIONS',
                    elements: this.getPage8a()
                },
                { // TRIP PLANNING
                    name: 'page8b',
                    title: 'DIRECTIONS ',
                    elements: this.getPage8b()
                },
                { // TRIP PLANNING
                    name: 'page9',
                    title: 'MONEY MANAGEMENT',
                    elements: this.getPage9()
                },
                { // TRIP PLANNING
                    name: 'page10',
                    title: 'FUEL CALCULATION',
                    elements: this.getPage10()
                },
                { // DRIVING ABILITY
                    name: 'page11a',
                    title: 'VISUAL MEMORY',
                    elements: this.getPage11a()
                },
                { // DRIVING ABILITY
                    name: 'page11b',
                    title: 'AUDITORY MEMORY',
                    elements: this.getPage11b()
                },
                { // DRIVING ABILITY
                    name: 'page12',
                    title: 'FIND THE TRAFFIC SIGN',
                    elements: this.getPage12()
                },
                { // DRIVING ABILITY
                    name: 'page13a',
                    title: 'INTERSECTIONS',
                    elements: this.getPage13a()
                },
                { // DRIVING ABILITY
                    name: 'page13b',
                    title: 'INTERSECTIONS',
                    elements: this.getPage13b()
                },
                { // DRIVING ABILITY
                    name: 'page13c',
                    title: 'INTERSECTIONS',
                    elements: this.getPage13c()
                },
                { // DRIVING ABILITY
                    name: 'page13d',
                    title: 'INTERSECTIONS',
                    elements: this.getPage13d()
                },
                { // LAND MARK NAVIGATION
                    name: 'page14a',
                    title: 'LANDMARK NAVIGATION',
                    elements: this.getPage14a()
                },
                { // LAND MARK NAVIGATION
                    name: 'page14aa',
                    title: 'LANDMARK NAVIGATION',
                    elements: this.getPage14aa()
                },
                { // LAND MARK NAVIGATION
                    name: 'page14aaa',
                    title: 'LANDMARK NAVIGATION',
                    elements: this.getPage14aaa()
                },
                { // DRIVING ABILITY
                    name: 'page14',
                    title: 'ROAD RULES',
                    elements: this.getPage14()
                },
                { // DRIVING ABILITY
                    name: 'page15a',
                    title: 'ROADSIDE FACTORS',
                    elements: this.getPage15a()
                },
                { // DRIVING ABILITY
                    name: 'page15aa',
                    title: 'ROADSIDE FACTORS',
                    elements: this.getPage15aa()
                },
                // { // DRIVING ABILITY
                //     name: 'page16a',
                //     title: 'RESPONDING TO TRAFFIC',
                //     elements: this.getPage17a()
                // },
                // { // DRIVING ABILITY
                //     name: 'page16b',
                //     title: 'RESPONDING TO TRAFFIC',
                //     elements: this.getPage17b()
                // },
                // { // DRIVING ABILITY
                //     name: 'page16c',
                //     title: 'RESPONDING TO TRAFFIC',
                //     elements: this.getPage17c()
                // },
                { // DRIVING ABILITY
                    name: 'page17a',
                    title: 'DRIVING MANOEUVRE',
                    elements: this.getPage16a()
                },
                { // DRIVING ABILITY
                    name: 'page17b',
                    title: 'DRIVING MANOEUVRE ',
                    elements: this.getPage16b()
                },
                {
                    name: 'page18',
                    title: 'ORDER A TAXI',
                    elements: this.getPage18()
                },
                {
                    name: 'page19',
                    title: 'SELF ASSESSMENT Active & Non-Active driver',
                    elements: this.getPage19()
                }
            ],
           };
        return surveyJSON;
    }

    getPage1(): any {
        const elements = [
            {
                type: 'html',
                name: 'INTRODUCTION',
                html: '<H2 style="color: #1ab394;font-weight: bold;margin-top: -6px;">GOING PLACES COGNITIVE DRIVING SCREEN</H2><br><div class="page-title">INTRODUCTION</div><h4 style="font-weight:normal">The CoDriS assess various cognitive functions that are needed to safely navigate traffic while driving. <br><br>To optimize your performance in the assessment, follow these guidelines:<br><br><ol><li>Allow 20 – 50 minutes to complete the assessment.</li><li>If possible, read the instructions yourself. If you have difficulty reading you may ask someone to read the instructions to you.</li><li>If you are unfamiliar with using an electronic device you may ask someone to help you operate the device.</li><li>Answer all the questions yourself. You cannot request assistance, take notes or use devices such as a calculator to help you with the answers.</li><li>Complete all the assessment tasks on a page before clicking the NEXT button in the bottom corner. Once you have clicked the NEXT button you <b>cannot return</b> to the page.</li><li>If you are unable to complete a task leave it out and click the NEXT button to move to the next page.</li></h4>'
            }
        ];

        return elements;
    }

    getPage2(): any {
        const elements = [
            {
                type: 'dropdown',
                renderAs: 'select2',
                name: 'DEMOGRAPHICS_gender',
                minWidth: '400px',
                title: 'Gender',
                choices: [{value: 'Male', text: 'Male'}, {value: 'Female', text: 'Female'}, {value: 'Neutral', text: 'Neutral'}]
            },
            {
                type: 'text',
                name: 'DEMOGRAPHICS_age',
                startWithNewLine: false,
                isRequired: false,
                title: 'Age',
                placeHolder: 'Type your age'
            },
            {
                type: 'dropdown',
                renderAs: 'select2',
                name: 'DEMOGRAPHICS_language',
                title: 'Is English your primary language?',
                isRequired: false,
                choices: [{value: 'Yes', text: 'Yes'}, {value: 'No', text: 'No'}]
            },
            {
                type: 'text',
                name: 'DEMOGRAPHICS_hidden_date',
                visible: false,
                title: 'Date',
                inputType: 'date',
                defaultValue: '=today()'
            },
            {
                type: 'dropdown',
                renderAs: 'select2',
                name: 'DEMOGRAPHICS_education',
                isRequired: false,
                startWithNewLine: true,
                title: 'Highest post schooling qualification',
                choices: [{value: 'None', text: 'None'}, {value: 'Certificate', text: 'Certificate'},
                        {value: 'Diploma', text: 'Diploma'}, {value: 'Degree', text: 'Degree'}]
            },
            {
                type: 'dropdown',
                renderAs: 'select2',
                name: 'DEMOGRAPHICS_school',
                isRequired: false,
                startWithNewLine: true,
                title: 'Highest level of schooling',
                choices: [{value: 'None', text: 'None'}, {value: 'Grade 1-7', text: 'Grade 1-7'},
                        {value: 'Grade 8-10', text: 'Grade 8-10'}, {value: 'Grade 11-13', text: 'Grade 11-13'}]
            },
            {
                type: 'html',
                name: 'p2_bottom',
                html: '<h4 style="margin-top: 16px;"></h4>'
            }
        ];
        return elements;
    }

    getPage3(): any {

        const elements = [
            {
                type: 'html',
                name: 'p3q1',
                html: '<h4><span>I give permission:</span></h4>'
            },
            {
                type: 'html',
                name: 'p3q2b',
                html: '<h4 style="font-weight:normal"><span>For my assessment results to be used for research, provided that no personal identifying information is used.</span></h4>'
            },
            {
                type: 'dropdown',
                renderAs: 'select2',
                name: 'CONSENT_data',
                title: ' ',
                isRequired: true,
                choices: [{value: 'Yes', text: 'Yes'}, {value: 'No', text: 'No '}]
            },
            {
                type: 'html',
                name: 'p3_bottom',
                html: '<h4 style="margin-top: 16px;"></h4>'
            }
        ];
        return elements;
    }

    getPage4(): any {
        const elements = [
            {
                type: 'html',
                name: 'p4q1',
                html: '<h4 style="font-weight:normal"><span>How well do you think you drive? Rate your driving ability by answering the questions below. <br><br>If you are currently driving select <b>Active Driver</b>.<br><br> If you have not driven since your medical incident select <b>Non-Active Driver</b>.</span></h4>'
            },
            {
                type: 'dropdown',
                renderAs: 'select2',
                name: 'SELF_ASSESSMENT_select',
                title: ' ',
                isRequired: true,
                choices: [{value: 'Active Driver', text: 'Active Driver'}, {value: 'Non-Active Driver', text: 'Non-Active Driver'}]
            },
            {
                type: 'html',
                name: 'p4_bottom_1',
                html: '<h4 style="margin-top: 16px;"></h4>'
            },
            {
                type: 'html',
                name: 'p4q2',
                html: '<br><h4><b>ACTIVE DRIVER</b></h4>',
                visibleIf: '{SELF_ASSESSMENT_select} = "Active Driver"',
            },
            {
                type: 'matrix',
                name: 'SELF_ASSESSMENT_active_driver',
                title: ' ',
                description: 'Have you experienced the following in the past 6 months?',
                visibleIf: '{SELF_ASSESSMENT_select} = "Active Driver"',
                isAllRowRequired: true,
                columns: [{value: 0, text: 'Never'}, {value: 1, text: 'Sometimes'}, {value: 2, text: 'Always'}],
                rows: [
                    {
                        value: 'I get lost while driving in a relatively familiar environment.',
                        text: 'I get lost while driving in a relatively familiar environment.'
                    },
                    {
                        value: 'I forget where I was planning to drive to.',
                        text: 'I forget where I was planning to drive to.'
                    },
                    {
                        value: 'I have difficulty planning my appointments independently.',
                        text: 'I have difficulty planning my appointments independently.'
                    },
                    {
                        value: 'I have difficulty deciding who has right of way at an intersection.',
                        text: 'I have difficulty deciding who has right of way at an intersection.'
                    },
                    {
                        value: 'I get nervous at an intersection because there is so much to watch out for.',
                        text: 'I get nervous at an intersection because there is so much to watch out for.'
                    },
                    {
                        value: 'I find it difficult to merge with traffic on a busy road.',
                        text: 'I find it difficult to merge with traffic on a busy road.'
                    },
                    {
                        value: 'I get surprised by vehicles or pedestrians who appear out of nowhere.',
                        text: 'I get surprised by vehicles or pedestrians who appear out of nowhere.'
                    }
                ]
            },
            {
                type: 'html',
                name: 'p4q3',
                html: '<br><h4><b>NON-ACTIVE DRIVER</b></h4>',
                visibleIf: '{SELF_ASSESSMENT_select} = "Non-Active Driver"',
            },
            {
                type: 'matrix',
                name: 'SELF_ASSESSMENT_non_active_driver',
                title: ' ',
                description: 'Do you anticipate experiencing the following challenges when you resume driving?',
                visibleIf: '{SELF_ASSESSMENT_select} = "Non-Active Driver"',
                isAllRowRequired: true,
                columns: [{value: 0, text: 'No'}, {value: 1, text: 'Maybe'}, {value: 2, text: 'Yes'}],
                rows: [
                    {
                        value: 'I may get lost while driving in a relatively familiar environment.',
                        text: 'I may get lost while driving in a relatively familiar environment.'
                    },
                    {
                        value: 'I may forget where I am planning to drive to.',
                        text: 'I may forget where I am planning to drive to.'
                    },
                    {
                        value: 'I may struggle to plan my appointments independently.',
                        text: 'I may struggle to plan my appointments independently.'
                    },
                    {
                        value: 'I may struggle to decide who has right of way at an intersection.',
                        text: 'I may struggle to decide who has right of way at an intersection.'
                    },
                    {
                        value: 'I may get nervous at an intersection because there is so much to watch out for.',
                        text: 'I may get nervous at an intersection because there is so much to watch out for.'
                    },
                    {
                        value: 'I may find it difficult to merge with traffic on a busy road.',
                        text: 'I may find it difficult to merge with traffic on a busy road.'
                    },
                    {
                        value: 'I may get surprised by vehicles or pedestrians who appear out of nowhere.',
                        text: 'I may get surprised by vehicles or pedestrians who appear out of nowhere.'
                    }
                ]
            },
            {
                type: 'html',
                name: 'p4_bottom',
                html: '<span style="margin-top: 0px;"></span>'
            }
        ];
        return elements;
    }

    getPage6a(): any {
        const date: Date = new Date();
        const sdate = date.toDateString();
        const time = date.toLocaleTimeString();

        const minutes = [];
        let i = 0;
        for (i = 0; i < 60; i++) {
            if (i < 10) {
                minutes.push({value: i, text: '0' + i});
            } else {
                minutes.push({value: i, text: '' + i});
            }
        }

        const hours = [];
        let k = 0;
        for (k = 0; k < 24; k++) {
            if (k < 10) {
                hours.push({value: k, text: '0' + k});
            } else {
                hours.push({value: k, text: '' + k});
            }
        }

        const days = [];
        let m = 1;
        for (m = 1; m < 32; m++) {
            if (m < 10) {
                days.push({value: m, text: '0' + m});
            } else {
                days.push({value: m, text: '' + m});
            }
        }

        const years = [];
        const thisYear = date.getFullYear();
        const startYear = thisYear - 15;
        const endYear = thisYear + 5;
        let j = 1;
        for (j = startYear; j < endYear; j++) {
            years.push('' + j);
        }

        const elements = [
            {
                type: 'text',
                name: 'ORIENTATION_TIME_hiddenDateTime',
                visible: false,
                title: 'Actual Hidden Date Time',
                inputType: 'text',
                defaultValue: sdate + ' ' + time
            },
            {
                type: 'html',
                name: 'ORIENTATION_TIME_today_date',
                html: '<h4><span>What is today\'s date?</span></h4>'
            },
            {
                type: 'dropdown',
                renderAs: 'select2',
                name: 'ORIENTATION_TIME_year',
                title: 'Year',
                choices: years,
                maxWidth: '320px',
                minWidth: '320px'
            },
            {
                type: 'dropdown',
                renderAs: 'select2',
                name: 'ORIENTATION_TIME_month',
                title: 'Month',
                startWithNewLine: false,
                choices: [
                    {value: 0, text: 'January'},
                    {value: 1, text: 'February'},
                    {value: 2, text: 'March'},
                    {value: 3, text: 'April'},
                    {value: 4, text: 'May'},
                    {value: 5, text: 'June'},
                    {value: 6, text: 'July'},
                    {value: 7, text: 'August'},
                    {value: 8, text: 'September'},
                    {value: 9, text: 'October'},
                    {value: 10, text: 'November'},
                    {value: 11, text: 'December'}
                ],
                maxWidth: '320px',
                minWidth: '320px'
            },
            {
                type: 'dropdown',
                renderAs: 'select2',
                name: 'ORIENTATION_TIME_day',
                title: 'Day',
                startWithNewLine: false,
                choices: days,
                maxWidth: '280px',
                minWidth: '280px',
            },
            {
                type: 'html',
                name: ' ',
                html: '<br>'
            },
            {
                type: 'dropdown',
                renderAs: 'select2',
                name: 'ORIENTATION_TIME_week_day',
                startWithNewLine: true,
                title: 'What day of the week is it today?',
                choices: [
                    {value: 1, text: 'Monday'},
                    {value: 2, text: 'Tuesday'},
                    {value: 3, text: 'Wednesday'},
                    {value: 4, text: 'Thursday'},
                    {value: 5, text: 'Friday'},
                    {value: 6, text: 'Saturday'},
                    {value: 0, text: 'Sunday'}
                ],
                maxWidth: '300px',
                minWidth: '300px'
            },
            {
                type: 'html',
                name: 'ORIENTATION_TIME_no_look_now',
                html: '<br><h4><span>Without looking at any clock, what time do you think it is now?</span></h4>'
            },
            {
                type: 'dropdown',
                renderAs: 'select2',
                name: 'ORIENTATION_TIME_no_look_now_hour',
                startWithNewLine: true,
                title: 'Hour',
                choices: hours,
                maxWidth: '320px',
                minWidth: '320px'
            },
            {
                type: 'dropdown',
                renderAs: 'select2',
                name: 'ORIENTATION_TIME_no_look_now_minute',
                startWithNewLine: false,
                title: 'Minute',
                choices: minutes,
                maxWidth: '300px',
                minWidth: '300px'
            },
            {
                type: 'html',
                name: 'p6q8',
                html: '<br><h4><span>Have a look at a clock, what is the exact time?</span></h4>'
            },
            {
                type: 'dropdown',
                renderAs: 'select2',
                name: 'ORIENTATION_TIME_look_now_hour',
                startWithNewLine: true,
                title: 'Hour',
                choices: hours,
                maxWidth: '320px',
                minWidth: '320px'
            },
            {
                type: 'dropdown',
                renderAs: 'select2',
                name: 'ORIENTATION_TIME_look_now_minute',
                startWithNewLine: false,
                title: 'Minute',
                choices: minutes,
                width: '100px',
                maxWidth: '100px',
                minWidth: '100px'
            },
            {
                type: 'html',
                name: 'p6a_bottom',
                html: '<h4 style="margin-top: 16px;"></h4>'
            }
           ];
        return elements;
    }

    getPage6b(): any {
        const elements = [
            {
                type: 'html',
                name: 'p6q11a',
                html: '<h4><span>Using the detailed information provided below, calculate the exact time you need to leave to be on time for an appointment.</span></h4><h4><span>You are attending an appointment at 10h00. It takes you 35 minutes to get there and you allow an additional 15 minutes for potential delays such as traffic congestion and time to find parking. What is the latest time you can leave to be on time for the appointment?</span></h4>'
            },
            {
                type: 'dropdown',
                renderAs: 'select2',
                name: 'TIME_calculation',
                title: ' ',
                choices: [{value: '9h25', text: '9h25'}, {value: '9h20', text: '9h20'}, {value: '9h10', text: '9h10'}, {value: '9h00', text: '9h00'}]
            },
            {
                type: 'html',
                name: 'p6b_bottom',
                html: '<h4 style="margin-top: 16px;"></h4>'
            }
        ];
        return elements;
    }

    getPage7a(): any {
        const elements = [
            {
                type: 'html',
                name: 'p7q1',
                html: '<h4><span>In which traffic situations will you find the following traffic signs?  Click on the image where you think you will find this traffic sign. Each traffic sign only matches 1 image. <br><br>	In addition, you will be asked to recall the 4 traffic signs later in the assessment (in no specific order). So, remember to take note of the traffic signs shown.</span><br><br>Click on the image where you think you will find this traffic sign.</h4>'
            },
            {
                type: 'html',
                name: 'p7q2',
                html: '<center><img src="' + this.signPedestrianCrossingUrl + '"/></center>'
            },
            {
                type: 'imagepicker',
                name: 'IDENTIFY_PEDESTRIAN',
                title: ' ',
                startWithNewLine: true,
                colCount: 3,
                imageWidth: 280,
                choices: [
                    { value: 'norighturn1', imageLink: this.photoNoRightTurnUrl },
                    { value: 'redline1', imageLink: this.photoRedLineUrl },
                    { value: 'circle1', imageLink: this.photoCircleUrl },
                    { value: 'stop1', imageLink: this.photoStopUrl },
                    { value: 'pedestrian1', imageLink: this.photoPedestrianUrl },
                    { value: 'robots1', imageLink: this.photoRobotUrl }
                ]
            },
            {
                type: 'html',
                name: 'p7a_bottom',
                html: '<h4 style="margin-top: 8px;"></h4>'
            }
        ];
        return elements;
    }

    getPage7b(): any {
        const elements = [
            {
                type: 'html',
                name: 'p7q4',
                html: '<center><img src="' + this.signStopUrl + '"/></center>'
            },
             {
                type: 'imagepicker',
                name: 'IDENTIFY_STOP',
                title: 'Click on the image where you think you will find this traffic sign.',
                startWithNewLine: true,
                colCount: 3,
                imageWidth: 280,
                choices: [
                    { value: 'circle2', imageLink: this.photoCircleUrl },
                    { value: 'robots2', imageLink: this.photoRobotUrl },
                    { value: 'stop2', imageLink: this.photoStopUrl },
                    { value: 'pedestrian2', imageLink: this.photoPedestrianUrl },
                    { value: 'norighturn2', imageLink: this.photoNoRightTurnUrl },
                    { value: 'redline2', imageLink: this.photoRedLineUrl }
                ]
            },
            {
                type: 'html',
                name: 'p7b_bottom',
                html: '<h4 style="margin-top: 8px;"></h4>'
            }
        ];
        return elements;
    }

    getPage7c(): any {
        const elements = [
            {
                type: 'html',
                name: 'p7q6',
                html: '<center><img src="' + this.signTrafficCircleUrl + '"/></center>'
            },
            {
                type: 'imagepicker',
                name: 'IDENTIFY_CIRCLE',
                title: 'Click on the image where you think you will find this traffic sign.',
                startWithNewLine: true,
                colCount: 3,
                imageWidth: 280,
                choices: [
                    { value: 'stop3', imageLink: this.photoStopUrl },
                    { value: 'norighturn3', imageLink: this.photoNoRightTurnUrl },
                    { value: 'robots3', imageLink: this.photoRobotUrl },
                    { value: 'circle3', imageLink: this.photoCircleUrl },
                    { value: 'redline3', imageLink: this.photoRedLineUrl },
                    { value: 'pedestrian3', imageLink: this.photoPedestrianUrl }
                ]
            },
            {
                type: 'html',
                name: 'p7c_bottom',
                html: '<h4 style="margin-top: 8px;"></h4>'
            }
        ];
        return elements;
    }

    getPage7d(): any {
        const elements = [
            {
                type: 'html',
                name: 'p7q8',
                html: '<center><img src="' + this.signRightTurnUrl + '"/></center>'
            },
            {
                type: 'imagepicker',
                name: 'IDENTIFY_RIGHT_TURN',
                title: 'Click on the image where you think you will find this traffic sign.',
                startWithNewLine: true,
                colCount: 3,
                imageWidth: 280,
                choices: [
                    { value: 'pedestrian4', imageLink: this.photoPedestrianUrl },
                    { value: 'norighturn4', imageLink: this.photoNoRightTurnUrl },
                    { value: 'stop4', imageLink: this.photoStopUrl },
                    { value: 'redline4', imageLink: this.photoRedLineUrl },
                    { value: 'circle4', imageLink: this.photoCircleUrl },
                    { value: 'robots4', imageLink: this.photoRobotUrl }
                ]
            },
            {
                type: 'html',
                name: 'p7d_bottom',
                html: '<h4 style="margin-top: 8px;"></h4>'
            }
        ];
        return elements;
    }

    getPage8a(): any {
        const elements = [
            {
                type: 'html',
                name: 'p8q1',
                html: '<h4><span>Listen to the directions to go to a specific location.<br><br>Once you have listened to the directions, select the correct images on the next page that show the directions to the location. <br><br> In addition to completing the task now you need to remember the directions as you will be asked to repeat the directions later in the assessment.<br></span></h4>'
            },
            {
                type: 'html',
                name: 'p8q2',
                html: '<h4><span>Click the play button <img style="vertical-align: middle;" src="' + this.arrowPlay + '"/> below.</span></h4><audio controls src="' + this.audioUrl + '">Your browser does not support the <code>audio</code> element.</audio>'
            },
            {
                type: 'dropdown',
                renderAs: 'select2',
                name: 'p8q2c',
                title: 'I have listened to the directions.',
                isRequired: true,
                choices: [{value: 'Yes', text: 'Yes'}],
            },
            {
                type: 'html',
                name: 'p8q2b',
                html: '<br><h4><span>Click the NEXT button after listening to the directions.</span></h4>'
            },
            {
                type: 'html',
                name: 'p8a_bottom',
                html: '<h4 style="margin-top: 16px;"></h4>'
            }
        ];
        return elements;
    }

    getPage8b(): any {
        const elements = [
            {
                type: 'html',
                name: 'p8q3a',
                html: '<h4><span>Click on the images that show the directions to the location.</span></h4><h4><span><b>DIRECTION 1</b></span></h4>'
            },
            {
                type: 'imagepicker',
                name: 'DIRECTION1',
                title: ' ',
                startWithNewLine: true,
                colCount: 4,
                imageWidth: 280,
                choices: [
                    {
                        value: 'first',
                        imageLink: this.direction4Url
                    },
                    {
                        value: 'second',
                        imageLink: this.direction3Url
                    },
                    {
                        value: 'third',
                        imageLink: this.direction2Url
                    },
                    {
                        value: 'fourth',
                        imageLink: this.direction1Url
                    }
                ]
            },
            {
                type: 'html',
                name: 'p8q4a',
                html: '<h4><span><b>DIRECTION 2</b></span></h4>'
            },
            {
                type: 'imagepicker',
                name: 'DIRECTION2',
                title: ' ',
                startWithNewLine: true,
                colCount: 4,
                imageWidth: 280,
                choices: [
                    {
                        value: 'straight',
                        imageLink: this.direction12Url
                    },
                    {
                        value: 'right',
                        imageLink: this.direction9Url
                    },
                    {
                        value: 'left',
                        imageLink: this.direction10Url
                    },
                    {
                        value: 'uturn',
                        imageLink: this.direction11Url
                    }
                ]
            },
            {
                type: 'html',
                name: 'p8q5a',
                html: '<h4><span><b>DIRECTION 3</b></span></h4>'
            },
            {
                type: 'imagepicker',
                name: 'DIRECTION3',
                title: ' ',
                startWithNewLine: true,
                colCount: 4,
                imageWidth: 280,
                choices: [
                    {
                        value: 'firstleft',
                        imageLink: this.direction5Url
                    },
                    {
                        value: 'firstright',
                        imageLink: this.direction6Url
                    },
                    {
                        value: 'secondleft',
                        imageLink: this.direction7Url
                    },
                    {
                        value: 'secondright',
                        imageLink: this.direction8Url
                    }
                ]
            },
            {
                type: 'html',
                name: 'p8q6a',
                html: '<h4><span><b>DIRECTION 4</b></span></h4>'
            },
            {
                type: 'imagepicker',
                name: 'DIRECTION4',
                title: ' ',
                startWithNewLine: true,
                colCount: 4,
                imageWidth: 280,
                choices: [
                    {
                        value: 'fuelstation',
                        imageLink: this.direction13Url
                    },
                    {
                        value: 'firestation',
                        imageLink: this.direction14Url
                    },
                    {
                        value: 'hospital',
                        imageLink: this.direction15Url
                    },
                    {
                        value: 'postoffice',
                        imageLink: this.direction16Url
                    }
                ]
            },
            {
                type: 'html',
                name: 'p8b_bottom',
                html: '<h4 style="margin-top: 8px;"></h4>'
            }
        ];
        return elements;
    }

    getPage9(): any {
        const elements = [
            {
                type: 'html',
                name: 'p9q1',
                html: '<h4><span>Using the information provided below, calculate the following:<br><br> You have a R50 note. Parking costs R12 per hour and R6 per half hour. You park for 2½ hours.</span></h4>'
            },
            {
                type: 'dropdown',
                renderAs: 'select2',
                name: 'MONEY_MANAGEMENT_parking',
                title: 'How much does your parking cost?',
                choices: [
                 {
                  value: 36,
                  text: 'R36'
                 },
                 {
                  value: 30,
                  text: 'R30'
                 },
                 {
                  value: 24,
                  text: 'R24'
                 },
                 {
                  value: 18,
                  text: 'R18'
                 }
                ]
            },
            {
                type: 'dropdown',
                renderAs: 'select2',
                name: 'MONEY_MANAGEMENT_change',
                title: 'How much change do you get?',
                choices: [
                 {
                  value: 32,
                  text: 'R32'
                 },
                 {
                  value: 26,
                  text: 'R26'
                 },
                 {
                  value: 20,
                  text: 'R20'
                 },
                 {
                  value: 14,
                  text: 'R14'
                 }
                ]
            },
            {
                type: 'html',
                name: 'p9_bottom',
                html: '<h4 style="margin-top: 16px;"></h4>'
            }
        ];
        return elements;
    }

    getPage10(): any {
        const elements = [
            {
                type: 'html',
                name: 'p10q1',
                html: '<h4><span>Using the information provided below, determine when you need to fill up your car with fuel.<br><br><span>You get into the car and are about to start your trip to work. You are running late. As you are about to leave home your fuel light comes on, indicating that you are able to drive 30km before you run out of fuel. A one-way trip from your house to work is 20km. On route there is only one fuel station which is 5km from your home.</span></span></h4>'
            },
            {
                type: 'dropdown',
                renderAs: 'select2',
                name: 'FUEL_CALCULATION',
                title: ' ',
                choices: [
                 {
                  value: 'I have enough fuel to drive to work, back home and fill up the next time I drive my car.',
                  text: 'I have enough fuel to drive to work, back home and fill up the next time I drive my car.'
                 },
                 {
                  value: 'I have sufficient fuel to fill up my car on the return trip home.',
                  text: 'I have sufficient fuel to fill up my car on the return trip home.'
                 },
                 {
                  value: 'I have to fill up my car on my way to work.',
                  text: 'I have to fill up my car on my way to work.'
                 },
                 {
                     value: 'I need to call a colleague to give me a lift to work because I do not have sufficient fuel to drive to the fuel station.',
                     text: 'I need to call a colleague to give me a lift to work because I do not have sufficient fuel to drive to the fuel station.'
                 }
                ]
            },
            {
                type: 'html',
                name: 'p10_bottom',
                html: '<h4 style="margin-top: 16px;"></h4>'
            }
        ];
        return elements;
    }

    getPage11a(): any {
        const elements = [
            {
                type: 'imagepicker',
                name: 'MEMORY1',
                title: 'Below are images of 8 traffic signs. Select the 4 traffic signs that you linked to traffic scenarios earlier in the assessment. It does not matter in which order you select the correct traffic signs.',
                multiSelect: true,
                colCount: 4,
                imageWidth: 240,
                choices: [
                    { value: 'noEntry', imageLink: this.signNoEntryUrl },
                    { value: 'rightTurn', imageLink: this.signRightTurnUrl },
                    { value: 'trafficLight', imageLink: this.signTrafficLightUrl },
                    { value: 'pedestrianCrossing', imageLink: this.signPedestrianCrossingUrl },
                    { value: 'stop', imageLink: this.signStopUrl },
                    { value: 'noParking', imageLink: this.signNoParkingUrl },
                    { value: 'leftTurn', imageLink: this.signLeftTurnUrl  },
                    { value: 'circle', imageLink: this.signTrafficCircleUrl }
                ]
            },
            {
                type: 'html',
                name: 'p11a_bottom',
                html: '<h4 style="margin-top: 8px;"></h4>'
            }
        ];
        return elements;
    }

    getPage11b(): any {
        const elements = [
            {
                type: 'html',
                name: 'p11q2',
                html: '<h4><span>Repeat the directions to the specific location that you heard earlier.<br><br> Click on the images that show the directions to the location.</span></h4>'
            },
            {
                type: 'html',
                name: 'p11q3a',
                html: '<h4><span><b>DIRECTION 1</b></span></h4>'
            },
            {
                type: 'imagepicker',
                name: 'MEMORY2_DIRECTION1',
                title: ' ',
                startWithNewLine: true,
                colCount: 4,
                imageWidth: 280,
                choices: [
                    {
                        value: 'fourth',
                        imageLink: this.direction1Url
                    },
                    {
                        value: 'third',
                        imageLink: this.direction2Url
                    },
                    {
                        value: 'second',
                        imageLink: this.direction3Url
                    },
                    {
                        value: 'first',
                        imageLink: this.direction4Url
                    }
                ]
            },
            {
                type: 'html',
                name: 'p11q4a',
                html: '<h4><span><b>DIRECTION 2</b></span></h4>'
            },
            {
                type: 'imagepicker',
                name: 'MEMORY2_DIRECTION2',
                title: ' ',
                startWithNewLine: true,
                colCount: 4,
                imageWidth: 280,
                choices: [
                    {
                        value: 'uturn',
                        imageLink: this.direction11Url
                    },
                    {
                        value: 'right',
                        imageLink: this.direction9Url
                    },
                    {
                        value: 'straight',
                        imageLink: this.direction12Url
                    },
                    {
                        value: 'left',
                        imageLink: this.direction10Url
                    },
                ]
            },
            {
                type: 'html',
                name: 'p11q5a',
                html: '<h4><span><b>DIRECTION 3</b></span></h4>'
            },
            {
                type: 'imagepicker',
                name: 'MEMORY2_DIRECTION3',
                title: ' ',
                startWithNewLine: true,
                colCount: 4,
                imageWidth: 280,
                choices: [
                    {
                        value: 'secondleft',
                        imageLink: this.direction7Url
                    },
                    {
                        value: 'firstright',
                        imageLink: this.direction6Url
                    },
                    {
                        value: 'firstleft',
                        imageLink: this.direction5Url
                    },
                    {
                        value: 'secondright',
                        imageLink: this.direction8Url
                    }
                ]
            },
            {
                type: 'html',
                name: 'p11q6a',
                html: '<h4><span><b>DIRECTION 4</b></span></h4>'
            },
            {
                type: 'imagepicker',
                name: 'MEMORY2_DIRECTION4',
                title: ' ',
                startWithNewLine: true,
                colCount: 4,
                imageWidth: 280,
                choices: [
                    {
                        value: 'postoffice',
                        imageLink: this.direction16Url
                    },
                    {
                        value: 'firestation',
                        imageLink: this.direction14Url
                    },
                    {
                        value: 'fuelstation',
                        imageLink: this.direction13Url
                    },
                    {
                        value: 'hospital',
                        imageLink: this.direction15Url
                    },
                ]
            },
            {
                type: 'html',
                name: 'p11b_bottom',
                html: '<h4 style="margin-top: 8px;"></h4>'
            }
        ];
        return elements;
    }

    getPage12(): any {
        const elements = [
            {
                type: 'html',
                name: 'p12q1',
                html: '<h4><span>In the image below you will see overlapping traffic signs. The image consists of the following 6 traffic signs:</span></5>'
            },
            {
                type: 'html',
                name: 'p12q2',
                // tslint:disable-next-line:max-line-length
                html: '<div style="width: 120px;display:inline-block;"><img src="' + this.roadtraffic1Url + '" /></div><div style="width: 120px;display:inline-block;"><img src="' + this.roadtraffic2Url + '" /></div><div style="width: 120px;display:inline-block;"><img src="' + this.roadtraffic3Url + '" /></div><div style="width: 120px;display:inline-block;"><img src="' + this.roadtraffic4Url + '" /></div><div style="width: 120px;display:inline-block;"><img src="' + this.roadtraffic5Url + '" /></div><div style="width: 120px;display:inline-block;"><img src="' + this.roadtraffic6Url + '" /></div>'
            },
            {
                type: 'html',
                name: 'p12q3',
                html: '<br><h4><span>Click on every NO ENTRY <div style="display:inline-block;"><img src="' + this.roadtraffic3Url + '" /></div> sign you can see, even if you can only see it partially.<br><br> Use the scroll function to scroll across the entire image to ensure that you do not miss a traffic sign.</span></5>'
            },
            {
                type: 'html',
                name: 'p12q4',
                // html: '<object height="770px" width="1014px" type="image/svg+xml" data="./trafficSignSvg"/>'
                html: '<iframe frameborder="0" width="1014px" height="770px" type="text/html" src="./trafficSignSvg"> </iframe>'
            },
            {
                type: 'html',
                name: 'p12_bottom',
                html: '<h4 style="margin-top: 8px;"></h4>'
            }
        ];
        return elements;
    }

    getPage13a(): any {
        const elements = [
            {
                type: 'html',
                name: 'p13q1',
                html: '<h4><span>Who has right of way? Indicate the correct order in which the vehicles should proceed. 1= the first vehicle and 2 = the second vehicle.</span></h4>'
            },
            {
                type: 'html',
                name: 'p13q1b',
                html: '<h4><span><b>INTERSECTION 1<b></span></h4>'
            },
            {
                type: 'dropdown',
                renderAs: 'select2',
                name: 'INTERSECTIONS1A',
                title: '1. First',
                startWithNewLine: true,
                choices: [{value: 'A', text: 'A'}, {value: 'B', text: 'B'}]
            },
            {
                type: 'dropdown',
                renderAs: 'select2',
                name: 'INTERSECTIONS1B',
                title: '2. Second',
                startWithNewLine: false,
                choices: [{value: 'A', text: 'A'}, {value: 'B', text: 'B'}]
            },
            {
                type: 'html',
                name: 'p13q2',
                html: '<center><img class="intersections" src="' + this.intersection1Url + '"/></center>'
            },
            {
                type: 'html',
                name: 'p13a_bottom',
                html: '<h4 style="margin-top: 0px;"></h4>'
            }
       ];
        return elements;
    }

    getPage13b(): any {
        const elements = [
            {
                type: 'html',
                name: 'p13q1',
                html: '<h4><span>Indicate the correct order in which the vehicles should proceed. 1= the first vehicle, 2 = the second vehicle and 3= the third vehicle.</span></h4>'
            },
            {
                type: 'html',
                name: 'p13q1b',
                html: '<h4><span><b>INTERSECTION 2<b></span></h4>'
            },
            {
                type: 'dropdown',
                renderAs: 'select2',
                name: 'INTERSECTIONS2A',
                title: '1. First',
                startWithNewLine: true,
                choices: [{value: 'A', text: 'A'}, {value: 'B', text: 'B'}, {value: 'C', text: 'C'}]
            },
            {
                type: 'dropdown',
                renderAs: 'select2',
                name: 'INTERSECTIONS2B',
                title: '2. Second',
                startWithNewLine: false,
                choices: [{value: 'A', text: 'A'}, {value: 'B', text: 'B'}, {value: 'C', text: 'C'}]
            },
            {
                type: 'dropdown',
                renderAs: 'select2',
                name: 'INTERSECTIONS2C',
                title: '3. Third',
                minWidth: '280px',
                startWithNewLine: false,
                choices: [{value: 'A', text: 'A'}, {value: 'B', text: 'B'}, {value: 'C', text: 'C'}]
            },
            {
                type: 'html',
                name: 'p13q2',
                html: '<center><img class="intersections" src="' + this.intersection2Url + '"/></center>'
            },
            {
                type: 'html',
                name: 'p13b_bottom',
                html: '<h4 style="margin-top: 0px;"></h4>'
            }
       ];
        return elements;
    }

    getPage13c(): any {
        const elements = [
            {
                type: 'html',
                name: 'p13q1',
                html: '<h4><span>Indicate the correct order in which the vehicles should proceed. 1= the first vehicle, 2 = the second vehicle and 3= the third vehicle.</span></h4>'
            },
            {
                type: 'html',
                name: 'p13q1b',
                html: '<h4><span><b>INTERSECTION 3<b></span></h4>'
            },
            {
                type: 'dropdown',
                renderAs: 'select2',
                name: 'INTERSECTIONS3A',
                title: '1. First',
                startWithNewLine: true,
                choices: [{value: 'A', text: 'A'}, {value: 'B', text: 'B'}, {value: 'C', text: 'C'}]
            },
            {
                type: 'dropdown',
                renderAs: 'select2',
                name: 'INTERSECTIONS3B',
                title: '2. Second',
                startWithNewLine: false,
                choices: [{value: 'A', text: 'A'}, {value: 'B', text: 'B'}, {value: 'C', text: 'C'}]
            },
            {
                type: 'dropdown',
                renderAs: 'select2',
                name: 'INTERSECTIONS3C',
                title: '3. Third',
                minWidth: '280px',
                startWithNewLine: false,
                choices: [{value: 'A', text: 'A'}, {value: 'B', text: 'B'}, {value: 'C', text: 'C'}]
            },
            {
                type: 'html',
                name: 'p13q2a',
                html: '<br><center><h4><span><b>Note:</b>  Vehicle A and B arrive at the stop street at the same time.</span></h4></center>'
            },
            {
                type: 'html',
                name: 'p13q2',
                // tslint:disable-next-line:max-line-length
                html: '<center><img class="intersections" src="' + this.intersection3Url + '"/></center>'
            },
            {
                type: 'html',
                name: 'p13c_bottom',
                html: '<h4 style="margin-top: 0px;"></h4>'
            }
       ];
        return elements;
    }

    getPage13d(): any {
        const elements = [
            {
                type: 'html',
                name: 'p13q1',
                html: '<h4><span>Indicate the correct order in which the vehicles should proceed. 1= the first vehicle and 2 = the second vehicle.</span></h4>'
            },
            {
                type: 'html',
                name: 'p13q1b',
                html: '<h4><span><b>INTERSECTION 4<b></span></h4>'
            },
            {
                type: 'dropdown',
                renderAs: 'select2',
                name: 'INTERSECTIONS4A',
                title: '1. First',
                startWithNewLine: true,
                minWidth: '320px',
                choices: [{value: 'A', text: 'A'}, {value: 'B', text: 'B'}]
            },
            {
                type: 'dropdown',
                renderAs: 'select2',
                name: 'INTERSECTIONS4B',
                title: '2. Second',
                startWithNewLine: false,
                minWidth: '280px',
                choices: [{value: 'A', text: 'A'}, {value: 'B', text: 'B'}]
            },
            {
                type: 'html',
                name: 'p13q2',
                html: '<center><img class="intersections" src="' + this.intersection4Url + '"/></center>'
            },
            {
                type: 'html',
                name: 'p13d_bottom',
                html: '<h4 style="margin-top: 0px;"></h4>'
            }
       ];
        return elements;
    }

    getPage14a(): any {
        const elements = [
            // {
            //     type: 'dropdown',
            //     renderAs: 'select2',
            //     name: 'LANDMARK_q1',
            //     title: 'In the map below, which shop is closest to the fuel station?',
            //     choices: ['Shop 1', 'Shop 2', 'Shop 3', 'Shop 4']
            // },
            {
                type: 'html',
                name: 'p14aq1',
                html: '<h4><span>In the map below, which shop is closest to the fuel station?  Select the correct shop by clicking on the image in the map below.</span></h4>'
            },
            {
                type: 'html',
                name: 'p14aq2',
                html: '<h4><span>Use the scroll function to scroll across the entire map to ensure that you do not miss anything.</span></h4>'
            },
            {
                type: 'html',
                name: 'p14aq4',
                //html: '<object height="525px" width="728px" style="border: 6px solid #1ab394;" type="image/svg+xml" data="./landmarkSvg"/>'
                html: '<iframe width="728px" height="525px" type="image/svg+xml" src="./landmarkSvg" style="border: 6px solid #1ab394;"> </iframe>'
            },
            {
                type: 'html',
                name: 'p14a_bottom',
                html: '<h4 style="margin-top: 0px;"></h4>'
            }
        ];
        return elements;
    }

    getPage14aa(): any {
        const elements = [
            // {
            //     type: 'dropdown',
            //     renderAs: 'select2',
            //     name: 'LANDMARK_q2',
            //     title: 'In the map below, using the most direct route, traveling from the fuel station to the closest shop, you:',
            // tslint:disable-next-line:max-line-length
            //     choices: ['Go past the church.', 'Go over a pedestrian crossing.', 'Go past the park.', 'Go over a bridge, crossing a river.']
            // },
            {
                type: 'html',
                name: 'p14aaq1',
                html: '<h4><span>Which shop is closest to a traffic circle (roundabout)? Select the correct shop by clicking on the image in the map below.</span></h4>'
            },
            {
                type: 'html',
                name: 'p14aaq2',
                html: '<h4><span>Use the scroll function to scroll across the entire map to ensure that you do not miss anything.</span></h4>'
            },
            {
                type: 'html',
                name: 'p14aaq3',
               // html: '<object height="525px" width="728px" style="border: 6px solid #005b97;" type="image/svg+xml" data="./landmarkSvg2"/>'
               html: '<iframe width="728px" height="525px" type="image/svg+xml" src="./landmarkSvg2" style="border: 6px solid #005b97;"> </iframe>'
            },
            {
                type: 'html',
                name: 'p14aa_bottom',
                html: '<h4 style="margin-top: 0px;"></h4>'
            }
        ];
        return elements;
    }

    getPage14aaa(): any {
        const elements = [
            // {
            //     type: 'dropdown',
            //     renderAs: 'select2',
            //     name: 'LANDMARK_q3',
            //     title: 'In the map below, which restaurant is furthest from the sports field?',
            //     choices: ['Restaurant 1', 'Restaurant 2', 'Restaurant 3', 'Restaurant 4']
            // },
            {
                type: 'html',
                name: 'p14aaaq1',
                html: '<h4><span>In the map below, which restaurant is furthest from the sports field?  Select the correct restaurant by clicking on the image in the map below.</span></h4>'
            },
            {
                type: 'html',
                name: 'p14aaaq2',
                html: '<h4><span>Use the scroll function to scroll across the entire map to ensure that you do not miss anything.</span></h4>'
            },
            {
                type: 'html',
                name: 'p14aaaq4',
                 //html: '<object height="525px" width="728px" style="border: 6px solid #939598;" type="image/svg+xml" data="./landmarkSvg3"/>'
                 html: '<iframe width="728px" height="525px" type="image/svg+xml" src="./landmarkSvg3" style="border: 6px solid #939598;"> </iframe>'
            },
            {
                type: 'html',
                name: 'p14aaa_bottom',
                html: '<h4 style="margin-top: 0px;"></h4>'
            }
        ];
        return elements;
    }

    getPage14(): any {
        const elements = [
            {
                type: 'matrix',
                name: 'ROADRULES',
                title: 'Are the following statements true or false?',
                columns: [
                 {
                  value: 1,
                  text: 'True'
                 },
                 {
                  value: 0,
                  text: 'False'
                 }
                ],
                rows: [
                 {
                  value: 'Keep right, pass left.',
                  text: 'Keep right, pass left.'
                 },
                 {
                  value: 'When I overtake a car, I don’t have to use my indicator.',
                  text: 'When I overtake a car, I don’t have to use my indicator.'
                 },
                 {
                  value: 'When I change lanes, I must check my rear and side view mirrors.',
                  text: 'When I change lanes, I must check my rear and side view mirrors.'
                 },
                 {
                  value: 'When I arrive first at a 4 way stop, I have right of way.',
                  text: 'When I arrive first at a 4 way stop, I have right of way.'
                 }
                ]
            },
            {
                type: 'html',
                name: 'p14_bottom',
                html: '<span style="margin-top: 0px;"></span>'
            }
        ];
        return elements;
    }

    getPage15a(): any {
        const elements = [
            {
                type: 'dropdown',
                renderAs: 'select2',
                name: 'ROADSIDEFACTORS_q1',
                title: 'Looking at the traffic signs, in the image below, what type of road is the road, crossing the main road?',
                choices: ['Dual carriage way', 'Cul-de-sac', 'Bus lane', 'One way']
            },
            {
                type: 'html',
                name: 'p15q2',
                html: '<br><img height="600px" width="1110px" src="' + this.photoRoadsideFactorUrl + '"/>'
            },
            {
                type: 'html',
                name: 'p15a_bottom',
                html: '<h4 style="margin-top: 16px;"></h4>'
            }
        ];
        return elements;
    }

    getPage15aa(): any {
        const elements = [
            {
                type: 'html',
                name: 'p15q1',
                html: '<h4><span>In addition to traffic signs, there are many things, in your surroundings, that determined how fast or slow you drive.  Click on all the things you can see in the image that impact on your driving speed.<br><br>Use the scroll function to scroll across the entire image to ensure that you do not miss anything.</span></h4>'
            },
            {
                type: 'html',
                name: 'p15q2',
                // html: '<object height="530px" width="1110px" type="image/svg+xml" data="./roadsideFactorsSvg1"/>'
                html: '<iframe frameborder="0" width="1110px" height="530px" type="image/svg+xml" src="./roadsideFactorsSvg1"> </iframe>'
            },
            {
                type: 'html',
                name: 'p15aa_bottom',
                html: '<h4 style="margin-top: 8px;"></h4>'
            }
        ];
        return elements;
    }

    getPage16a(): any {
        const elements = [
            {
                type: 'dropdown',
                renderAs: 'select2',
                name: 'DRIVING_MANOEUVRE_triangular',
                title: 'In the image below, what does the triangular sign mean?',
                choices: ['One road lane is merging into another.', 'There is a left turn coming up.', 'U turns allowed.', 'There is a T junction ahead.']
            },
            {
                type: 'html',
                name: 'p16q1',
                html: '<br><img src="' + this.drivingManouever + '" height="500px" /><br>'
            },
            {
                type: 'html',
                name: 'p16a_bottom',
                html: '<h4 style="margin-top: 16px;"></h4>'
            }
        ];
        return elements;
    }

    getPage16b(): any {
        const elements = [
            {
                type: 'html',
                name: 'p16q3',
                html: '<h4><span>In the image below click on the items that you use when merging with traffic. <br><br> Use the scroll function to scroll across the entire image to ensure that you do not miss an item.</span></h4>'
            },
            {
                type: 'html',
                name: 'p16q4',
                // html: '<object height="730px" width="1120px" type="image/svg+xml" data="./carInteriorSvg"/>'
                html: '<iframe class="parentFrame" frameborder="0" width="1120px" height="730px" type="text/html" src="./carInteriorSvg"> </iframe>'
            },
            {
                type: 'html',
                name: 'p16b_bottom',
                html: '<h4 style="margin-top: 16px;"></h4>'
            }
        ];
        return elements;
    }

    getPage17a(): any {
        const elements = [
            {
                type: 'html',
                name: 'p17aq3',
                html: '<iframe class="parentFrame" frameborder="0" width="100%" type="text/html" src="./video1"> </iframe>'
            },
            // {
            //     type: 'html',
            //     name: 'p17a_bottom',
            //     html: '<h4 style="margin-top: 0px;"></h4>'
            // }
        ];
        return elements;
    }

    getPage17b(): any {
        const elements = [
            {
                type: 'html',
                name: 'p17bq3',
                html: '<iframe class="parentFrame" frameborder="0" width="100%" type="text/html" src="./video2"> </iframe>'
            },
            // {
            //     type: 'html',
            //     name: 'p17b_bottom',
            //     html: '<h4 style="margin-top: 0px;"></h4>'
            // }
        ];
        return elements;
    }

    getPage17c(): any {
        const elements = [
            {
                type: 'html',
                name: 'p17cq3',
                html: '<iframe class="parentFrame" frameborder="0" width="100%" type="text/html" src="./video3"> </iframe>'
            },
            // {
            //     type: 'html',
            //     name: 'p17c_bottom',
            //     html: '<h4 style="margin-top: 0px;"></h4>'
            // }
        ];
        return elements;
    }

    getPage18(): any {
        const elements = [
            {
                type: 'html',
                name: 'p17q2',
                // tslint:disable-next-line:max-line-length
                html: '<h4><span>Your car is in for repairs and you have made arrangements to meet a friend at a shopping mall. Instead of cancelling your plans you decide to order a taxi using an app on your phone. You are given the following instructions to order a taxi:<br><br>1.  Use the taxi app called Arrive. The icon for the Arrive app looks like this: <img style="vertical-align: bottom;" height="60px" src="' + this.taxiLogo1Url + '"/><br>2.  Choose your destination. You are meeting your friend at A2Z Mall.<br>3.  The taxi service provides various ride options. Choose the cheapest option.<br>4.  Share your ride with your friend, so that they can track your trip and know when you arrive at the mall.</span></h4>'
            },
            {
                type: 'html',
                name: 'p17q3',
                // tslint:disable-next-line:max-line-length
                html: '<h4><span>Use the above information to answer the following questions.</span></h4>'
            },
            {
                type: 'imagepicker',
                name: 'ORDERTAXI1',
                title: '1.  Which taxi app are you going to use? Select the icon for the taxi app you have been instructed to use.',
                startWithNewLine: true,
                choices: [
                    {
                        value: 'taxiLogo1',
                        imageLink: this.taxiLogo3Url
                    },
                    {
                        value: 'taxiLogo2',
                        imageLink: this.taxiLogo2Url
                    },
                    {
                        value: 'taxiLogo3',
                        imageLink: this.taxiLogo1Url
                    },
                    {
                        value: 'taxiLogo4',
                        imageLink: this.taxiLogo4Url
                    }
                ]
            },
            {
                type: 'dropdown',
                renderAs: 'select2',
                name: 'ORDERTAXI2',
                title: '2.  Where are you meeting your friend? Choose your destination.',
                choices: [
                    {
                        value: 'Z2A Mall',
                        test: 'Z2A Mall'
                    },
                    {
                        value: 'A2Z Mall',
                        test: 'A2Z Mall'
                    },
                    {
                        value: 'A2B Mall',
                        test: 'A2B Mall'
                    },
                    {
                        value: 'AZ2 Mall',
                        test: 'AZ2 Mall'
                    }
                ]
            },
            {
                type: 'dropdown',
                renderAs: 'select2',
                name: 'ORDERTAXI3',
                title: '3.  Which ride option are you going to use? Choose a ride.',
                choices: [
                    {
                        value: 'Premium: R175',
                        test: 'Premium: R175'
                    },
                    {
                        value: 'Maxi: R165',
                        test: 'Maxi: R165'
                    },
                    {
                        value: 'Go: R125',
                        test: 'Go: R125'
                    },
                    {
                        value: 'Saver: R115',
                        test: 'Saver: R115'
                    }
                ]
            },
            {
                type: 'dropdown',
                renderAs: 'select2',
                name: 'ORDERTAXI4',
                title: '4.  Do you want your friend to track your ride?',
                choices: [
                    {
                        value: 'No',
                        test: 'No'
                    },
                    {
                        value: 'Yes',
                        test: 'Yes'
                    }
                ]
            },
            {
                type: 'html',
                name: 'p18_bottom',
                html: '<h4 style="margin-top: 16px;"></h4>'
            }
        ];
        return elements;
    }

    getPage19(): any {
        const elements = [
            {
                type: 'matrix',
                name: 'SELF_ASSESSMENT_performance',
                isRequired: true,
                title: 'How do you think you performed in this assessment?',
                columns: [
                    {
                        value: '0',
                        text: 'Poor'
                    },
                    {
                        value: '1',
                        text: 'Average'
                    },
                    {
                        value: '2',
                        text: 'Great'
                    }
                ],
                rows: [
                    {
                        value: 'How do you think you performed in this assessment?',
                        text: 'How do you think you performed in this assessment?'
                    }
                ]
        },
        {
            type: 'html',
            name: 'p19_bottom',
            html: '<span style="margin-top: 0px;"></span>'
        }
    ];
        return elements;
    }

}
