import { Component, OnInit, OnDestroy } from '@angular/core';

@Component({
  selector: 'app-road-sign-svg',
  templateUrl: './road-sign-svg.component.svg',
  styleUrls: []
})
export class RoadSignSvgComponent implements OnInit, OnDestroy {

  FILL80_1 = 'transparent';
  FILL80_2 = 'transparent';
  FILL80_3 = 'transparent';
  FILL80_4 = 'transparent';
  FILL80_5 = 'transparent';
  FILL80_6 = 'transparent';
  FILL80_7 = 'transparent';
  FILL80_8 = 'transparent';
  FILL80_9 = 'transparent';
  FILL80_10 = 'transparent';
  FILL80_11 = 'transparent';
  FILL80_12 = 'transparent';

  NOUTURN_1 = 'transparent';
  NOUTURN_2 = 'transparent';
  NOUTURN_3 = 'transparent';
  NOUTURN_4 = 'transparent';
  NOUTURN_5 = 'transparent';
  NOUTURN_6 = 'transparent';
  NOUTURN_7 = 'transparent';
  NOUTURN_8 = 'transparent';
  NOUTURN_9 = 'transparent';
  NOUTURN_10 = 'transparent';
  NOUTURN_11 = 'transparent';

  NOENTRY_1 = 'transparent';
  NOENTRY_2 = 'transparent';
  NOENTRY_3 = 'transparent';
  NOENTRY_4 = 'transparent';
  NOENTRY_5 = 'transparent';
  NOENTRY_6 = 'transparent';
  NOENTRY_7 = 'transparent';
  NOENTRY_8 = 'transparent';
  NOENTRY_9 = 'transparent';

  DEADEND_1 = 'transparent';
  DEADEND_2 = 'transparent';
  DEADEND_3 = 'transparent';
  DEADEND_4 = 'transparent';
  DEADEND_5 = 'transparent';
  DEADEND_6 = 'transparent';
  DEADEND_7 = 'transparent';
  DEADEND_8 = 'transparent';
  DEADEND_9 = 'transparent';
  DEADEND_10 = 'transparent';
  DEADEND_11 = 'transparent';

  PEDESTRIAN_1 = 'transparent';
  PEDESTRIAN_2 = 'transparent';
  PEDESTRIAN_3 = 'transparent';
  PEDESTRIAN_4 = 'transparent';
  PEDESTRIAN_5 = 'transparent';
  PEDESTRIAN_6 = 'transparent';
  PEDESTRIAN_7 = 'transparent';
  PEDESTRIAN_8 = 'transparent';
  PEDESTRIAN_9 = 'transparent';
  PEDESTRIAN_10 = 'transparent';
  PEDESTRIAN_11 = 'transparent';
  PEDESTRIAN_12 = 'transparent';

  LEFTTURN_1 = 'transparent';
  LEFTTURN_2 = 'transparent';
  LEFTTURN_3 = 'transparent';
  LEFTTURN_4 = 'transparent';
  LEFTTURN_5 = 'transparent';
  LEFTTURN_6 = 'transparent';
  LEFTTURN_7 = 'transparent';
  LEFTTURN_8 = 'transparent';

  arrRoadSignClicks: string[] = [];

  constructor(){
  }

  ngOnInit(): void {
    localStorage.setItem('arrRoadSignClicks', '');
  }

  ngOnDestroy(): void {
  }

  clickSign(sName: string): void {
    let bAdd = true;

    if (sName === 'FILL80_1' && this.FILL80_1 === 'transparent') { bAdd = false;
    } else if (sName === 'FILL80_2' && this.FILL80_2 === 'transparent') { bAdd = false;
    } else if (sName === 'FILL80_3' && this.FILL80_3 === 'transparent') { bAdd = false;
    } else if (sName === 'FILL80_4' && this.FILL80_4 === 'transparent') { bAdd = false;
    } else if (sName === 'FILL80_5' && this.FILL80_5 === 'transparent') { bAdd = false;
    } else if (sName === 'FILL80_6' && this.FILL80_6 === 'transparent') { bAdd = false;
    } else if (sName === 'FILL80_7' && this.FILL80_7 === 'transparent') { bAdd = false;
    } else if (sName === 'FILL80_8' && this.FILL80_8 === 'transparent') { bAdd = false;
    } else if (sName === 'FILL80_9' && this.FILL80_9 === 'transparent') { bAdd = false;
    } else if (sName === 'FILL80_10' && this.FILL80_10 === 'transparent') { bAdd = false;
    } else if (sName === 'FILL80_11' && this.FILL80_11 === 'transparent') { bAdd = false;
    } else if (sName === 'FILL80_12' && this.FILL80_12 === 'transparent') { bAdd = false;
    } else if (sName === 'NOUTURN_1' && this.NOUTURN_1 === 'transparent') { bAdd = false;
    } else if (sName === 'NOUTURN_2' && this.NOUTURN_2 === 'transparent') { bAdd = false;
    } else if (sName === 'NOUTURN_3' && this.NOUTURN_3 === 'transparent') { bAdd = false;
    } else if (sName === 'NOUTURN_4' && this.NOUTURN_4 === 'transparent') { bAdd = false;
    } else if (sName === 'NOUTURN_5' && this.NOUTURN_5 === 'transparent') { bAdd = false;
    } else if (sName === 'NOUTURN_6' && this.NOUTURN_6 === 'transparent') { bAdd = false;
    } else if (sName === 'NOUTURN_7' && this.NOUTURN_7 === 'transparent') { bAdd = false;
    } else if (sName === 'NOUTURN_8' && this.NOUTURN_8 === 'transparent') { bAdd = false;
    } else if (sName === 'NOUTURN_9' && this.NOUTURN_9 === 'transparent') { bAdd = false;
    } else if (sName === 'NOUTURN_10' && this.NOUTURN_10 === 'transparent') { bAdd = false;
    } else if (sName === 'NOUTURN_11' && this.NOUTURN_11 === 'transparent') { bAdd = false;
    } else if (sName === 'NOENTRY_1' && this.NOENTRY_1 === 'transparent') { bAdd = false;
    } else if (sName === 'NOENTRY_2' && this.NOENTRY_2 === 'transparent') { bAdd = false;
    } else if (sName === 'NOENTRY_3' && this.NOENTRY_3 === 'transparent') { bAdd = false;
    } else if (sName === 'NOENTRY_4' && this.NOENTRY_4 === 'transparent') { bAdd = false;
    } else if (sName === 'NOENTRY_5' && this.NOENTRY_5 === 'transparent') { bAdd = false;
    } else if (sName === 'NOENTRY_6' && this.NOENTRY_6 === 'transparent') { bAdd = false;
    } else if (sName === 'NOENTRY_7' && this.NOENTRY_7 === 'transparent') { bAdd = false;
    } else if (sName === 'NOENTRY_8' && this.NOENTRY_8 === 'transparent') { bAdd = false;
    } else if (sName === 'NOENTRY_9' && this.NOENTRY_9 === 'transparent') { bAdd = false;
    } else if (sName === 'DEADEND_1' && this.DEADEND_1 === 'transparent') { bAdd = false;
    } else if (sName === 'DEADEND_2' && this.DEADEND_2 === 'transparent') { bAdd = false;
    } else if (sName === 'DEADEND_3' && this.DEADEND_3 === 'transparent') { bAdd = false;
    } else if (sName === 'DEADEND_4' && this.DEADEND_4 === 'transparent') { bAdd = false;
    } else if (sName === 'DEADEND_5' && this.DEADEND_5 === 'transparent') { bAdd = false;
    } else if (sName === 'DEADEND_6' && this.DEADEND_6 === 'transparent') { bAdd = false;
    } else if (sName === 'DEADEND_7' && this.DEADEND_7 === 'transparent') { bAdd = false;
    } else if (sName === 'DEADEND_8' && this.DEADEND_8 === 'transparent') { bAdd = false;
    } else if (sName === 'DEADEND_9' && this.DEADEND_9 === 'transparent') { bAdd = false;
    } else if (sName === 'DEADEND_10' && this.DEADEND_10 === 'transparent') { bAdd = false;
    } else if (sName === 'DEADEND_11' && this.DEADEND_11 === 'transparent') { bAdd = false;
    } else if (sName === 'PEDESTRIAN_1' && this.PEDESTRIAN_1 === 'transparent') { bAdd = false;
    } else if (sName === 'PEDESTRIAN_2' && this.PEDESTRIAN_2 === 'transparent') { bAdd = false;
    } else if (sName === 'PEDESTRIAN_3' && this.PEDESTRIAN_3 === 'transparent') { bAdd = false;
    } else if (sName === 'PEDESTRIAN_4' && this.PEDESTRIAN_4 === 'transparent') { bAdd = false;
    } else if (sName === 'PEDESTRIAN_5' && this.PEDESTRIAN_5 === 'transparent') { bAdd = false;
    } else if (sName === 'PEDESTRIAN_6' && this.PEDESTRIAN_6 === 'transparent') { bAdd = false;
    } else if (sName === 'PEDESTRIAN_7' && this.PEDESTRIAN_7 === 'transparent') { bAdd = false;
    } else if (sName === 'PEDESTRIAN_8' && this.PEDESTRIAN_8 === 'transparent') { bAdd = false;
    } else if (sName === 'PEDESTRIAN_9' && this.PEDESTRIAN_9 === 'transparent') { bAdd = false;
    } else if (sName === 'PEDESTRIAN_10' && this.PEDESTRIAN_10 === 'transparent') { bAdd = false;
    } else if (sName === 'PEDESTRIAN_11' && this.PEDESTRIAN_11 === 'transparent') { bAdd = false;
    } else if (sName === 'PEDESTRIAN_12' && this.PEDESTRIAN_12 === 'transparent') { bAdd = false;
    } else if (sName === 'LEFTTURN_1' && this.LEFTTURN_1 === 'transparent') { bAdd = false;
    } else if (sName === 'LEFTTURN_2' && this.LEFTTURN_2 === 'transparent') { bAdd = false;
    } else if (sName === 'LEFTTURN_3' && this.LEFTTURN_3 === 'transparent') { bAdd = false;
    } else if (sName === 'LEFTTURN_4' && this.LEFTTURN_4 === 'transparent') { bAdd = false;
    } else if (sName === 'LEFTTURN_5' && this.LEFTTURN_5 === 'transparent') { bAdd = false;
    } else if (sName === 'LEFTTURN_6' && this.LEFTTURN_6 === 'transparent') { bAdd = false;
    } else if (sName === 'LEFTTURN_7' && this.LEFTTURN_7 === 'transparent') { bAdd = false;
    } else if (sName === 'LEFTTURN_8' && this.LEFTTURN_8 === 'transparent') { bAdd = false;
    }

    if (bAdd) {
      this.arrRoadSignClicks.indexOf(sName) === -1 ? this.arrRoadSignClicks.push(sName) : console.log('This item already exists');
    } else {
      const arrIndex = this.arrRoadSignClicks.indexOf(sName);
      if (arrIndex > -1) {
        this.arrRoadSignClicks.splice(arrIndex, 1);
      }
    }

    this.arrRoadSignClicks.filter((item, index) => this.arrRoadSignClicks.indexOf(item) === index);
    localStorage.setItem('arrRoadSignClicks', JSON.stringify(this.arrRoadSignClicks));

    // var clicks: any = localStorage.getItem('arrRoadSignClicks');
    // this.findRoadTrafficSignHandler(JSON.parse(clicks));
  }


//   findRoadTrafficSignHandler(arrRoadSignClicks: string[]): number {
//     let totalFindRoadTrafficSign  = 0;
//     let nineSigns = 9;

//     let totalNoEntries: number = arrRoadSignClicks.filter(x => x.indexOf('NOENTRY') > -1).length;
//     let totalOther: number = arrRoadSignClicks.filter(x => x.indexOf('NOENTRY') === -1).length;

//     if (totalOther === 0 && totalNoEntries === 9) {
//         totalFindRoadTrafficSign = 3;
//     } else {

//       var myNewScoreNoEntries = ((nineSigns - totalNoEntries) + totalOther) * 0.5;
//       totalFindRoadTrafficSign = 3 - myNewScoreNoEntries;
//     }
//     return totalFindRoadTrafficSign < 1 ? 0 : totalFindRoadTrafficSign;
// }


  changeColor(sName: string): void {

    const imageColor = '#1ab394';
    // const imageColor = 'yellow';

    if (sName === 'FILL80_1') {         this.FILL80_1 = (this.FILL80_1 === 'transparent' ? imageColor : 'transparent');
    } else if (sName === 'FILL80_2') {  this.FILL80_2 = (this.FILL80_2 === 'transparent' ? imageColor : 'transparent');
    } else if (sName === 'FILL80_3') {  this.FILL80_3 = (this.FILL80_3 === 'transparent' ? imageColor : 'transparent');
    } else if (sName === 'FILL80_4') {  this.FILL80_4 = (this.FILL80_4 === 'transparent' ? imageColor : 'transparent');
    } else if (sName === 'FILL80_5') {  this.FILL80_5 = (this.FILL80_5 === 'transparent' ? imageColor : 'transparent');
    } else if (sName === 'FILL80_6') {  this.FILL80_6 = (this.FILL80_6 === 'transparent' ? imageColor : 'transparent');
    } else if (sName === 'FILL80_7') {  this.FILL80_7 = (this.FILL80_7 === 'transparent' ? imageColor : 'transparent');
    } else if (sName === 'FILL80_8') {  this.FILL80_8 = (this.FILL80_8 === 'transparent' ? imageColor : 'transparent');
    } else if (sName === 'FILL80_9') {  this.FILL80_9 = (this.FILL80_9 === 'transparent' ? imageColor : 'transparent');
    } else if (sName === 'FILL80_10') { this.FILL80_10 = (this.FILL80_10 === 'transparent' ? imageColor : 'transparent');
    } else if (sName === 'FILL80_11') { this.FILL80_11 = (this.FILL80_11 === 'transparent' ? imageColor : 'transparent');
    } else if (sName === 'FILL80_12') { this.FILL80_12 = (this.FILL80_12 === 'transparent' ? imageColor : 'transparent');
    } else if (sName === 'NOUTURN_1') { this.NOUTURN_1 = (this.NOUTURN_1 === 'transparent' ? imageColor : 'transparent');
    } else if (sName === 'NOUTURN_2') { this.NOUTURN_2 = (this.NOUTURN_2 === 'transparent' ? imageColor : 'transparent');
    } else if (sName === 'NOUTURN_3') { this.NOUTURN_3 = (this.NOUTURN_3 === 'transparent' ? imageColor : 'transparent');
    } else if (sName === 'NOUTURN_4') { this.NOUTURN_4 = (this.NOUTURN_4 === 'transparent' ? imageColor : 'transparent');
    } else if (sName === 'NOUTURN_5') { this.NOUTURN_5 = (this.NOUTURN_5 === 'transparent' ? imageColor : 'transparent');
    } else if (sName === 'NOUTURN_6') { this.NOUTURN_6 = (this.NOUTURN_6 === 'transparent' ? imageColor : 'transparent');
    } else if (sName === 'NOUTURN_7') { this.NOUTURN_7 = (this.NOUTURN_7 === 'transparent' ? imageColor : 'transparent');
    } else if (sName === 'NOUTURN_8') { this.NOUTURN_8 = (this.NOUTURN_8 === 'transparent' ? imageColor : 'transparent');
    } else if (sName === 'NOUTURN_9') { this.NOUTURN_9 = (this.NOUTURN_9 === 'transparent' ? imageColor : 'transparent');
    } else if (sName === 'NOUTURN_10') { this.NOUTURN_10 = (this.NOUTURN_10 === 'transparent' ? imageColor : 'transparent');
    } else if (sName === 'NOUTURN_11') { this.NOUTURN_11 = (this.NOUTURN_11 === 'transparent' ? imageColor : 'transparent');
    } else if (sName === 'NOENTRY_1') { this.NOENTRY_1 = (this.NOENTRY_1 === 'transparent' ? imageColor : 'transparent');
    } else if (sName === 'NOENTRY_2') { this.NOENTRY_2 = (this.NOENTRY_2 === 'transparent' ? imageColor : 'transparent');
    } else if (sName === 'NOENTRY_3') { this.NOENTRY_3 = (this.NOENTRY_3 === 'transparent' ? imageColor : 'transparent');
    } else if (sName === 'NOENTRY_4') { this.NOENTRY_4 = (this.NOENTRY_4 === 'transparent' ? imageColor : 'transparent');
    } else if (sName === 'NOENTRY_5') { this.NOENTRY_5 = (this.NOENTRY_5 === 'transparent' ? imageColor : 'transparent');
    } else if (sName === 'NOENTRY_6') { this.NOENTRY_6 = (this.NOENTRY_6 === 'transparent' ? imageColor : 'transparent');
    } else if (sName === 'NOENTRY_7') { this.NOENTRY_7 = (this.NOENTRY_7 === 'transparent' ? imageColor : 'transparent');
    } else if (sName === 'NOENTRY_8') { this.NOENTRY_8 = (this.NOENTRY_8 === 'transparent' ? imageColor : 'transparent');
    } else if (sName === 'NOENTRY_9') { this.NOENTRY_9 = (this.NOENTRY_9 === 'transparent' ? imageColor : 'transparent');
    } else if (sName === 'DEADEND_1') { this.DEADEND_1 = (this.DEADEND_1 === 'transparent' ? imageColor : 'transparent');
    } else if (sName === 'DEADEND_2') { this.DEADEND_2 = (this.DEADEND_2 === 'transparent' ? imageColor : 'transparent');
    } else if (sName === 'DEADEND_3') { this.DEADEND_3 = (this.DEADEND_3 === 'transparent' ? imageColor : 'transparent');
    } else if (sName === 'DEADEND_4') { this.DEADEND_4 = (this.DEADEND_4 === 'transparent' ? imageColor : 'transparent');
    } else if (sName === 'DEADEND_5') { this.DEADEND_5 = (this.DEADEND_5 === 'transparent' ? imageColor : 'transparent');
    } else if (sName === 'DEADEND_6') { this.DEADEND_6 = (this.DEADEND_6 === 'transparent' ? imageColor : 'transparent');
    } else if (sName === 'DEADEND_7') { this.DEADEND_7 = (this.DEADEND_7 === 'transparent' ? imageColor : 'transparent');
    } else if (sName === 'DEADEND_8') { this.DEADEND_8 = (this.DEADEND_8 === 'transparent' ? imageColor : 'transparent');
    } else if (sName === 'DEADEND_9') { this.DEADEND_9 = (this.DEADEND_9 === 'transparent' ? imageColor : 'transparent');
    } else if (sName === 'DEADEND_10') { this.DEADEND_10 = (this.DEADEND_10 === 'transparent' ? imageColor : 'transparent');
    } else if (sName === 'DEADEND_11') { this.DEADEND_11 = (this.DEADEND_11 === 'transparent' ? imageColor : 'transparent');
    } else if (sName === 'PEDESTRIAN_1') {  this.PEDESTRIAN_1 = (this.PEDESTRIAN_1 === 'transparent' ? imageColor : 'transparent');
    } else if (sName === 'PEDESTRIAN_2') {  this.PEDESTRIAN_2 = (this.PEDESTRIAN_2 === 'transparent' ? imageColor : 'transparent');
    } else if (sName === 'PEDESTRIAN_3') {  this.PEDESTRIAN_3 = (this.PEDESTRIAN_3 === 'transparent' ? imageColor : 'transparent');
    } else if (sName === 'PEDESTRIAN_4') {  this.PEDESTRIAN_4 = (this.PEDESTRIAN_4 === 'transparent' ? imageColor : 'transparent');
    } else if (sName === 'PEDESTRIAN_5') {  this.PEDESTRIAN_5 = (this.PEDESTRIAN_5 === 'transparent' ? imageColor : 'transparent');
    } else if (sName === 'PEDESTRIAN_6') {  this.PEDESTRIAN_6 = (this.PEDESTRIAN_6 === 'transparent' ? imageColor : 'transparent');
    } else if (sName === 'PEDESTRIAN_7') {  this.PEDESTRIAN_7 = (this.PEDESTRIAN_7 === 'transparent' ? imageColor : 'transparent');
    } else if (sName === 'PEDESTRIAN_8') {  this.PEDESTRIAN_8 = (this.PEDESTRIAN_8 === 'transparent' ? imageColor : 'transparent');
    } else if (sName === 'PEDESTRIAN_9') {  this.PEDESTRIAN_9 = (this.PEDESTRIAN_9 === 'transparent' ? imageColor : 'transparent');
    } else if (sName === 'PEDESTRIAN_10') { this.PEDESTRIAN_10 = (this.PEDESTRIAN_10 === 'transparent' ? imageColor : 'transparent');
    } else if (sName === 'PEDESTRIAN_11') { this.PEDESTRIAN_11 = (this.PEDESTRIAN_11 === 'transparent' ? imageColor : 'transparent');
    } else if (sName === 'PEDESTRIAN_12') { this.PEDESTRIAN_12 = (this.PEDESTRIAN_12 === 'transparent' ? imageColor : 'transparent');
    } else if (sName === 'LEFTTURN_1') { this.LEFTTURN_1 = (this.LEFTTURN_1 === 'transparent' ? imageColor : 'transparent');
    } else if (sName === 'LEFTTURN_2') { this.LEFTTURN_2 = (this.LEFTTURN_2 === 'transparent' ? imageColor : 'transparent');
    } else if (sName === 'LEFTTURN_3') { this.LEFTTURN_3 = (this.LEFTTURN_3 === 'transparent' ? imageColor : 'transparent');
    } else if (sName === 'LEFTTURN_4') { this.LEFTTURN_4 = (this.LEFTTURN_4 === 'transparent' ? imageColor : 'transparent');
    } else if (sName === 'LEFTTURN_5') { this.LEFTTURN_5 = (this.LEFTTURN_5 === 'transparent' ? imageColor : 'transparent');
    } else if (sName === 'LEFTTURN_6') { this.LEFTTURN_6 = (this.LEFTTURN_6 === 'transparent' ? imageColor : 'transparent');
    } else if (sName === 'LEFTTURN_7') { this.LEFTTURN_7 = (this.LEFTTURN_7 === 'transparent' ? imageColor : 'transparent');
    } else if (sName === 'LEFTTURN_8') { this.LEFTTURN_8 = (this.LEFTTURN_8 === 'transparent' ? imageColor : 'transparent');
    }

  }

}
