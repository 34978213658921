
<app-header [showLogout]="false" [showTitle]="true"></app-header>
<div class="col-md-12" style="margin-bottom: 200px;">
  <div class="card card-container">
    <div class="form-group" *ngIf="!showForm">
      <h3 class="text-center">Invalid token for password reset</h3>
      <div class="form-group text-center">
        <button class="btn btn-link" (click)="goToLogin()">Login</button>
      </div>
    </div>

    <div *ngIf="showForm">
      <form [formGroup]="frmSignup" (submit)="onSubmit()" #f="ngForm">
      
      <div class="form-group" *ngIf="showFields">
        <label for="password" [ngClass]="frmSignup.controls['password'].invalid ? 'text-normal' : ''">New password:</label>
        <input id="password" formControlName="password" type="password" class="form-control" [ngClass]="frmSignup.controls['password'].invalid ? 'is-invalid' : ''">

        <label class="col" [ngClass]="frmSignup.controls['password'].hasError('required') || frmSignup.controls['password'].hasError('minlength')  ? 'text-normal' : 'text-success'">
          <i class="material-icons">{{ frmSignup.controls['password'].hasError('required') ||
            frmSignup.controls['password'].hasError('minlength') ? 'cancel' :
            'check_circle' }}</i>
          Must be at least 8 characters.
        </label>
        <label class="col" [ngClass]="frmSignup.controls['password'].hasError('required') || frmSignup.controls['password'].hasError('hasNumber')  ? 'text-normal' : 'text-success'">
          <i class="material-icons">{{ frmSignup.controls['password'].hasError('required') ||
            frmSignup.controls['password'].hasError('hasNumber') ? 'cancel' :
            'check_circle' }}</i>
          Must contain at least 1 number.
        </label>
        <label class="col" [ngClass]="frmSignup.controls['password'].hasError('required') || frmSignup.controls['password'].hasError('hasCapitalCase')  ? 'text-normal' : 'text-success'">
          <i class="material-icons">{{ frmSignup.controls['password'].hasError('required') ||
            frmSignup.controls['password'].hasError('hasCapitalCase') ? 'cancel' :
            'check_circle' }}</i>
          Must contain at least 1 letter in capital case.
        </label>
        <label class="col" [ngClass]="frmSignup.controls['password'].hasError('required') || frmSignup.controls['password'].hasError('hasSmallCase')  ? 'text-normal' : 'text-success'">
          <i class="material-icons">{{ frmSignup.controls['password'].hasError('required') ||
            frmSignup.controls['password'].hasError('hasSmallCase') ? 'cancel' :
            'check_circle' }}</i>
          Must contain at least 1 letter in small case.
        </label>
        <label class="col" [ngClass]="frmSignup.controls['password'].hasError('required') || frmSignup.controls['password'].hasError('hasSpecialCharacters') ? 'text-normal' : 'text-success'">
          <i class="material-icons">{{ frmSignup.controls['password'].hasError('required') ||
            frmSignup.controls['password'].hasError('hasSpecialCharacters') ? 'cancel' :
            'check_circle' }}</i>
          Must contain at least 1 special character.
        </label>
      </div>
      <div class="form-group" *ngIf="showFields">
        <label for="confirmPassword" [ngClass]="frmSignup.controls['confirmPassword'].invalid ? 'text-normal' : ''">Confirm password:</label>
        <input id="confirmPassword" formControlName="confirmPassword" type="password" class="form-control"
          [ngClass]="frmSignup.controls['confirmPassword'].invalid ? 'is-invalid' : ''">
        <label class="text-normal" *ngIf="frmSignup.controls['confirmPassword'].hasError('required')">
          Password is required.
        </label>
        <label class="text-normal" *ngIf="frmSignup.controls['confirmPassword'].hasError('NoPassswordMatch')">
          Confirmed password does not match.
        </label>
      </div>
        <div class="form-group text-center" *ngIf="showFields">
          <button class="btn btn-primary btn-block">
            Change new password.
          </button>
        </div>
        <div class="form-group">
          <div
            class="alert alert-danger"
            role="alert"
            *ngIf="f.submitted && isResetPasswordFailed"
          >
            Reset of password failed: Should you have any queries please email info@going-places.co.za
          </div>
        </div>
        <div class="form-group">
          <div
            class="alert alert-success"
            role="alert"
            *ngIf="f.submitted && !isResetPasswordFailed"
          >
            Reset of password was successful: Should you have any queries please email info@going-places.co.za
          </div>
        </div>
        <div class="form-group text-center">
          <button class="btn btn-link" (click)="goToLogin()">Login</button>
        </div>
      </form>
    </div>
  </div>
</div>

<app-footer></app-footer>

