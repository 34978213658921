<div class="container">
    <div class="row">
        <div class="col-xs-10 col-sm-10">
            <br>
            <h2>Generate Tokens</h2>
        </div>
        <div class="col-xs-2 col-sm-2 float-right logo">
            <a routerLink="/home"><img src="./assets/images/logo/GOINGPLACES_RGB no tag.jpg" height="80px" /></a>
        </div>
    </div>

    <div class="row">
        <div class="col-xs-12 col-sm-12"><hr></div>
    </div>

    <div class="row">
        <div class="col-xs-12 col-sm-12">
            <button class="btn btn-primary main" (click)="gemerateToken()">Generate Token</button>
        </div>
    </div>

    <div class="row" *ngIf="sToken !== ''">
        <div class="col-xs-12 col-sm-12">
            <h4>Client  <button class="btn btn-outline-primary btn-sm" (click)="copyClientUrl()">Copy to clipboard</button></h4>
            <a #clientToken href="{{clientUrl}}?token=c-{{sToken}}" target="_blank">{{clientUrl}}?token=c-{{sToken}}</a>
        </div>
    </div>
    <!-- <div class="row" *ngIf="sToken !== ''">
        <div class="col-xs-12 col-sm-12">
            <h4>Therapist <button class="btn btn-secondary btn-sm" (click)="copyTherapistUrl()">Copy to clipboard</button></h4>
            <a href="{{therapistUrl}}?token=t-{{sToken}}" target="_blank">{{therapistUrl}}?token=t-{{sToken}}</a>
        </div>
    </div> -->

</div>
