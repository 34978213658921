import { ApiTherapistService } from './../../api-therapist.service';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-therapist-report',
  templateUrl: './therapist-report.component.html',
  styleUrls: ['./therapist-report.component.css']
})
export class TherapistReportComponent implements OnInit {

  surveyResults: any = {};
  surveyPages = [];
  surveyTime = 0;
  token = '';
  today = new Date().toISOString().slice(0, 10);
  countryName = '';
  regionName = '';

  constructor(private apiService: ApiTherapistService) { }

  ngOnInit(): void {

    this.surveyResults = this.apiService.surveyResults;
    this.surveyPages = this.apiService.surveyPages;
    this.surveyTime = this.apiService.surveyTime;
    this.countryName = this.apiService.countryName;
    this.regionName = this.apiService.regionName;
    this.token = JSON.stringify(localStorage.getItem('token'));
    this.token = this.token.replace('"', '');
    this.token = this.token.replace('"', '');

    // console.log('surveyResults', this.surveyResults);
    // console.log('surveyPages', this.surveyPages);
  }

  print(): void {
    window.print();
  }

}
