import { environment } from './../../../environments/environment';
import { Component, OnInit } from '@angular/core';
import { ClipboardService } from 'ngx-clipboard';

@Component({
  selector: 'app-tokens',
  templateUrl: './tokens.component.html',
  styleUrls: ['./tokens.component.css']
})
export class TokensComponent implements OnInit {

  sToken = '';
  clientUrl = '';
  therapistUrl = '';

  constructor(private clipboardApi: ClipboardService) { }

  ngOnInit(): void {
    this.clientUrl = environment.clientUrl;
    this.therapistUrl = environment.therapistUrl;
  }

  gemerateToken(): void {
    this.sToken = this.NEW_GUID;

    // console.log('sToken', this.sToken);
  }

  copyClientUrl(): void {
    this.clipboardApi.copyFromContent(this.clientUrl + '?token=c-' + this.sToken);
  }

  copyTherapistUrl(): void {
    this.clipboardApi.copyFromContent(this.therapistUrl + '?token=t-' + this.sToken);
  }

  public get NEW_GUID(): string {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
        // tslint:disable-next-line:one-variable-per-declaration
        const r = Math.random() * 16 | 0, v = c === 'x' ? r : (r & 0x3 | 0x8);
        return v.toString(16);
    });
  }

}
