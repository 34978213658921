import { Component, Input } from '@angular/core';
import { ITimes } from '../results.component';

@Component({
  selector: 'app-times',
  styles: [
    `
    .pane{
      padding: 1em;
    }
  `
  ],
  template: `
    <table class="table table-bordered" style="visibility: visible;">
        <thead class="thead-dark">
        <tr>
            <th>Date</th>
            <th>ClientRefNo</th>
            <th>OTT</th>
            <th>TC</th>
            <th>ITS</th>
            <th>D</th>
            <th>MM</th>
            <th>FC</th>
            <th>M</th>
            <th>FTS</th>
            <th>I</th>
            <th>LN</th>
            <th>RR</th>
            <th>RF</th>
            <th>RTT</th>
            <th>DM</th>
            <th *ngIf="reportType === 'CoDriS'">OAT</th>
            <th *ngIf="reportType !== 'CoDriS'">TI</th>
            <th>SA</th>
            <th>TotalTestItem</th>
            <th>TotalTest</th>
        </tr>
        </thead>
        <tbody>
            <tr *ngFor="let item of timeItems; let i = index;">
            <td>{{item.dDateCreated}}</td>
            <td>{{item.sToken}}</td>
            <td>{{item.ott}}</td>
            <td>{{item.tc}}</td>
            <td>{{item.irts}}</td>
            <td>{{item.directions}}</td>
            <td>{{item.mm}}</td>
            <td>{{item.fc}}</td>
            <td>{{item.memory}}</td>
            <td>{{item.frts}}</td>
            <td>{{item.intersections}}</td>
            <td>{{item.ln}}</td>
            <td>{{item.rr}}</td>
            <td>{{item.rf}}</td>
            <td>{{item.rtt}}</td>
            <td>{{item.dm}}</td>
            <td>{{item.oat}}</td>
            <td>{{item.sa}}</td>
            <td>{{item.ti}}</td>
            <td>{{item.totalti}}</td>
            </tr>
        </tbody>
    </table>
  `
})
export class TimeItemComponent {
  @Input() timeItems: ITimes[] = [];
  @Input() reportType: string = '';
}
