import { CalculationService } from './../../calculation.service';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import * as xlsx from 'xlsx';
import { AssessmentService } from '../_services/assessment.service';

export interface IreportItem {
  id: number;
  sToken: string;
  assessmentData: string;
  totalTimeSpent: number;
  pagesTime: string;
  dDateCreated: string;
}

export interface IDemographics {
  id: number;
  dDateCreated: string;
  sToken: string;
  consent: string;
  gender: string;
  age: string;
  english: string;
  scooling: string;
  education: string;
}

export interface IRawData {
  dDateCreated: string;
  sToken: string;
  q1: string;
  q2: string;
  q3: string;
  q4: string;
  q5: string;
  q6: string;
  q7: string;
  q8: string;
  q9: string;
  q10: string;
  q11: string;
  q12: string;
  q13: string;
  q14: string;
  q15: string;
  q16: string;
  q17: string;
  q18: string;
  q19: string;
  q20: string;
  q21: string;
  q22: string;
  q23: string;
  q24: string;
  q25: string;
  q26: string;
  q27: string;
  q28: string;
  q29: string;
  q30: string;
  q31: string;
  q32: string;
  q33: string;
  q34: string;
  q35: string;
  q36: string;
  q37: string;
  q38: string;
  q39: string;
  q40: string;
  q41: string;
  q42: string;
  q43: string;
  q44: string;
  q45: string;
  q46: string;
  q47: string;
  q48: string;
  q49: string;
  q50: string;
  q51: string;
}

export interface IDrivingFitness {
  dDateCreated: string;
  sToken: string;
  tto: number;
  tc: number;
  mm: number;
  fc: number;
  direction: number;
  oat: number;
  irts: number;
  vmemory: number;
  amemory: number;
  frts: number;
  intersection: number;
  landmark: number;
  rr: number;
  rf: number;
  left: number;
  right: number;
  dm: number;
  sa: number;
  tp: number;
  tpPerc: string;
  da: number;
  daPerc: string;
  df: number;
  dfPerc: string;
  insight: string;
}

export interface IComunnityMobility {
  dDateCreated: string;
  sToken: string;
  tto: number;
  tc: number;
  direction: number;
  oat: number;
  mm: number;
  fc: number;
  irts: number;
  memory: number;
  frts: number;
  landmark: number;
  rf: number;
  cm: number;
  cmPerc: string;
  tns: number;
  tnsPerc: string;
}
export interface ITestScores {
  dDateCreated: string;
  sToken: string;
  ott_q1: number;
  ott_q2: number;
  ott_q3: number;
  ott_q4: number;
  ott_q5: number;
  ott_q6: number;
  ott: number;
  tc: number;
  irts_q1: number;
  irts_q2: number;
  irts_q3: number;
  irts_q4: number;
  irts: number;
  direction_1: number;
  direction_2: number;
  direction_3: number;
  direction_4: number;
  direction: number;
  mm_q1: number;
  mm_q2: number;
  mm: number;
  fc: number;
  memory_1: number;
  memory_2: number;
  memory: number;
  frts: number;
  intersection_q1: number;
  intersection_q2: number;
  intersection_q3: number;
  intersection_q4: number;
  intersection: number;
  lm_q1: number;
  lm_q2: number;
  lm_q3: number;
  lm: number;
  rr_q1: number;
  rr_q2: number;
  rr_q3: number;
  rr_q4: number;
  rr: number;
  rf_q1: number;
  rf_q2: number;
  rf: number;
  left_q1: number;
  left_q2: number;
  left: number;
  right_q1: number;
  right_q2: number;
  right: number;
  dm_q1: number;
  dm_q2: number;
  dm: number;
  oat_q1: number;
  oat_q2: number;
  oat_q3: number;
  oat_q4: number;
  oat: number;
  sa_q1: number;
  sa_q2: number;
  sa_q3: number;
  sa_q4: number;
  sa_q5: number;
  sa_q6: number;
  sa_q7: number;
  sa_q8: number;
  sa: number;
}
export interface ITimes {
  dDateCreated: string;
  sToken: string;
  ott: number;
  tc: number;
  irts: number;
  directions: number;
  mm: number;
  fc: number;
  memory: number;
  frts: number;
  intersections: number;
  ln: number;
  rr: number;
  rf: number;
  rtt: number;
  dm: number;
  oat: number;
  sa: number;
  ti: number;
  totalti: number;
}
export interface IClientFeedback {
  dDateCreated: string;
  sToken: string;
  sToken2: string;
  pseudonym: string;
  diagnosis: string;
  orientation: string;
  approach: string;
  mood: string;
  concentration: string;
  processingspeed: string;
  insight: string;
  mocascore: string;
  MocaVisospatial: string;
  MocaNaming: string;
  MocaDigit: string;
  MocaTapping: string;
  MocaSerial7: string;
  MocaSentence: string;
  MocaFluency: string;
  MocaAbstract: string;
  MocaMemory: string;
  MocaOTP: string;
  mazetimeinseconds: string;
  mazenumberofmistakes: string;
  bellstimeinseconds: string;
  bellsnumberofmistakes: string;
  trailsbtimeinseconds: string;
  trailsnumberofmistakes: string;
  fitness2: string;
  clinicalobservation: string;
}

export interface ITherapistFeedback {
  dDateCreated: string;
  sToken: string;
  sToken2: string;
  readinstructions1: number;
  readinstructions2: number;
  operatedevice: number;
  struggle2: string;
  otherfeedbackcomment: string;
}

@Component({
  selector: 'app-results',
  templateUrl: './results.component.html',
  styleUrls: ['./results.component.css']
})
export class ResultsComponent implements OnInit {

  @ViewChild('tab1', { static: false })
  tab1!: ElementRef;
  @ViewChild('tab2', { static: false })
  tab2!: ElementRef;
  @ViewChild('tab3', { static: false })
  tab3!: ElementRef;
  @ViewChild('tab4', { static: false })
  tab4!: ElementRef;
  @ViewChild('tab5', { static: false })
  tab5!: ElementRef;
  @ViewChild('tab6', { static: false })
  tab6!: ElementRef;
  @ViewChild('tab7', { static: false })
  tab7!: ElementRef;
  @ViewChild('tab8', { static: false })
  tab8!: ElementRef;

  results: IreportItem[] = [];
  demographics: IDemographics[] = [];
  rawData: IRawData[] = [];
  drivingFitness: IDrivingFitness[] = [];
  communityMobilities: IComunnityMobility[] = [];
  testScores: ITestScores[] = [];
  timeItems: ITimes[] = [];
  clientFeedback: IClientFeedback[] = [];
  theraphistFeedback: ITherapistFeedback[] = [];

  showDemographics = false;
  showDriving = false;
  showCommunity = false;
  showTest = false;
  showRaw = false;
  showTimes = false;
  showTClient = false;
  showTTest = false;

  totalClientRecords = 0;
  totalTherapistRecords = 0;
  reportType = 'CoDriS';

  constructor(private calcService: CalculationService, private assessmentService: AssessmentService) { }

  ngOnInit(): void {

    this.changeTab(this.reportType)
  }

  changeTab(title: any): void {

    if (title == 'CoDriS Employment') {
      title = 'CoDriSEmployment';
    }
    this.reportType = title;

    this.results = [];
    this.demographics = [];
    this.rawData = [];
    this.drivingFitness = [];
    this.communityMobilities = [];
    this.testScores = [];
    this.timeItems = [];
    this.clientFeedback = [];
    this.theraphistFeedback = [];

    this.assessmentService.getReportData(title).subscribe(
      data => {
        this.results = data;
        if (data) {
          this.formatData();
        }
      },
      err => {
       console.log('error fetching report data', err);
      }
    );
  }

  formatData(): void {
    //console.log('results', this.results);

    // tslint:disable-next-line:prefer-for-of
    for (let i = 0; i < this.results.length; i++) {
      const replacedData = this.results[i].assessmentData.replace(/\r?\n|\r/g, '');
      const assessmentData = JSON.parse(replacedData);
      const dDate = this.results[i].dDateCreated.split(' ')[0];
      const token = this.results[i].sToken.substring(0, 10);

      //console.log('assessmentData', assessmentData);
      //console.log(i+1, token);

      if (this.results[i].sToken[0] === 'c')  {
          this.totalClientRecords++;
          const pageTimes = JSON.parse(this.results[i].pagesTime);
          let consentAnswer = '-';
          if (assessmentData.CONSENT_data === 'Yes') {
            consentAnswer = '1';
          } else {
            consentAnswer = '0';
          }

          let englishAnswer = '-';
          if (assessmentData.DEMOGRAPHICS_language === 'Yes') {
            englishAnswer = '1';
          } else {
            englishAnswer = '0';
          }

          let genderAnswer = '-';
          if (assessmentData.DEMOGRAPHICS_gender === 'Male') {
            genderAnswer = '2';
          } else if (assessmentData.DEMOGRAPHICS_gender === 'Female') {
            genderAnswer = '1';
          } else if (assessmentData.DEMOGRAPHICS_gender === 'Neutral') {
            genderAnswer = '0';
          }

          let schoolingAnswer = '-';
          if (assessmentData.DEMOGRAPHICS_school === 'None') {
            schoolingAnswer = '0';
          } else if (assessmentData.DEMOGRAPHICS_school === 'Grade 1-7') {
            schoolingAnswer = '1';
          } else if (assessmentData.DEMOGRAPHICS_school === 'Grade 8-10') {
            schoolingAnswer = '2';
          } else if (assessmentData.DEMOGRAPHICS_school === 'Grade 11-12') {
            schoolingAnswer = '3';
          } else if (assessmentData.DEMOGRAPHICS_school === 'Grade 13') {
            schoolingAnswer = '4';
          } else if (assessmentData.DEMOGRAPHICS_school === 'Grade 11-13') {
            schoolingAnswer = '3';
          }

          let educationAnswer = '-';
          if (assessmentData.DEMOGRAPHICS_school === 'None') {
            educationAnswer = '0';
          } else if (assessmentData.DEMOGRAPHICS_education === 'Certificate') {
            educationAnswer = '1';
          } else if (assessmentData.DEMOGRAPHICS_education === 'Diploma') {
            educationAnswer = '2';
          } else if (assessmentData.DEMOGRAPHICS_education === 'Degree') {
            educationAnswer = '3';
          }

          this.demographics.push({
            id: this.results[i].id,
            dDateCreated: dDate,
            sToken: token,
            consent: consentAnswer,
            gender: genderAnswer,
            age: assessmentData.DEMOGRAPHICS_age,
            english: englishAnswer,
            scooling: schoolingAnswer,
            education: educationAnswer
          });

          this.formatRawData(token, assessmentData, dDate);
          this.formatDrivingFitness(token, assessmentData, dDate);
          this.formatMobility(token, assessmentData, dDate);
          this.formatItemScores(token, assessmentData, dDate);
          this.formatTimeItems(token, pageTimes, dDate);
        } else {
          this.totalTherapistRecords++;
          this.formatClientFeedback(token, assessmentData, dDate);
          this.formatTherapistFeedback(token, assessmentData, dDate);
        }
    }
  }

  formatRawData(token: string, assessmentData: any, dateCreated: any): void {

    let q2Answer = '';
    if (assessmentData.ORIENTATION_TIME_year !== undefined ||
      assessmentData.ORIENTATION_TIME_month !== undefined ||
      assessmentData.ORIENTATION_TIME_day !== undefined) {
      // tslint:disable-next-line:max-line-length
      q2Answer = assessmentData.ORIENTATION_TIME_year + '-' + assessmentData.ORIENTATION_TIME_month + '-' + assessmentData.ORIENTATION_TIME_day;
    }
    let q3Answer = '';
    if (assessmentData.ORIENTATION_TIME_week_day === 1) {
      q3Answer = 'Monday';
    } else if (assessmentData.ORIENTATION_TIME_week_day === 2) {
      q3Answer = 'Tuesday';
    } else if (assessmentData.ORIENTATION_TIME_week_day === 3) {
      q3Answer = 'Wednesday';
    } else if (assessmentData.ORIENTATION_TIME_week_day === 4) {
      q3Answer = 'Thursday';
    } else if (assessmentData.ORIENTATION_TIME_week_day === 5) {
      q3Answer = 'Friday';
    } else if (assessmentData.ORIENTATION_TIME_week_day === 6) {
      q3Answer = 'Saturday';
    } else if (assessmentData.ORIENTATION_TIME_week_day === 7) {
      q3Answer = 'Sunday';
    }

    let q4Answer = '';
    if (assessmentData.ORIENTATION_TIME_no_look_now_hour !== undefined ||
      assessmentData.ORIENTATION_TIME_no_look_now_minute !== undefined) {
        q4Answer = assessmentData.ORIENTATION_TIME_no_look_now_hour + ':' + assessmentData.ORIENTATION_TIME_no_look_now_minute;
    }

    let q5Answer = '';
    if (assessmentData.ORIENTATION_TIME_look_now_hour !== undefined ||
      assessmentData.ORIENTATION_TIME_look_now_minute !== undefined) {
        q5Answer = assessmentData.ORIENTATION_TIME_look_now_hour + ':' + assessmentData.ORIENTATION_TIME_look_now_minute;
    }

    let q19Answer = '';
    if (assessmentData.MEMORY2_DIRECTION1 !== undefined) {
      q19Answer = q19Answer + assessmentData.MEMORY2_DIRECTION1 + ',';
    }
    if (assessmentData.MEMORY2_DIRECTION2 !== undefined) {
      q19Answer = q19Answer + assessmentData.MEMORY2_DIRECTION2 + ',';
    }
    if (assessmentData.MEMORY2_DIRECTION3 !== undefined) {
      q19Answer = q19Answer + assessmentData.MEMORY2_DIRECTION3 + ',';
    }
    if (assessmentData.MEMORY2_DIRECTION4 !== undefined) {
      q19Answer = q19Answer + assessmentData.MEMORY2_DIRECTION4;
    }

    let q21Answer = '';
    if (assessmentData.INTERSECTIONS1A !== undefined) {
      q21Answer = q21Answer + assessmentData.INTERSECTIONS1A;
    }
    if (assessmentData.INTERSECTIONS1B !== undefined) {
      q21Answer = q21Answer + assessmentData.INTERSECTIONS1B;
    }
    let q22Answer = '';
    if (assessmentData.INTERSECTIONS2A !== undefined) {
      q22Answer = q22Answer + assessmentData.INTERSECTIONS2A;
    }
    if (assessmentData.INTERSECTIONS2B !== undefined) {
      q22Answer = q22Answer + assessmentData.INTERSECTIONS2B;
    }
    if (assessmentData.INTERSECTIONS2C !== undefined) {
      q22Answer = q22Answer + assessmentData.INTERSECTIONS2C;
    }
    let q23Answer = '';
    if (assessmentData.INTERSECTIONS3A !== undefined) {
      q23Answer = q23Answer + assessmentData.INTERSECTIONS3A;
    }
    if (assessmentData.INTERSECTIONS3B !== undefined) {
      q23Answer = q23Answer + assessmentData.INTERSECTIONS3B;
    }
    if (assessmentData.INTERSECTIONS3C !== undefined) {
      q23Answer = q23Answer + assessmentData.INTERSECTIONS3C;
    }
    let q24Answer = '';
    if (assessmentData.INTERSECTIONS4A !== undefined) {
      q24Answer = q24Answer + assessmentData.INTERSECTIONS4A;
    }
    if (assessmentData.INTERSECTIONS4B !== undefined) {
      q24Answer = q24Answer + assessmentData.INTERSECTIONS4B;
    }
    let q28Answer = '';
    let q29Answer = '';
    let q30Answer = '';
    let q31Answer = '';
    if (assessmentData.ROADRULES) {
      q28Answer = assessmentData.ROADRULES['Keep right, pass left.'];
      q29Answer = assessmentData.ROADRULES['When I overtake a car, I don’t have to use my indicator.'];
      q30Answer = assessmentData.ROADRULES['When I change lanes, I must check my rear and side view mirrors.'];
      q31Answer = assessmentData.ROADRULES['When I arrive first at a 4 way stop, I have right of way.'];
    }
    let q34Answer = '';
    let q35Answer = '';
    let q36Answer = '';
    let q37Answer = '';
    if (assessmentData.video2) {
      q34Answer = assessmentData.video2.leftClicks;
      q36Answer = assessmentData.video2.rightClicks;
    }
    if (assessmentData.video3) {
      q35Answer = assessmentData.video3.leftClicks;
      q37Answer = assessmentData.video3.rightClicks;
    }
    let q44Answer = '';
    let q45Answer = '';
    let q46Answer = '';
    let q47Answer = '';
    let q48Answer = '';
    let q49Answer = '';
    let q50Answer = '';
    if (assessmentData.SELF_ASSESSMENT_active_driver) {
      if (assessmentData.SELF_ASSESSMENT_active_driver['I get lost while driving in a relatively familiar environment.'] === 0) {
        q44Answer = 'Never';
      } else if (assessmentData.SELF_ASSESSMENT_active_driver['I get lost while driving in a relatively familiar environment.'] === 1) {
        q44Answer = 'Sometimes';
      } else if (assessmentData.SELF_ASSESSMENT_active_driver['I get lost while driving in a relatively familiar environment.'] === 2) {
        q44Answer = 'Always';
      }

      if (assessmentData.SELF_ASSESSMENT_active_driver['I forget where I was planning to drive to.'] === 0) {
        q45Answer = 'Never';
      } else if (assessmentData.SELF_ASSESSMENT_active_driver['I forget where I was planning to drive to.'] === 1) {
        q45Answer = 'Sometimes';
      } else if (assessmentData.SELF_ASSESSMENT_active_driver['I forget where I was planning to drive to.'] === 2) {
        q45Answer = 'Always';
      }

      if (assessmentData.SELF_ASSESSMENT_active_driver['I am often late for appointments.'] === 0) {
        q46Answer = 'Never';
      } else if (assessmentData.SELF_ASSESSMENT_active_driver['I am often late for appointments.'] === 1) {
        q46Answer = 'Sometimes';
      } else if (assessmentData.SELF_ASSESSMENT_active_driver['I am often late for appointments.'] === 2) {
        q46Answer = 'Always';
      }

      if (assessmentData.SELF_ASSESSMENT_active_driver['I have difficulty deciding who has right of way at an intersection.'] === 0) {
        q47Answer = 'Never';
      // tslint:disable-next-line:max-line-length
      } else if (assessmentData.SELF_ASSESSMENT_active_driver['I have difficulty deciding who has right of way at an intersection.'] === 1) {
        q47Answer = 'Sometimes';
      // tslint:disable-next-line:max-line-length
      } else if (assessmentData.SELF_ASSESSMENT_active_driver['I have difficulty deciding who has right of way at an intersection.'] === 2) {
        q47Answer = 'Always';
      }

      // tslint:disable-next-line:max-line-length
      if (assessmentData.SELF_ASSESSMENT_active_driver['I get nervous at an intersection because there is so much to watch out for.'] === 0) {
        q48Answer = 'Never';
      // tslint:disable-next-line:max-line-length
      } else if (assessmentData.SELF_ASSESSMENT_active_driver['I get nervous at an intersection because there is so much to watch out for.'] === 1) {
        q48Answer = 'Sometimes';
      // tslint:disable-next-line:max-line-length
      } else if (assessmentData.SELF_ASSESSMENT_active_driver['I get nervous at an intersection because there is so much to watch out for.'] === 2) {
        q48Answer = 'Always';
      }

      if (assessmentData.SELF_ASSESSMENT_active_driver['I find it difficult to merge with traffic on a busy road.'] === 0) {
        q49Answer = 'Never';
      } else if (assessmentData.SELF_ASSESSMENT_active_driver['I find it difficult to merge with traffic on a busy road.'] === 1) {
        q49Answer = 'Sometimes';
      } else if (assessmentData.SELF_ASSESSMENT_active_driver['I find it difficult to merge with traffic on a busy road.'] === 2) {
        q49Answer = 'Always';
      }

      if (assessmentData.SELF_ASSESSMENT_active_driver['I get surprised by vehicles or pedestrians who appear out of nowhere.'] === 0) {
        q50Answer = 'Never';
      // tslint:disable-next-line:max-line-length
      } else if (assessmentData.SELF_ASSESSMENT_active_driver['I get surprised by vehicles or pedestrians who appear out of nowhere.'] === 1) {
        q50Answer = 'Sometimes';
      // tslint:disable-next-line:max-line-length
      } else if (assessmentData.SELF_ASSESSMENT_active_driver['I get surprised by vehicles or pedestrians who appear out of nowhere.'] === 2) {
        q50Answer = 'Always';
      }
    }
    if (assessmentData.SELF_ASSESSMENT_non_active_driver) {
      if (assessmentData.SELF_ASSESSMENT_non_active_driver['I may get lost while driving in a relatively familiar environment.'] === 0) {
        q44Answer = 'No';
      // tslint:disable-next-line:max-line-length
      } else if (assessmentData.SELF_ASSESSMENT_non_active_driver['I may get lost while driving in a relatively familiar environment.'] === 1) {
        q44Answer = 'Maybe';
      // tslint:disable-next-line:max-line-length
      } else if (assessmentData.SELF_ASSESSMENT_non_active_driver['I may get lost while driving in a relatively familiar environment.'] === 2) {
        q44Answer = 'Yes';
      }

      if (assessmentData.SELF_ASSESSMENT_non_active_driver['I may forget where I am planning to drive to.'] === 0) {
        q45Answer = 'No';
      // tslint:disable-next-line:max-line-length
      } else if (assessmentData.SELF_ASSESSMENT_non_active_driver['I may forget where I am planning to drive to.'] === 1) {
        q45Answer = 'Maybe';
      // tslint:disable-next-line:max-line-length
      } else if (assessmentData.SELF_ASSESSMENT_non_active_driver['I may forget where I am planning to drive to.'] === 2) {
        q45Answer = 'Yes';
      }

      if (assessmentData.SELF_ASSESSMENT_non_active_driver['I may struggle to be on time for appointments.'] === 0) {
        q46Answer = 'No';
      // tslint:disable-next-line:max-line-length
      } else if (assessmentData.SELF_ASSESSMENT_non_active_driver['I may struggle to be on time for appointments.'] === 1) {
        q46Answer = 'Maybe';
      // tslint:disable-next-line:max-line-length
      } else if (assessmentData.SELF_ASSESSMENT_non_active_driver['I may struggle to be on time for appointments.'] === 2) {
        q46Answer = 'Yes';
      }

      if (assessmentData.SELF_ASSESSMENT_non_active_driver['I may struggle to decide who has right of way at an intersection.'] === 0) {
        q47Answer = 'No';
      // tslint:disable-next-line:max-line-length
      } else if (assessmentData.SELF_ASSESSMENT_non_active_driver['I may struggle to decide who has right of way at an intersection.'] === 1) {
        q47Answer = 'Maybe';
      // tslint:disable-next-line:max-line-length
      } else if (assessmentData.SELF_ASSESSMENT_non_active_driver['I may struggle to decide who has right of way at an intersection.'] === 2) {
        q47Answer = 'Yes';
      }

      // tslint:disable-next-line:max-line-length
      if (assessmentData.SELF_ASSESSMENT_non_active_driver['I may get nervous at an intersection because there is so much to watch out for.'] === 0) {
        q48Answer = 'No';
      // tslint:disable-next-line:max-line-length
      } else if (assessmentData.SELF_ASSESSMENT_non_active_driver['I may get nervous at an intersection because there is so much to watch out for.'] === 1) {
        q48Answer = 'Maybe';
      // tslint:disable-next-line:max-line-length
      } else if (assessmentData.SELF_ASSESSMENT_non_active_driver['I may get nervous at an intersection because there is so much to watch out for.'] === 2) {
        q48Answer = 'Yes';
      }

      // tslint:disable-next-line:max-line-length
      if (assessmentData.SELF_ASSESSMENT_non_active_driver['I may find it difficult to merge with traffic on a busy road.'] === 0) {
        q49Answer = 'No';
      // tslint:disable-next-line:max-line-length
      } else if (assessmentData.SELF_ASSESSMENT_non_active_driver['I may find it difficult to merge with traffic on a busy road.'] === 1) {
        q49Answer = 'Maybe';
      // tslint:disable-next-line:max-line-length
      } else if (assessmentData.SELF_ASSESSMENT_non_active_driver['I may find it difficult to merge with traffic on a busy road.'] === 2) {
        q49Answer = 'Yes';
      }

      // tslint:disable-next-line:max-line-length
      if (assessmentData.SELF_ASSESSMENT_non_active_driver['I may get surprised by vehicles or pedestrians who appear out of nowhere.'] === 0) {
        q50Answer = 'No';
      // tslint:disable-next-line:max-line-length
      } else if (assessmentData.SELF_ASSESSMENT_non_active_driver['I may get surprised by vehicles or pedestrians who appear out of nowhere.'] === 1) {
        q50Answer = 'Maybe';
      // tslint:disable-next-line:max-line-length
      } else if (assessmentData.SELF_ASSESSMENT_non_active_driver['I may get surprised by vehicles or pedestrians who appear out of nowhere.'] === 2) {
        q50Answer = 'Yes';
      }

    }
    let q51Answer = '';
    if (assessmentData.SELF_ASSESSMENT_performance) {
      if (assessmentData.SELF_ASSESSMENT_performance['How do you think you performed in this assessment?'] === '0') {
        q51Answer = 'Poor';
      }
      if (assessmentData.SELF_ASSESSMENT_performance['How do you think you performed in this assessment?'] === '1') {
        q51Answer = 'Average';
      }
      if (assessmentData.SELF_ASSESSMENT_performance['How do you think you performed in this assessment?'] === '2') {
        q51Answer = 'Great';
      }
    }

    // trip instructions
    let q40 = '';
    let q41 = '';
    let q42 = '';
    let q43 = '';
    if (this.reportType === 'CoDriS') {
      q40 = assessmentData.ORDERTAXI1;
      q41 = assessmentData.ORDERTAXI2;
      q42 = assessmentData.ORDERTAXI3;
      q43 = assessmentData.ORDERTAXI4;
    } else {
      q40 = assessmentData.p18q6;
      q41 = assessmentData.p18q7;
      q42 = assessmentData.p18q8;
      q43 = assessmentData.p18q9;
    }
    

    this.rawData.push({
      dDateCreated: dateCreated,
      sToken: token,
      q1: assessmentData.ORIENTATION_TIME_hiddenDateTime,
      q2: q2Answer,
      q3: q3Answer,
      q4: q4Answer,
      q5: q5Answer,
      q6: assessmentData.TIME_calculation,
      q7: assessmentData.IDENTIFY_PEDESTRIAN,
      q8: assessmentData.IDENTIFY_STOP,
      q9: assessmentData.IDENTIFY_CIRCLE,
      q10: assessmentData.IDENTIFY_RIGHT_TURN,
      q11: assessmentData.DIRECTION1,
      q12: assessmentData.DIRECTION2,
      q13: assessmentData.DIRECTION3,
      q14: assessmentData.DIRECTION4,
      q15: assessmentData.MONEY_MANAGEMENT_parking,
      q16: assessmentData.MONEY_MANAGEMENT_change,
      q17: assessmentData.FUEL_CALCULATION,
      q18: assessmentData.MEMORY1,
      q19: q19Answer,
      q20: assessmentData.RoadSignClicks,
      q21: q21Answer,
      q22: q22Answer,
      q23: q23Answer,
      q24: q24Answer,
      q25: assessmentData.LANDMARK_q1,
      q26: assessmentData.LANDMARK_q2,
      q27: assessmentData.LANDMARK_q3,
      q28: q28Answer,
      q29: q29Answer,
      q30: q30Answer,
      q31: q31Answer,
      q32: assessmentData.ROADSIDEFACTORS_q1,
      q33: assessmentData.RoadSideClicks,
      q34: q34Answer,
      q35: q35Answer,
      q36: q36Answer,
      q37: q37Answer,
      q38: assessmentData.DRIVING_MANOEUVRE_triangular,
      q39: assessmentData.CarInteriorClicks,
      q40: q40,
      q41: q41,
      q42: q42,
      q43: q43,
      q44: q44Answer,
      q45: q45Answer,
      q46: q46Answer,
      q47: q47Answer,
      q48: q48Answer,
      q49: q49Answer,
      q50: q50Answer,
      q51: q51Answer
    });
  }

  formatDrivingFitness(token: string, assessmentData: any, dateCreated: any): void {
    const totalTimeOrientation = this.getOrientationTime(assessmentData);
    const timeCalculation = this.getTimeCalculation(assessmentData);
    const totalDirection = this.getDirection(assessmentData);
    const mm3 = this.getMoneyManagement(assessmentData);
    const fuel = this.calcService.fuelCalculationHandler(assessmentData.FUEL_CALCULATION);
    const totalirts = this.getIRTS(assessmentData);
    const totalMemory = this.getMemory(assessmentData);
    const totalLM = this.getLandMark(assessmentData);
    const totalIntersections = this.getIntersections(assessmentData);

    let totalFRTS = 0;
    if (assessmentData.RoadSignClicks) {
      totalFRTS = this.calcService.findRoadTrafficSignHandler(assessmentData.RoadSignClicks);
    }

    let totalRR = 0;
    if (assessmentData.ROADRULES !== undefined) {
      totalRR =  this.calcService.roadRulesHandler(Object.entries(assessmentData.ROADRULES));
    }

    let totalRoadSideFactors = 0;
    if (assessmentData.ROADSIDEFACTORS_q1 !== undefined && assessmentData.ROADSIDEFACTORS_q1 === 'One way') {
      totalRoadSideFactors = totalRoadSideFactors + 1;
    }
    if (assessmentData.RoadSideClicks !== undefined) {
      totalRoadSideFactors = totalRoadSideFactors + this.calcService.roadSideFactorsHandler(assessmentData.RoadSideClicks);
    }

    let totalLeft = 0;
    if (assessmentData.video2 !== undefined || assessmentData.video3 !== undefined) {
      totalLeft = this.calcService.dividedAttentionHandler(assessmentData.video2, assessmentData.video3, 'left');
    }
    let totalRight = 0;
    if (assessmentData.video2 !== undefined || assessmentData.video3 !== undefined) {
      totalRight = this.calcService.dividedAttentionHandler(assessmentData.video2, assessmentData.video3, 'right');
    }

    // total driving manouevre
    let totalDM = 0;
    if (assessmentData.DRIVING_MANOEUVRE_triangular !== undefined &&
        assessmentData.DRIVING_MANOEUVRE_triangular === 'One road lane is merging into another.') {
        totalDM = 1;
    }
    if (assessmentData.CarInteriorClicks !== undefined) {
      totalDM = totalDM + this.calcService.drivingManoeuvreHandler(assessmentData.CarInteriorClicks);
    }

    // self assessment/self awareness
    let totalSA = 0;
    let saPercentage = '';
    if (assessmentData.SELF_ASSESSMENT_active_driver !== undefined) {
      totalSA = this.calcService.selfAssessmentHandler(Object.entries(assessmentData.SELF_ASSESSMENT_active_driver), 'SELF_ASSESSMENT_active_driver');
    }
    if (assessmentData.SELF_ASSESSMENT_non_active_driver !== undefined) {
      totalSA = this.calcService.selfAssessmentHandler(Object.entries(assessmentData.SELF_ASSESSMENT_non_active_driver), 'SELF_ASSESSMENT_non_active_driver');
    }
    if (assessmentData.SELF_ASSESSMENT_performance !== undefined) {
      totalSA = totalSA + this.calcService.selfAssessmentHandler(Object.entries(assessmentData.SELF_ASSESSMENT_performance), 'SELF_ASSESSMENT_performance');
    }
    saPercentage = (totalSA / 16 * 100).toFixed(0);

    const vmemory = assessmentData.MEMORY1 ? this.calcService.getVisualMemory(assessmentData.MEMORY1) : 0;
    const amemory = this.getMemory(assessmentData, 'auditory');
    const oat = this.getOAT(assessmentData);
    const tripInstruction = this.getTripInstruction(assessmentData);
    
    // total trip planning
    const totalTP = totalTimeOrientation + timeCalculation + totalDirection + mm3 + fuel + oat + tripInstruction ;
    const tpPercentage = (totalTP / 15 * 100).toFixed(0);


    // tslint:disable-next-line:max-line-length
    // TOTAL DRIVING ABILITY
    const totalDa = totalirts + totalMemory + totalFRTS + totalIntersections + totalLM + totalRR + totalRoadSideFactors +
                    totalLeft + totalRight + totalDM;
    const daPercentage = (totalDa / 30 * 100).toFixed(0);

    const totalDf = totalTP + totalDa;
    const dfPercentage = (totalDf / 45 * 100).toFixed(0);

    const insightPerc = (+saPercentage - +dfPercentage).toFixed(0);

    this.drivingFitness.push({
      dDateCreated: dateCreated,
      sToken: token,
      tto: totalTimeOrientation,
      tc: timeCalculation,
      mm: mm3,
      fc: fuel,
      direction: totalDirection,
      oat: oat+tripInstruction,
      irts: totalirts,
      vmemory: vmemory,
      amemory: amemory,
      frts: totalFRTS,
      intersection: totalIntersections,
      landmark: totalLM,
      rr: totalRR,
      rf: totalRoadSideFactors,
      left: totalLeft,
      right: totalRight,
      dm: totalDM,
      sa: totalSA,
      tp: totalTP,
      tpPerc: tpPercentage,
      da: totalDa,
      daPerc: daPercentage,
      df: totalDf,
      dfPerc: dfPercentage,
      insight: insightPerc
    });
  }

  formatMobility(token: string, assessmentData: any, dateCreated: any): void {
    const totalTimeOrientation = this.getOrientationTime(assessmentData);
    const timeCalculation = this.getTimeCalculation(assessmentData);
    const totalDirection = this.getDirection(assessmentData);
    const mm3 = this.getMoneyManagement(assessmentData);
    const fuel = this.calcService.fuelCalculationHandler(assessmentData.FUEL_CALCULATION);
    const totalirts = this.getIRTS(assessmentData);
    const totalMemory = this.getMemory(assessmentData);
    const totalLM = this.getLandMark(assessmentData);
    const oatTotal = this.getOAT(assessmentData);

    let totalFRTS = 0;
    if (assessmentData.RoadSignClicks) {
      totalFRTS = this.calcService.findRoadTrafficSignHandler(assessmentData.RoadSignClicks);
    }

    let totalRoadSideFactors = 0;
    if (assessmentData.ROADSIDEFACTORS_q1 !== undefined && assessmentData.ROADSIDEFACTORS_q1 === 'One way') {
      totalRoadSideFactors = totalRoadSideFactors + 1;
    }
    if (assessmentData.RoadSideClicks !== undefined) {
      totalRoadSideFactors = totalRoadSideFactors + this.calcService.roadSideFactorsHandler(assessmentData.RoadSideClicks);
    }

    const cmTotal = totalTimeOrientation + timeCalculation + totalDirection +  mm3 + fuel +
                    totalirts + totalMemory + totalFRTS + totalLM + totalRoadSideFactors;
    const cmTotalPerc = (cmTotal / 33 * 100).toFixed(0);

    const tnsTotal = totalTimeOrientation + timeCalculation + totalDirection ;
    const tnsTotalPerc = (tnsTotal / 12 * 100).toFixed(0);

    this.communityMobilities.push({
      dDateCreated: dateCreated,
      sToken: token,
      tto: totalTimeOrientation,
      tc: timeCalculation,
      direction: totalDirection,
      oat: oatTotal,
      mm: mm3,
      fc: fuel,
      irts: totalirts,
      memory: totalMemory,
      frts: totalFRTS,
      landmark: totalLM,
      rf: totalRoadSideFactors,
      cm: cmTotal,
      cmPerc: cmTotalPerc,
      tns: tnsTotal,
      tnsPerc: tnsTotalPerc
    });
  }

  formatItemScores(token: string, assessmentData: any, dateCreated: any): void {
    const ottq1 = this.getOrientationTime(assessmentData, 'q1');
    const ottq2 = this.getOrientationTime(assessmentData, 'q2');
    const ottq3 = this.getOrientationTime(assessmentData, 'q3');
    const ottq4 = this.getOrientationTime(assessmentData, 'q4');
    const ottq5 = this.getOrientationTime(assessmentData, 'q5');
    const ottq6 = this.getOrientationTime(assessmentData, 'q6');
    const ottTotal = this.getOrientationTime(assessmentData, '');

    const timeCalculation = this.getTimeCalculation(assessmentData);

    const irtsq1 = this.getIRTS(assessmentData, 'q1');
    const irtsq2 = this.getIRTS(assessmentData, 'q2');
    const irtsq3 = this.getIRTS(assessmentData, 'q3');
    const irtsq4 = this.getIRTS(assessmentData, 'q4');
    const totalirts = this.getIRTS(assessmentData, '');

    const direction1 = this.getDirection(assessmentData, 'q1');
    const direction2 = this.getDirection(assessmentData, 'q2');
    const direction3 = this.getDirection(assessmentData, 'q3');
    const direction4 = this.getDirection(assessmentData, 'q4');
    const totalDirection = this.getDirection(assessmentData, '');

    const mmq1 = this.getMoneyManagement(assessmentData, 'q1');
    const mmq2 = this.getMoneyManagement(assessmentData, 'q2');
    const mm3 = this.getMoneyManagement(assessmentData, '');

    const fuel = this.calcService.fuelCalculationHandler(assessmentData.FUEL_CALCULATION);

    const memory1 = this.getMemory(assessmentData, 'q1');
    const memory2 = this.getMemory(assessmentData, 'q2');
    const totalMemory = this.getMemory(assessmentData, '');

    let totalFRTS = 0;
    if (assessmentData.RoadSignClicks) {
      totalFRTS = this.calcService.findRoadTrafficSignHandler(assessmentData.RoadSignClicks);
    }
    const intersectionq1 = this.getIntersections(assessmentData, 'q1');
    const intersectionq2 = this.getIntersections(assessmentData, 'q2');
    const intersectionq3 = this.getIntersections(assessmentData, 'q3');
    const intersectionq4 = this.getIntersections(assessmentData, 'q4');
    const totalIntersections = this.getIntersections(assessmentData, '');

    const lmq1 = this.getLandMark(assessmentData, 'q1');
    const lmq2 = this.getLandMark(assessmentData, 'q2');
    const lmq3 = this.getLandMark(assessmentData, 'q3');
    const totalLM = this.getLandMark(assessmentData, '');

    let totalRR = 0;
    let rrq1 = 0;
    let rrq2 = 0;
    let rrq3 = 0;
    let rrq4 = 0;
    if (assessmentData.ROADRULES !== undefined) {
      rrq1 =  this.calcService.roadRulesHandler(Object.entries(assessmentData.ROADRULES), 'q1');
      rrq2 =  this.calcService.roadRulesHandler(Object.entries(assessmentData.ROADRULES), 'q2');
      rrq3 =  this.calcService.roadRulesHandler(Object.entries(assessmentData.ROADRULES), 'q3');
      rrq4 =  this.calcService.roadRulesHandler(Object.entries(assessmentData.ROADRULES), 'q4');
      totalRR =  this.calcService.roadRulesHandler(Object.entries(assessmentData.ROADRULES), '');
    }

    let totalRoadSideFactors = 0;
    let rfq1 = 0;
    let rfq2 = 0;
    if (assessmentData.ROADSIDEFACTORS_q1 !== undefined && assessmentData.ROADSIDEFACTORS_q1 === 'One way') {
      totalRoadSideFactors = totalRoadSideFactors + 1;
      rfq1 = 1;
    }
    if (assessmentData.RoadSideClicks !== undefined) {
      rfq2 = this.calcService.roadSideFactorsHandler(assessmentData.RoadSideClicks);
      totalRoadSideFactors = totalRoadSideFactors + rfq2;
    }

    let totalLeft = 0;
    let leftq1 = 0;
    let leftq2 = 0;
    if (assessmentData.video2 !== undefined || assessmentData.video3 !== undefined) {
      totalLeft = this.calcService.dividedAttentionHandler(assessmentData.video2, assessmentData.video3, 'left');
      leftq1 = this.calcService.dividedAttentionHandler(assessmentData.video2, assessmentData.video3, 'left', 'q1');
      leftq2 = this.calcService.dividedAttentionHandler(assessmentData.video2, assessmentData.video3, 'left', 'q2');
    }
    let totalRight = 0;
    let rightq1 = 0;
    let rightq2 = 0;
    if (assessmentData.video2 !== undefined || assessmentData.video3 !== undefined) {
      totalRight = this.calcService.dividedAttentionHandler(assessmentData.video2, assessmentData.video3, 'right');
      rightq1 = this.calcService.dividedAttentionHandler(assessmentData.video2, assessmentData.video3, 'right', 'q1');
      rightq2 = this.calcService.dividedAttentionHandler(assessmentData.video2, assessmentData.video3, 'right', 'q2');
    }

    let totalDM = 0;
    let dmq1 = 0;
    let dmq2 = 0;
    if (assessmentData.DRIVING_MANOEUVRE_triangular !== undefined &&
        assessmentData.DRIVING_MANOEUVRE_triangular === 'One road lane is merging into another.') {
        totalDM = 1;
        dmq1 = 1;
    }
    if (assessmentData.CarInteriorClicks !== undefined) {
      dmq2 = this.calcService.drivingManoeuvreHandler(assessmentData.CarInteriorClicks);
      totalDM = totalDM + dmq2;
    }

    const oatTotal = this.getOAT(assessmentData);
    const oatq1 = this.getOAT(assessmentData, 'q1');
    const oatq2 = this.getOAT(assessmentData, 'q2');
    const oatq3 = this.getOAT(assessmentData, 'q3');
    const oatq4 = this.getOAT(assessmentData, 'q4');

    const tripITotal = this.getTripInstruction(assessmentData);
    const tI1 = this.getTripInstruction(assessmentData, 'q1');
    const tI2 = this.getTripInstruction(assessmentData, 'q2');
    const tI3 = this.getTripInstruction(assessmentData, 'q3');
    const tI4 = this.getTripInstruction(assessmentData, 'q4');

    let totalSA = 0;
    let saq1 = 0;
    let saq2 = 0;
    let saq3 = 0;
    let saq4 = 0;
    let saq5 = 0;
    let saq6 = 0;
    let saq7 = 0;
    let saq8 = 0;
    if (assessmentData.SELF_ASSESSMENT_active_driver !== undefined) {
      totalSA = this.calcService.selfAssessmentHandler(Object.entries(assessmentData.SELF_ASSESSMENT_active_driver), 'SELF_ASSESSMENT_active_driver');
      saq1 = this.calcService.selfAssessmentHandler(Object.entries(assessmentData.SELF_ASSESSMENT_active_driver), 'SELF_ASSESSMENT_active_driver', 'q1');
      saq2 = this.calcService.selfAssessmentHandler(Object.entries(assessmentData.SELF_ASSESSMENT_active_driver), 'SELF_ASSESSMENT_active_driver', 'q2');
      saq3 = this.calcService.selfAssessmentHandler(Object.entries(assessmentData.SELF_ASSESSMENT_active_driver), 'SELF_ASSESSMENT_active_driver', 'q3');
      saq4 = this.calcService.selfAssessmentHandler(Object.entries(assessmentData.SELF_ASSESSMENT_active_driver), 'SELF_ASSESSMENT_active_driver', 'q4');
      saq5 = this.calcService.selfAssessmentHandler(Object.entries(assessmentData.SELF_ASSESSMENT_active_driver), 'SELF_ASSESSMENT_active_driver', 'q5');
      saq6 = this.calcService.selfAssessmentHandler(Object.entries(assessmentData.SELF_ASSESSMENT_active_driver), 'SELF_ASSESSMENT_active_driver', 'q6');
      saq7 = this.calcService.selfAssessmentHandler(Object.entries(assessmentData.SELF_ASSESSMENT_active_driver), 'SELF_ASSESSMENT_active_driver', 'q7');
    }
    if (assessmentData.SELF_ASSESSMENT_non_active_driver !== undefined) {
      totalSA = this.calcService.selfAssessmentHandler(Object.entries(assessmentData.SELF_ASSESSMENT_non_active_driver), 'SELF_ASSESSMENT_non_active_driver');
      saq1 = this.calcService.selfAssessmentHandler(Object.entries(assessmentData.SELF_ASSESSMENT_non_active_driver), 'SELF_ASSESSMENT_non_active_driver', 'q1');
      saq2 = this.calcService.selfAssessmentHandler(Object.entries(assessmentData.SELF_ASSESSMENT_non_active_driver), 'SELF_ASSESSMENT_non_active_driver', 'q2');
      saq3 = this.calcService.selfAssessmentHandler(Object.entries(assessmentData.SELF_ASSESSMENT_non_active_driver), 'SELF_ASSESSMENT_non_active_driver', 'q3');
      saq4 = this.calcService.selfAssessmentHandler(Object.entries(assessmentData.SELF_ASSESSMENT_non_active_driver), 'SELF_ASSESSMENT_non_active_driver', 'q4');
      saq5 = this.calcService.selfAssessmentHandler(Object.entries(assessmentData.SELF_ASSESSMENT_non_active_driver), 'SELF_ASSESSMENT_non_active_driver', 'q5');
      saq6 = this.calcService.selfAssessmentHandler(Object.entries(assessmentData.SELF_ASSESSMENT_non_active_driver), 'SELF_ASSESSMENT_non_active_driver', 'q6');
      saq7 = this.calcService.selfAssessmentHandler(Object.entries(assessmentData.SELF_ASSESSMENT_non_active_driver), 'SELF_ASSESSMENT_non_active_driver', 'q7');
    }
    if (assessmentData.SELF_ASSESSMENT_performance !== undefined) {
      saq8 = this.calcService.selfAssessmentHandler(Object.entries(assessmentData.SELF_ASSESSMENT_performance), 'SELF_ASSESSMENT_performance');
      totalSA = totalSA + saq8;
    }

    this.testScores.push({
      dDateCreated: dateCreated,
      sToken: token,
      ott_q1: ottq1,
      ott_q2: ottq2,
      ott_q3: ottq3,
      ott_q4: ottq4,
      ott_q5: ottq5,
      ott_q6: ottq6,
      ott: ottTotal,
      tc: timeCalculation,
      irts_q1: irtsq1,
      irts_q2: irtsq2,
      irts_q3: irtsq3,
      irts_q4: irtsq4,
      irts: totalirts,
      direction_1: direction1,
      direction_2: direction2,
      direction_3: direction3,
      direction_4: direction4,
      direction: totalDirection,
      mm_q1: mmq1,
      mm_q2: mmq2,
      mm: mm3,
      fc: fuel,
      memory_1: memory1,
      memory_2: memory2,
      memory: totalMemory,
      frts: totalFRTS,
      intersection_q1: intersectionq1,
      intersection_q2: intersectionq2,
      intersection_q3: intersectionq3,
      intersection_q4: intersectionq4,
      intersection: totalIntersections,
      lm_q1: lmq1,
      lm_q2: lmq2,
      lm_q3: lmq3,
      lm: totalLM,
      rr_q1: rrq1,
      rr_q2: rrq2,
      rr_q3: rrq3,
      rr_q4: rrq4,
      rr: totalRR,
      rf_q1: rfq1,
      rf_q2: rfq2,
      rf: totalRoadSideFactors,
      left_q1: leftq1,
      left_q2: leftq2,
      left: totalLeft,
      right_q1: rightq1,
      right_q2: rightq2,
      right: totalRight,
      dm_q1: dmq1,
      dm_q2: dmq2,
      dm: totalDM,
      oat_q1: oatq1+tI1,
      oat_q2: oatq2+tI2,
      oat_q3: oatq3+tI3,
      oat_q4: oatq4+tI4,
      oat: oatTotal+tripITotal,
      sa_q1: saq1,
      sa_q2: saq2,
      sa_q3: saq3,
      sa_q4: saq4,
      sa_q5: saq5,
      sa_q6: saq6,
      sa_q7: saq7,
      sa_q8: saq8,
      sa: totalSA
    });
  }

  formatTimeItems(token: string, pagesTime: any, dateCreated: any): void {
    let saTotal = this.getTimesForKey('SELF ASSESSMENT', pagesTime);
    saTotal = saTotal + this.getTimesForKey('SELF ASSESSMENT Active & Non-Active driver', pagesTime);

    const tott = this.getTimesForKey('ORIENTATION TO TIME', pagesTime);
    const ttc = this.getTimesForKey('TIME CALCULATION', pagesTime);
    const tirts = this.getTimesForKey('IDENTIFYING ROAD TRAFFIC SIGNS', pagesTime);
    const tdirections = this.getTimesForKey('DIRECTIONS', pagesTime);
    const tmm = this.getTimesForKey('MONEY MANAGEMENT', pagesTime);
    const tfc = this.getTimesForKey('FUEL CALCULATION', pagesTime);
    const tmemory = this.getTimesForKey('MEMORY', pagesTime);
    const tfrts = this.getTimesForKey('FIND THE ROAD TRAFFIC SIGN', pagesTime);
    const tintersections = this.getTimesForKey('INTERSECTIONS', pagesTime);
    const tln = this.getTimesForKey('LANDMARK NAVIGATION', pagesTime);
    const trr = this.getTimesForKey('ROAD RULES', pagesTime);
    const trf = this.getTimesForKey('ROADSIDE FACTORS', pagesTime);
    const trtt = this.getTimesForKey('RESPONDING TO TRAFFIC', pagesTime);
    const tdm = this.getTimesForKey('DRIVING MANOEUVRE', pagesTime);
    const toat = this.getTimesForKey('ORDER A TAXI', pagesTime);
    const tripI = this.getTimesForKey('TRIP INSTRUCTIONS', pagesTime);

    const totalItems = tott + ttc + tirts + tdirections + tmm + tfc + tmemory + tfrts + tintersections + tln + trr +
    trf + trtt + tdm + toat + tripI + saTotal;

    let allTotal = 0;
    // tslint:disable-next-line:prefer-for-of
    for (let i = 0; i < pagesTime.length; i++) {
      allTotal = allTotal + pagesTime[i]['time'];
    }

    this.timeItems.push(
      {
        dDateCreated: dateCreated,
        sToken: token,
        ott: tott,
        tc: ttc,
        irts: tirts,
        directions: tdirections,
        mm: tmm,
        fc: tfc,
        memory: tmemory,
        frts: tfrts,
        intersections: tintersections,
        ln: tln,
        rr: trr,
        rf: trf,
        rtt: trtt,
        dm: tdm,
        oat: toat+tripI,
        sa: saTotal,
        ti: totalItems,
        totalti: allTotal
      }
    );
  }

  formatClientFeedback(token: string, assessmentData: any, dateCreated: any): void {

    let orientationAnswer = '-';
    if (assessmentData.q_orientation === 'Fair') {
      orientationAnswer = '1';
    } else if (assessmentData.q_orientation === 'Good') {
      orientationAnswer = '2';
    } else if (assessmentData.q_orientation === 'Poor') {
      orientationAnswer = '0';
    }

    let approachAnswer = '-';
    if (assessmentData.q_approach === 'Fluctuating') {
      approachAnswer = '1';
    } else if (assessmentData.q_approach === 'Structured') {
      approachAnswer = '2';
    } else if (assessmentData.q_approach === 'Unstructured') {
      approachAnswer = '0';
    }

    let moodAnswer = '-';
    if (assessmentData.q_mood === 'Fluctuating') {
      moodAnswer = '1';
    } else if (assessmentData.q_mood === 'Calm') {
      moodAnswer = '2';
    } else if (assessmentData.q_mood === 'Anxious') {
      moodAnswer = '0';
    }

    let concentrationAnswer = '-';
    if (assessmentData.q_concentration === 'Fluctuating') {
      concentrationAnswer = '1';
    } else if (assessmentData.q_concentration === 'Good') {
      concentrationAnswer = '2';
    } else if (assessmentData.q_concentration === 'Poor') {
      concentrationAnswer = '0';
    }

    let processingspeedAnswer = '-';
    if (assessmentData.q_processingspeed === 'Average') {
      processingspeedAnswer = '1';
    } else if (assessmentData.q_processingspeed === 'Fast') {
      processingspeedAnswer = '2';
    } else if (assessmentData.q_processingspeed === 'Poor') {
      orientationAnswer = '0';
    }

    let insightAnswer = '-';
    if (assessmentData.q_insight === 'Questionable') {
      insightAnswer = '1';
    } else if (assessmentData.q_insight === 'Good') {
      insightAnswer = '2';
    } else if (assessmentData.q_insight === 'Poor') {
      insightAnswer = '0';
    }

    let fitness2Answer = '-';
    if (assessmentData.q_fitness2 === 'Failed clinical assessment, however cognition was not a contributing factor. Not fit to drive.') {
      fitness2Answer = '1';
    } else if (assessmentData.q_fitness2 === 'Failed on-road assessment due to cognitive impairment. (This can include other impairments however cognition needs to be a contributor to the failure.) Not fit to drive.') {
      fitness2Answer = '2';
    // tslint:disable-next-line:max-line-length
    } else if (assessmentData.q_fitness2 === 'Failed on-road assessment, however cognition was not a contributing factor. Not fit to drive.') {
      fitness2Answer = '3';
    } else if (assessmentData.q_fitness2 === 'Passed on-road assessment. Fit to drive.') {
      fitness2Answer = '4';
    } else if (assessmentData.q_fitness2 === 'Failed clinical assessment due to cognitive impairment. (This can include other impairments however cognition needs to be a contributor to the failure.) Not fit to drive.') {
      fitness2Answer = '0';
    }

    
    this.clientFeedback.push({
      dDateCreated: dateCreated,
      sToken: token,
      sToken2: token.replace('t-', 'c-'),
      pseudonym: assessmentData.q_pseudonym,
      diagnosis: assessmentData.q_diagnosis,
      orientation: orientationAnswer,
      approach: approachAnswer,
      mood: moodAnswer,
      concentration: concentrationAnswer,
      processingspeed: processingspeedAnswer,
      insight: insightAnswer,
      mocascore: assessmentData.q_mocatotal,
      MocaVisospatial: assessmentData.q_moca_visuospatial,
      MocaNaming: assessmentData.q_moca_naming,
      MocaDigit: assessmentData.q_moca_attention_digits,
      MocaTapping: assessmentData.q_moca_attention_tapping,
      MocaSerial7: assessmentData.q_moca_attention_serial,
      MocaSentence: assessmentData.q_moca_language_sentences,
      MocaFluency: assessmentData.q_moca_language_fluency,
      MocaAbstract: assessmentData.q_moca_abstraction,
      MocaMemory: assessmentData.q_moca_delayed_recall,
      MocaOTP: assessmentData.q_moca_orientation,
      mazetimeinseconds: assessmentData.q_mazetimeinseconds,
      mazenumberofmistakes: assessmentData.q_mazenumberofmistakes,
      bellstimeinseconds: assessmentData.q_bellstimeinseconds,
      bellsnumberofmistakes: assessmentData.q_bellsnumberofmistakes,
      trailsbtimeinseconds: assessmentData.q_trailsbtimeinseconds,
      trailsnumberofmistakes: assessmentData.q_trailsnumberofmistakes,
      fitness2: fitness2Answer,
      clinicalobservation: assessmentData.q_clinicalobservation,
    });

  }

  formatTherapistFeedback(token: string, assessmentData: any, dateCreated: any): void {

    let readinstructions1Answer = 0;
    if (assessmentData.q_readinstructions1 === 'Yes') {
      readinstructions1Answer = 1;
    }

    let readinstructions2Answer = 0;
    if (assessmentData.q_readinstructions2 === 'Yes') {
      readinstructions2Answer = 1;
    }

    let operateDeviceAnswer = 0;
    if (assessmentData.q_operatedevice === 'Yes') {
      operateDeviceAnswer = 1;
    }

    this.theraphistFeedback.push(
      {
        dDateCreated: dateCreated,
        sToken: token,
        sToken2: token.replace('t-', 'c-'),
        readinstructions1: readinstructions1Answer,
        readinstructions2: readinstructions2Answer,
        operatedevice: operateDeviceAnswer,
        struggle2: assessmentData.q_struggle2,
        otherfeedbackcomment: assessmentData.q_otherfeedbackcomment,
      }
    );
  }

  //////////////////////////////////////////////////////////////////

  getTripInstruction(assessmentData: any, question: string = ''): number {
    let tripInstruction = 0;
    if (question === '') {
      if (assessmentData.p18q6) {
        if (assessmentData.p18q6 === '23') {
          tripInstruction++;
        }
      }
      if (assessmentData.p18q7) {
        if (assessmentData.p18q7 === '10 Boxes 4 Bags') {
          tripInstruction++;
        }
      }
      if (assessmentData.p18q8) {
        if (assessmentData.p18q8 === 'Alpha') {
          tripInstruction++;
        }
      }
      if (assessmentData.p18q9) {
        if (assessmentData.p18q9 === 'No') {
          tripInstruction++;
        }
      }
    } else if (question === 'q1') {
      if (assessmentData.p18q6) {
        if (assessmentData.p18q6 === '23') {
          tripInstruction++;
        }
      }
    } else if (question === 'q2') {
      if (assessmentData.p18q8) {
        if (assessmentData.p18q8 === 'Alpha') {
          tripInstruction++;
        }
      }
    } else if (question === 'q3') {
      if (assessmentData.p18q9) {
        if (assessmentData.p18q9 === 'No') {
          tripInstruction++;
        }
      }
    } else if (question === 'q4') {
      if (assessmentData.p18q9) {
        if (assessmentData.p18q9 === 'No') {
          tripInstruction++;
        }
      }
    }
    return tripInstruction;
  }

  getOrientationTime(assessmentData: any, question: string = ''): number {
    let timeyear = 0;
    if (assessmentData.ORIENTATION_TIME_year !== undefined) {
      timeyear = assessmentData.ORIENTATION_TIME_year;
    }
    let nolooknowhour = 0;
    if (assessmentData.ORIENTATION_TIME_no_look_now_hour !== undefined) {
      nolooknowhour = assessmentData.ORIENTATION_TIME_no_look_now_hour;
    }
    let nolooknowminute = 0;
    if (assessmentData.ORIENTATION_TIME_no_look_now_minute !== undefined) {
      nolooknowminute = assessmentData.ORIENTATION_TIME_no_look_now_minute;
    }
    let looknowhour = 0;
    if (assessmentData.ORIENTATION_TIME_look_now_hour !== undefined) {
      looknowhour = assessmentData.ORIENTATION_TIME_look_now_hour;
    }
    let looknowminute = 0;
    if (assessmentData.ORIENTATION_TIME_look_now_minute !== undefined) {
      looknowminute = assessmentData.ORIENTATION_TIME_look_now_minute;
    }
    let timemonth = 0;
    if (assessmentData.ORIENTATION_TIME_month !== undefined) {
      timemonth = assessmentData.ORIENTATION_TIME_month;
    }
    let timeweekday = 0;
    if (assessmentData.ORIENTATION_TIME_week_day !== undefined) {
      timeweekday = assessmentData.ORIENTATION_TIME_week_day;
    }
    let timeday = 0;
    if (assessmentData.ORIENTATION_TIME_day !== undefined) {
      timeday = assessmentData.ORIENTATION_TIME_day;
    }

    return this.calcService.timeOrientationHandler(timeyear,
                                                  new Date(assessmentData.ORIENTATION_TIME_hiddenDateTime),
                                                  nolooknowhour,
                                                  nolooknowminute,
                                                  looknowhour,
                                                  looknowminute,
                                                  timemonth,
                                                  timeweekday,
                                                  timeday,
                                                  question);
  }

  getTimeCalculation(assessmentData: any): number {
    let timeCalculation = 0;
    if (assessmentData.TIME_calculation === 0 || assessmentData.TIME_calculation === 1) {
      timeCalculation = assessmentData.TIME_calculation;
    } else {
      timeCalculation = this.calcService.timeCalculationHandler(assessmentData.TIME_calculation);
    }
    return timeCalculation;
  }

  getDirection(assessmentData: any, question: string = ''): number {
    let totalDirection = 0;
    if (question === '') {
      totalDirection = totalDirection + this.calcService.directionHandler('DIRECTION1', assessmentData.DIRECTION1);
      totalDirection = totalDirection + this.calcService.directionHandler('DIRECTION2', assessmentData.DIRECTION2);
      totalDirection = totalDirection + this.calcService.directionHandler('DIRECTION3', assessmentData.DIRECTION3);
      totalDirection = totalDirection + this.calcService.directionHandler('DIRECTION4', assessmentData.DIRECTION4);
    } else if (question === 'q1') {
      totalDirection = totalDirection + this.calcService.directionHandler('DIRECTION1', assessmentData.DIRECTION1);
    } else if (question === 'q2') {
      totalDirection = totalDirection + this.calcService.directionHandler('DIRECTION2', assessmentData.DIRECTION2);
    } else if (question === 'q3') {
      totalDirection = totalDirection + this.calcService.directionHandler('DIRECTION3', assessmentData.DIRECTION3);
    } else if (question === 'q4') {
      totalDirection = totalDirection + this.calcService.directionHandler('DIRECTION4', assessmentData.DIRECTION4);
    }
    return totalDirection;
  }

  getMoneyManagement(assessmentData: any, question: string = ''): number {
    let mm3 = 0;

    if (question === '') {
      const mm1 = this.calcService.moneyParkingHandler(assessmentData.MONEY_MANAGEMENT_parking);
      const mm2 = this.calcService.moneyChangeHandler(assessmentData.MONEY_MANAGEMENT_change);
      mm3 = mm1 + mm2;
    } else if (question === 'q1') {
      mm3  = this.calcService.moneyParkingHandler(assessmentData.MONEY_MANAGEMENT_parking);
    } else if (question === 'q2') {
      mm3  = this.calcService.moneyChangeHandler(assessmentData.MONEY_MANAGEMENT_change);
    }
    return mm3;
  }

  getIRTS(assessmentData: any, question: string = ''): number {
    let totalirts = 0;
    if (question ===  '') {
      totalirts = totalirts + this.calcService.identifySignHandler('IDENTIFY_PEDESTRIAN', assessmentData.IDENTIFY_PEDESTRIAN);
      totalirts = totalirts + this.calcService.identifySignHandler('IDENTIFY_STOP', assessmentData.IDENTIFY_STOP);
      totalirts = totalirts + this.calcService.identifySignHandler('IDENTIFY_CIRCLE', assessmentData.IDENTIFY_CIRCLE);
      totalirts = totalirts + this.calcService.identifySignHandler('IDENTIFY_RIGHT_TURN', assessmentData.IDENTIFY_RIGHT_TURN);
    } else if (question ===  'q1') {
      totalirts = totalirts + this.calcService.identifySignHandler('IDENTIFY_PEDESTRIAN', assessmentData.IDENTIFY_PEDESTRIAN);
    } else if (question ===  'q2') {
      totalirts = totalirts + this.calcService.identifySignHandler('IDENTIFY_STOP', assessmentData.IDENTIFY_STOP);
    } else if (question ===  'q3') {
      totalirts = totalirts + this.calcService.identifySignHandler('IDENTIFY_CIRCLE', assessmentData.IDENTIFY_CIRCLE);
    } else if (question ===  'q4') {
      totalirts = totalirts + this.calcService.identifySignHandler('IDENTIFY_RIGHT_TURN', assessmentData.IDENTIFY_RIGHT_TURN);
    }
    return totalirts;
  }

  getMemory(assessmentData: any, question: string = ''): number {
    let totalMemory2 = 0;
    let totalMemory = 0;


    if (question === '') {
      totalMemory2 = totalMemory2 + this.calcService.memory2Handler('MEMORY2_DIRECTION1', assessmentData.MEMORY2_DIRECTION1);
      totalMemory2 = totalMemory2 + this.calcService.memory2Handler('MEMORY2_DIRECTION2', assessmentData.MEMORY2_DIRECTION2);
      totalMemory2 = totalMemory2 + this.calcService.memory2Handler('MEMORY2_DIRECTION3', assessmentData.MEMORY2_DIRECTION3);
      totalMemory2 = totalMemory2 + this.calcService.memory2Handler('MEMORY2_DIRECTION4', assessmentData.MEMORY2_DIRECTION4);
      if (assessmentData.MEMORY1) {
        totalMemory = this.calcService.identifyMemorySignsHandler(assessmentData.MEMORY1, totalMemory2);
      }
    } else if (question === 'q1') {
      totalMemory = totalMemory + this.calcService.memory2Handler('MEMORY2_DIRECTION1', assessmentData.MEMORY2_DIRECTION1);
      totalMemory = totalMemory + this.calcService.memory2Handler('MEMORY2_DIRECTION2', assessmentData.MEMORY2_DIRECTION2);
      totalMemory = totalMemory + this.calcService.memory2Handler('MEMORY2_DIRECTION3', assessmentData.MEMORY2_DIRECTION3);
      totalMemory = totalMemory + this.calcService.memory2Handler('MEMORY2_DIRECTION4', assessmentData.MEMORY2_DIRECTION4);
    } else if (question === 'q2') {
      if (assessmentData.MEMORY1) {
        totalMemory = this.calcService.identifyMemorySignsHandler(assessmentData.MEMORY1, 0);
      }
    } else if (question === 'auditory') {
      totalMemory = totalMemory + this.calcService.memory2Handler('MEMORY2_DIRECTION1', assessmentData.MEMORY2_DIRECTION1);
      totalMemory = totalMemory + this.calcService.memory2Handler('MEMORY2_DIRECTION2', assessmentData.MEMORY2_DIRECTION2);
      totalMemory = totalMemory + this.calcService.memory2Handler('MEMORY2_DIRECTION3', assessmentData.MEMORY2_DIRECTION3);
      totalMemory = totalMemory + this.calcService.memory2Handler('MEMORY2_DIRECTION4', assessmentData.MEMORY2_DIRECTION4);
    }

    return totalMemory;
  }

  getLandMark(assessmentData: any, question: string = ''): number {
    let totalLM = 0;
    if (question === '') {
      if (assessmentData.LANDMARK_q1 !== undefined) {
        totalLM = totalLM + this.calcService.landmarkHandler('LANDMARK_q1', assessmentData.LANDMARK_q1);
      }
      if (assessmentData.LANDMARK_q2 !== undefined) {
        totalLM = totalLM + this.calcService.landmarkHandler('LANDMARK_q2', assessmentData.LANDMARK_q2);
      }
      if (assessmentData.LANDMARK_q3 !== undefined) {
        totalLM = totalLM + this.calcService.landmarkHandler('LANDMARK_q3', assessmentData.LANDMARK_q3);
      }
    } else if (question === 'q1') {
      if (assessmentData.LANDMARK_q1 !== undefined) {
        totalLM = totalLM + this.calcService.landmarkHandler('LANDMARK_q1', assessmentData.LANDMARK_q1);
      }
    } else if (question === 'q2') {
      if (assessmentData.LANDMARK_q2 !== undefined) {
        totalLM = totalLM + this.calcService.landmarkHandler('LANDMARK_q2', assessmentData.LANDMARK_q2);
      }
    } else if (question === 'q3') {
      if (assessmentData.LANDMARK_q3 !== undefined) {
        totalLM = totalLM + this.calcService.landmarkHandler('LANDMARK_q3', assessmentData.LANDMARK_q3);
      }
    }
    return totalLM;
  }

  getOAT(assessmentData: any, question: string = ''): number {
    let oatTotal = 0;
    if (question === '') {
      if (assessmentData.ORDERTAXI1 !== undefined) {
        oatTotal = oatTotal + this.calcService.orderTaxiHander('ORDERTAXI1', assessmentData.ORDERTAXI1);
      }
      if (assessmentData.ORDERTAXI2 !== undefined) {
        oatTotal = oatTotal + this.calcService.orderTaxiHander('ORDERTAXI2', assessmentData.ORDERTAXI2);
      }
      if (assessmentData.ORDERTAXI3 !== undefined) {
        oatTotal = oatTotal + this.calcService.orderTaxiHander('ORDERTAXI3', assessmentData.ORDERTAXI3);
      }
      if (assessmentData.ORDERTAXI4 !== undefined) {
        oatTotal = oatTotal + this.calcService.orderTaxiHander('ORDERTAXI4', assessmentData.ORDERTAXI4);
      }
    } else if (question === 'q1') {
      if (assessmentData.ORDERTAXI1 !== undefined) {
        oatTotal = oatTotal + this.calcService.orderTaxiHander('ORDERTAXI1', assessmentData.ORDERTAXI1);
      }
    } else if (question === 'q2') {
      if (assessmentData.ORDERTAXI2 !== undefined) {
        oatTotal = oatTotal + this.calcService.orderTaxiHander('ORDERTAXI2', assessmentData.ORDERTAXI2);
      }
    } else if (question === 'q3') {
      if (assessmentData.ORDERTAXI3 !== undefined) {
        oatTotal = oatTotal + this.calcService.orderTaxiHander('ORDERTAXI3', assessmentData.ORDERTAXI3);
      }
    } else if (question === 'q4') {
      if (assessmentData.ORDERTAXI4 !== undefined) {
        oatTotal = oatTotal + this.calcService.orderTaxiHander('ORDERTAXI4', assessmentData.ORDERTAXI4);
      }
    }
    return oatTotal;
  }

  getTimesForKey(key: string, pagesTime: any): number {
    let result = 0;

    // tslint:disable-next-line:prefer-for-of
    for (let i = 0; i < pagesTime.length; i++) {
      if (pagesTime[i]['title'].trim() === key) {
        result = result + pagesTime[i]['time'];
      }
    }

    return result;
  }

  getIntersections(assessmentData: any, question: string = ''): number {
    let totalIntersections = 0;
    let intersectionA = '';
    let intersectionB = '';
    let intersectionC = '';
    let intersectionD = '';
    if (assessmentData.INTERSECTIONS1A !== undefined && assessmentData.INTERSECTIONS1B !== undefined) {
      intersectionA = intersectionA.concat(assessmentData.INTERSECTIONS1A);
      intersectionA = intersectionA.concat(assessmentData.INTERSECTIONS1B);
    }
    if (assessmentData.INTERSECTIONS2A !== undefined &&
       assessmentData.INTERSECTIONS2B !== undefined &&
       assessmentData.INTERSECTIONS2C !== undefined) {
      intersectionB = intersectionB.concat(assessmentData.INTERSECTIONS2A);
      intersectionB = intersectionB.concat(assessmentData.INTERSECTIONS2B);
      intersectionB = intersectionB.concat(assessmentData.INTERSECTIONS2C);
    }
    if (assessmentData.INTERSECTIONS3A !== undefined &&
      assessmentData.INTERSECTIONS3B !== undefined &&
      assessmentData.INTERSECTIONS3C !== undefined) {
      intersectionC = intersectionC.concat(assessmentData.INTERSECTIONS3A);
      intersectionC = intersectionC.concat(assessmentData.INTERSECTIONS3B);
      intersectionC = intersectionC.concat(assessmentData.INTERSECTIONS3C);
    }
    if (assessmentData.INTERSECTIONS4A !== undefined && assessmentData.INTERSECTIONS4B !== undefined) {
      intersectionD = intersectionD.concat(assessmentData.INTERSECTIONS4A);
      intersectionD = intersectionD.concat(assessmentData.INTERSECTIONS4B);
    }
    totalIntersections = this.calcService.intersectionHandler(intersectionA, intersectionB, intersectionC, intersectionD, question);

    return totalIntersections;
  }
  //////////////////////////////////////////////////////////////////

  exportToExcel(): void {

    const tab1: xlsx.WorkSheet = xlsx.utils.table_to_sheet(this.tab1.nativeElement);
    const tab2: xlsx.WorkSheet = xlsx.utils.table_to_sheet(this.tab2.nativeElement);
    const tab3: xlsx.WorkSheet = xlsx.utils.table_to_sheet(this.tab3.nativeElement);
    const tab4: xlsx.WorkSheet = xlsx.utils.table_to_sheet(this.tab4.nativeElement);
    const tab5: xlsx.WorkSheet = xlsx.utils.table_to_sheet(this.tab5.nativeElement);
    const tab6: xlsx.WorkSheet = xlsx.utils.table_to_sheet(this.tab6.nativeElement);
    const tab7: xlsx.WorkSheet = xlsx.utils.table_to_sheet(this.tab7.nativeElement);
    const tab8: xlsx.WorkSheet = xlsx.utils.table_to_sheet(this.tab8.nativeElement);
    // Demographics
    const wb1: xlsx.WorkBook = xlsx.utils.book_new();
    xlsx.utils.book_append_sheet(wb1, tab1, 'Demographics');
    xlsx.utils.book_append_sheet(wb1, tab2, 'Driving Fitness Scores');
    xlsx.utils.book_append_sheet(wb1, tab3, 'Community Mobility Scores');
    xlsx.utils.book_append_sheet(wb1, tab4, 'Test Item Scores');
    xlsx.utils.book_append_sheet(wb1, tab5, 'Raw Data');
    xlsx.utils.book_append_sheet(wb1, tab6, 'Times');
    xlsx.utils.book_append_sheet(wb1, tab7, 'Therapist Client Feedback');
    xlsx.utils.book_append_sheet(wb1, tab8, 'Therapist Test Feedback');

    xlsx.writeFile(wb1, 'CDA_Database.xlsx');
   }

}
