<app-header [showLogout]="false" [showTitle]="false" [showBack]="true"></app-header>
<div class="content">
    <div class="text-center">
          <img src="./assets/images/logo/GOINGPLACES_B&W-HR.jpg" height="120px">
          <h2 class="heading-title" *ngIf="assessmentName === 'CoDriS'">CODRIS SCORES</h2>
          <h2 class="heading-title" *ngIf="assessmentName !== 'CoDriS'">CODRIS EMPLOYMENT SCORES</h2>
    </div>

    <div class="row">
        <div class="col-sm-12 div-but">
            <button type="button" class="btn btn-success" style="width: 120px;margin-right: 2px" (click)="download()">Download</button>
            <button class="btn btn-primary" (click)="showEmailBox = !showEmailBox">Email</button><br><br>
            <p style="color: red;" *ngIf="emailStatus != ''">{{emailStatus}}</p>
            <p style="font-size: small;margin-top: -10px;">Some devices do not allow PDF downloads</p>
        </div>
    </div>
    
    <div *ngIf="showEmailBox" style="margin-top: 10px;margin-bottom: 14px;">
        <div class="modal-content">
            <div class="modal-header">
                <h4 class="modal-title">Send PDF Report</h4>
                <i class="fa fa-times pointer" title="Close" (click)="showEmailBox=false"></i>
            </div>
            <div class="modal-body">
                <table width="100%" border="0">
                    <tr>
                        <th width="1%">To:</th>
                        <td style="text-align: left; width: 100%"><input type="email" class="form-control form-control-sm" placeholder="to email address" [(ngModel)]="toAddress"></td>
                    </tr>
                    <tr>
                        <th width="1%">Cc:</th>
                        <td style="text-align: left; width: 100%"><input type="email" class="form-control form-control-sm" placeholder="cc email address" [(ngModel)]="ccAddress"></td>
                    </tr>
                    <tr>
                        <th width="1%">Participant Name:</th>
                        <td style="text-align: left; width: 100%"><input type="email" class="form-control form-control-sm" placeholder="Participant name" [(ngModel)]="participantName"></td>
                    </tr>
                </table>
               
            </div>
            <div class="modal-footer">
                
                <button type="button" class="btn btn-success" style="width: 120px;" (click)="showEmailBox=false">Cancel</button>
                <button type="button" class="btn btn-success" style="width: 120px;" (click)="sendEmail()" >Send</button>
            </div> 
        </div>
    </div> 

    <div class="row">
        <div class="col-xs-3 col-sm-3"><b>Reference number:</b></div>
        <div class="col-xs-5 col-sm-5"><small>{{token}}</small></div>
        <div class="col-xs-1 col-sm-1"><b>Date:</b></div>
        <div class="col-xs-2 col-sm-2"><small>{{today}}</small></div>
    </div>
    

    <H3>TRIP PLANNING</H3>
    <p><b>This score is an indication of the client’s ability to plan a trip and deal with unforeseen circumstances on route.</b></p>

    <div class="row" *ngFor="let item of tripPlanningItems">
        <div class="col-xs-7 col-sm-7">{{item['itemName']}}</div>
        <div class="col-xs-1 col-sm-1 score-text">{{item['score']}}/{{item['scoreOutOf']}}</div>
    </div>
    <div class="row">
        <div class="col-xs-7 col-sm-7 bold">Total Score</div>
        <div class="col-xs-1 col-sm-1 bold score-text">{{totalTripPlanning}}/{{totalTripPlanningOutOf}}</div>
        <div class="col-xs-1 col-sm-1 bold score-text">{{percTripPlanning}}%</div>
    </div>

   <H3>DRIVING ABILITY</H3>
    <p><b>This score is an indication of the client’s ability to navigate traffic while driving.</b></p>
    <div class="row" *ngFor="let item of drivingAbilityItems">
        <div class="col-xs-7 col-sm-7">{{item['itemName']}}</div>
        <div class="col-xs-1 col-sm-1 score-text">{{item['score']}}/{{item['scoreOutOf']}}</div>
    </div>
    <br>
    <div class="row">
        <div class="col-xs-7 col-sm-7 bold">Total Score</div>
        <div class="col-xs-1 col-sm-1 bold score-text">{{totalDrivingAbility}}/{{totalDrivingAbilityOutOf}}</div>
        <div class="col-xs-1 col-sm-1 bold score-text">&nbsp;{{percDrivingAbility}}%</div>
    </div>
 
   <H3>DRIVING FITNESS</H3>
    <p><b>This is a combined score of the client’s ability to plan a trip, deal with unforeseen circumstances and navigate traffic.</b></p>
    <p>Trip Planning score + Driving Ability score = Driving Fitness score</p>
    <div class="row">
        <div class="col-xs-7 col-sm-7 bold">Total Score</div>
        <div class="col-xs-1 col-sm-1 bold score-text">{{totalDrivingFitness}}/{{totalDrivingFitnessOutOf}}</div>
        <div class="col-xs-1 col-sm-1 bold score-text">&nbsp;{{percDrivingFitness}}%</div>
    </div>

    
    <div style="background-color: #e0e0e0;margin-top: 8px; padding-top: 1px;padding-bottom: 1px; padding-left:6px;padding-right: 6px;">
        <h4>DRIVING FITNESS RISK PROFILE</h4>
        <p><b>The Driving Fitness percentage can be categorised into a risk profile.</b></p>
        
        <p>
            Low risk:	85% – 100% <br>
            Medium risk:	70% – 84%<br>
            High risk: 	69% and below
        </p>
        <p>For guidelines on how to interpret the scores and which steps to take next, please refer to the <b>Score Interpretation</b> document.</p>
        <p>Given the intricate nature of cognitive function and the complexities of driving, the outcome of the CoDriS should be considered as a guideline rather than a definitive determination of the participant’s ability to meet all the cognitive demands of driving.</p>
    </div>
     
    <H3 style="page-break-before: always;">INSIGHT</H3>
    <p><b>This score is an indication of how the client’s perception of their driving ability compares to their performance in the assessment.</b></p>
    <H4>Self Assessment Responses</H4>

    <div class="row" *ngFor="let item of selfAssessmentData">
        <div class="col-xs-7 col-sm-7">{{item['label']}}</div>
        <div class="col-xs-1 col-sm-1 score-text">{{item['value']}}</div>
    </div>
    <br>
    <div class="row">
        <div class="col-xs-7 col-sm-7 bold">Total Score</div>
        <div class="col-xs-1 col-sm-1 bold score-text">{{totalSelfAssessmentData}}/16</div>
        <div class="col-xs-1 col-sm-1 bold score-text">{{percSelfAssessmentData}}%</div>
    </div>

    <br>

    <div class="row">
        <div class="col-xs-12 col-sm-12">
            Insight is determined by the difference between the Self Assessment and Driving Fitness percentage. 
        </div>
    </div>
    <div class="row">
        <div class="col-xs-12 col-sm-12">
            Self Assessment score – Driving Fitness score = Insight score. 
        </div>
    </div>

<br>
    

    <div class="row">
        <div class="col-xs-8">
            <div class="chart-wrapper">
                <canvas baseChart 
                id="selfChart"
                [datasets]="barChartData"
                [labels]="barChartLabels"
                [options]="barChartOptions"
                [plugins]="barChartPlugins"
                [legend]="barChartLegend"
                [chartType]="barChartType">
            </canvas>
            </div>
        </div>
        <div class="col-xs-4 formula">
            <h3>{{column1Perc}} % - {{column2Perc}} % = {{totalDiff}} %</h3>
        </div>
    </div>

    <div style="background-color: #e0e0e0;margin-top: 4px;margin-bottom: 4px; padding-top: 1px;padding-bottom: 10px; padding-left:6px;padding-right: 6px;">
    <H4>INTERPRETATION OF INSIGHT SCORE</H4>
    <table>
        <tr>
            <th width="10%">Good insight</th>
            <th width="90%">Poor insight</th>
        </tr>
        <tr>
            <td width="10%" style="text-align: left;padding:6px;">
                The client has <b>good insight</b> when there is a small difference between the Self Assessment and Driving Fitness percentage.
                <br><br>
                <ul>
                    <li>If both percentages are high, the client’s insight and driving ability is good. The client is aware that they are able to drive.</li>
                    <li>If both percentages are low, the client’s insight is good but they have difficulty with certain aspects of driving. The client is aware that they have difficulties with driving.</li>
                </ul>
            </td>
            <td width="90%" style="text-align: left;padding:6px;">
                The client has <b>poor insight</b> when there is a large difference between the Self Assessment and Driving Fitness percentage. 
                <br><br>
                <ul>
                    <li>If the Self Assessment percentage is higher than the Driving Fitness percentage, the client overestimates their abilities. The client is unaware of their driving difficulties.  This is presented as a (+) insight score.</li>
                    <li>If the Self Assessment percentage is lower than the Driving Fitness percentage, the client underestimates their ability. The client perceives their driving ability to be worse than it possibly is.  This is presented as a (–) insight score.</li>
                </ul>
            </td>
        </tr>
    </table>
</div>

<div *ngIf="assessmentName === 'CoDriS'">


    <H3 style="page-break-before: always;" >COMMUNITY MOBILITY</H3>
    <p><b>This score is an indication of the client’s ability to use various forms of community transport and function independently at their destination.</b></p>
    
    <div class="row" *ngFor="let item of communityItems">
        <div class="col-xs-7 col-sm-7">{{item['itemName']}}</div>
        <div class="col-xs-1 col-sm-1 score-text">{{item['score']}}/{{item['scoreOutOf']}}</div>
    </div>
    <br>
    <div class="row">
        <div class="col-xs-7 col-sm-7 bold">Total Score</div>
        <div class="col-xs-1 col-sm-1 bold score-text">{{totalCommunityMobility}}/{{totalCommunityMobilityOutOf}}</div>
        <div class="col-xs-1 col-sm-1 bold score-text">{{percCommunityMobility}}%</div>
    </div>

    <div style="background-color: #e0e0e0;margin-top: 8px; padding-top: 1px;padding-bottom: 1px; padding-left:6px;padding-right: 6px;">
        <h4>COMMUNITY MOBILITY RISK PROFILE</h4>
        <p>
            Low risk: 	86% - 100%<br>
            Medium risk: 	72% – 85%<br>
            High risk:	 71% and below
        </p>

        <p>For guidelines on how to interpret the scores and which steps to take next, please refer to the <b>Score Interpretation</b> document. </p>
        <p>Considering the intricate nature of cognitive function and the complexities of community mobility, the CoDriS outcome should be viewed as a guideline rather than a definitive determination of the participant’s ability to meet all the cognitive requirements for community mobility.</p>
    </div>
</div>
    <div style="height:200px;"></div>
</div>
<app-footer></app-footer>

