
<app-header [showLogout]="false" [showTitle]="true"></app-header>
<div class="col-md-12">
  <div class="card card-container">
    <form [formGroup]="frmSignup" (submit)="onSubmit()" #f="ngForm">
      <div class="form-group">
        <label for="email" [ngClass]="frmSignup.controls['email'].invalid ? 'text-normal' : ''">Email address</label>
        <input id="email" formControlName="email" type="email" class="form-control" [ngClass]="frmSignup.controls['email'].invalid ? 'is-invalid' : ''">
        <label class="text-normal" *ngIf="frmSignup.controls['email'].hasError('required')">
          Email is required.
        </label>
        <label class="text-normal" *ngIf="frmSignup.controls['email'].hasError('email')">
          Enter a valid email address.
        </label>
      </div>
      <div class="form-group text-center">
        <button class="btn btn-primary btn-block" [disabled]="frmSignup.invalid" type="submit">Reset Password</button>
        <button class="btn btn-link" (click)="goToLogin()">Login</button>
      </div>
      <div class="form-group">
        <div
          class="alert alert-normal"
          role="alert"
          *ngIf="f.submitted && isResetPasswordFailed"
        >
        Reset Password Request Failed: Should you have any queries please email info@going-places.co.za
        </div>
          <div
            class="alert alert-success"
            role="alert"
            *ngIf="f.submitted && !isResetPasswordFailed"
          >
            Reset Password Request Successful: Should you have any queries please email info@going-places.co.za <br><br>
            Please check your email to access the link to reset your password.
          </div>
      </div>
     
    </form>
  </div>
</div>

<app-footer></app-footer>

