<h4>
  <span>
    In this section there are 3 videos that you need to respond to. The instructions for all 3 videos are the same.  The first video is a practice round and will not be scored.  You can play the first video twice, to practice your click reaction. The second and third video can only be played once.<br>
    <div style="font-weight: bold;margin-top: 6px;margin-bottom: 6px;">As you watch the video:</div>
      <ul>
          <li>Click on the <span style="color: #63666A; font-weight: bold;">grey</span> button every time you see a vehicle (all vehicles including bicycles) driving from <b>right to left</b>
          <div style="display: inline-block; width: 50px;text-align: center;padding-left: 10px;">
            <img width="100%" src="../../../assets/images/arrows/arrowLeftGrey.png"/>
          </div>.  Alternatively click the <b>N</b> key on the keyboard.
          </li>

          <li>Click on the <span style="color: #1ab394; font-weight: bold;">green</span> button every time you see a vehicle driving from <b>left to right</b>
          <div  style="display: inline-block; width: 50px;text-align: center;padding-left: 10px;">
            <img width="100%" src="../../../assets/images/arrows/arrowRightGreen.png"/>
          </div>. Alternatively click the <b>B</b> key on the keyboard.
        </li>
      </ul>
      Your ability to respond to each vehicle is assessed. How quickly you click the button after seeing a vehicle is not important.
    </span>
  </h4>
    <main>
      <div class="videoWrapper" style="--aspect-ratio: 3 / 4;">
        <iframe appIframeTracker (iframeClick)="onIframeClick()" width=883 height=496 style="border:0;" scrolling="no" src="https://screencast-o-matic.com/player/c3Qq22VO4XX?width=883&height=496&ff=1&title=0&controls=0" allowfullscreen="true"></iframe>
      </div>
    </main>
    <input #hidden type="hidden" name="hiddeninput" value=""> 

<div  class="tableDiv" style="margin-top: 32%;"> 
  <table>
        <tr>
          <td class="green-text">{{nrLeftClicksDisplay}}</td>
          <td>
              <button *ngIf="bShowGreenButtonBorder === 0" class="greenbutton" (click)="clickImageArea('rightToLeft')">button</button>
              <button *ngIf="bShowGreenButtonBorder === 1" class="greenbutton-border" (click)="clickImageArea('rightToLeft')">button</button></td>
          <td>
              <button *ngIf="bShowGreyButtonBorder === 0" class="greybutton" (click)="clickImageArea('leftToRight')">button</button>
              <button *ngIf="bShowGreyButtonBorder === 1" class="greybutton-border" (click)="clickImageArea('leftToRight')">button</button>
          </td>
          <td class="grey-text">{{nrRightClicksDisplay}}</td>
        </tr>
    </table>
</div>
