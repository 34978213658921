import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-therapist-complete',
  templateUrl: './therapist-complete.component.html',
  styleUrls: ['./therapist-complete.component.css']
})
export class TherapistCompleteComponent implements OnInit {

  constructor(private router: Router) { }

  ngOnInit(): void {
  }

  viewReport(): void {
    this.router.navigate(['treport']);
  }

}
