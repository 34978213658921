import { Component, Input, OnInit } from '@angular/core';
import { TokenStorageService } from '../_services/token-storage.service';
import { AuthService } from '../_services/auth.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['header-footer.component.css']
})
export class HeaderComponent implements OnInit {

  constructor(private tokenStorageService: TokenStorageService, private authService: AuthService, private router: Router) { }

  @Input() showLogout = false;
  @Input() showTitle = false;
  @Input() showBack = false;
  @Input() showResults = false;
  @Input() showDescription = false;

  ngOnInit(): void {
    var username = this.tokenStorageService.getUsername();
    if (username == '') {
      this.showBack = false;
    }
  }

  logout() {
    var username = this.tokenStorageService.getUsername();
    this.authService.logout(username).subscribe(
      data => {
        this.tokenStorageService.signOut();
        this.router.navigate(['/']);
      },
      err => {
        console.log('error', err);
      }
    );
  }

  goHome() {
    const isLoggedIn = !!this.tokenStorageService.getAuthToken();
    if (isLoggedIn) {
      this.router.navigate(['/home']);
    } else {
      this.router.navigate(['']);
    }
  }

  goResults() {
    const isLoggedIn = !!this.tokenStorageService.getAuthToken();
    if (isLoggedIn) {
      this.router.navigate(['/results']);
    }
  }
 
}
