import { Component, Input } from '@angular/core';
import { IComunnityMobility } from '../results.component';

@Component({
  selector: 'app-community-mobility',
  styles: [
    `
    .pane{
      padding: 1em;
    }
  `
  ],
  template: `
    <table class="table table-bordered" style="visibility: visible;">
            <thead class="thead-dark">
            <tr>
                <th>Date</th>
                <th>ClientRefNo</th>
                <th>OTT</th>
                <th>TC</th>
                <th>MM</th>
                <th>FC</th>
                <th>ITS</th>
                <th>D</th>
                <th>M</th>
                <th>FRTS</th>
                <th>LN</th>
                <th>RF</th>
                <th>OAT</th>
                <th>CM33</th>
                <th>CM%</th>
            </tr>
            </thead>
            <tbody>
                <tr *ngFor="let item of communityMobilities; let i = index;">
                    <td>{{item.dDateCreated}}</td>
                    <td>{{item.sToken}}</td>
                    <td>{{item.tto}}</td>
                    <td>{{item.tc}}</td>
                    <td>{{item.mm}}</td>
                    <td>{{item.fc}}</td>
                    <td>{{item.irts}}</td>
                    <td>{{item.direction}}</td>
                    <td>{{item.memory}}</td>
                    <td>{{item.frts}}</td>
                    <td>{{item.landmark}}</td>
                    <td>{{item.rf}}</td>
                    <td>{{item.oat}}</td>
                    <td>{{item.cm}}</td>
                    <td>{{item.cmPerc}}</td>
                </tr>
            </tbody>
        </table>
  `
})
export class CommunityMobilityComponent {
  @Input() communityMobilities: IComunnityMobility[] = [];
}
