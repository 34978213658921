import { CalculationService } from './../../calculation.service';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { ChartOptions, ChartType, ChartDataSets } from 'chart.js';
import { ActivatedRoute } from '@angular/router';
import { IreportItem } from '../results/results.component';
import html2canvas from 'html2canvas';
import { AssessmentService } from '../_services/assessment.service';


export interface iReportItem {
  itemName: string;
  scoreOutOf: number;
  score: number;
}

@Component({
  selector: 'app-report',
  templateUrl: './report.component.html',
  styleUrls: ['./report.component.css']
})
export class ReportComponent implements OnInit, OnDestroy {

  surveyResults: any = {};
  surveyTime = 0;
  token = '';
  today = new Date().toISOString().slice(0, 10);
  
  showEmailBox = false;
  toAddress = '';
  ccAddress = '';
  downloadReport = false;
  emailStatus = '';

  selfAssessmentData: any[] = [];

  //
  // TRIP PLANNING
  //

  placeOrientation = 0;
  timeHiddenDateTime: Date = new Date();
  timeYear = 0;
  timeMonth = 0;
  timeWeekDay = 0;
  timeDay = 0;
  timeNoLookHour = 0;
  timeNoLookMinute = 0;
  timeLookHour = 0;
  timeLookMinute = 0;
  timeCalculation = 0;
  totalTimeOrientation = 0;
  percTimeOrientation = '0';
  totalDirections = 0;
  totalMoneyManagement = 0;
  totalFuelCalculation = 0;
  countryName = '';
  regionName = '';
  tripPlanningItems: iReportItem[] = [];
  communityItems: iReportItem[] = [];

  //
  // DRIVING ABILITY
  //

  totalIdentifySigns = 0;
  totalMemory = 0;
  totalMemory2 = 0;
  totalVisualMemory = 0;
  totalAuditoryMemory = 0;
  totalMemoryDirections = 0;
  totalFindRoadTrafficSign = 0;
  totalRoadRules = 0;
  totalDrivingManoeuvre = 0;
  totalDrivingManoeuvreFunc = 0;
  intersectionA = '';
  intersectionB = '';
  intersectionC = '';
  intersectionD = '';
  totalIntersections = 0;
  arrMemorySigns = Array();
  arrRoadSignClicks = Array();
  arrCarInteriorClicks = Array();
  arrRoadSideClicks = Array();
  landmarkClick1 = '';
  landmarkClick2 = '';
  landmarkClick3 = '';
  totalRoadSideFactors = 0;
  video1 = {rightClicks: 0, leftClicks: 0, videoName: 'video1'};
  video2 = {rightClicks: 0, leftClicks: 0, videoName: 'video2'};
  video3 = {rightClicks: 0, leftClicks: 0, videoName: 'video3'};
  totalVideoLeftToRightClicks = 0;
  totalVideoRightToLeftClicks = 0;
  totalTaxi = 0;
  totalTripInstructions = 0;
  drivingAbilityItems: iReportItem[] = [];

  totalSelfAssessmentData = 0;
  percSelfAssessmentData = '0';
  totalTripPlanning = 0;
  totalTripPlanningOutOf = 0;
  percTripPlanning = '0';
  totalDrivingAbility = 0;
  totalDrivingAbilityOutOf = 0;
  percDrivingAbility = '0';
  percDrivingAbilityOutOf = 0;
  totalDrivingFitness = 0;
  totalDrivingFitnessOutOf = 0;
  percDrivingFitness = '0';
  totalCommunityMobility = 0;
  totalCommunityMobilityOutOf = 0;
  percCommunityMobility = '0';
  totalTransportation = 0;
  percTransportation = '0';

  landmark = 0;

  totalDiff = 0;

  // chart
  chartURI = "";
  column1Perc = 50;
  column2Perc = 50;
  barChartOptions: ChartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      yAxes: [{
          ticks: {
              beginAtZero: true,
              fontSize: 14,
          }
      }],
      xAxes: [{
        ticks: {
            beginAtZero: true,
            fontSize: 16,
        }
    }]
    },
    tooltips: {
      callbacks: {
          // tslint:disable-next-line:typedef
          label: function(tooltipItem) {
              return ' ' + Number(tooltipItem.yLabel) + ' %';
          }
      }
  },
  plugins: {
      legend: {
          labels: {
              // This more specific font property overrides the global property
              font: {
                  size: 14,
                  family: '\'Open Sans\''
              }
          }
      }
  }

  };
  // tslint:disable-next-line:ban-types
  barChartLabels: any[] = ['Self Assessment', 'Driving Fitness'];
  barChartType: ChartType = 'bar';
  barChartLegend = false;
  barChartPlugins = [];
  barChartData: ChartDataSets[] = [];

  dbResult: IreportItem[] = [];
  assessmentName = 'CoDriS';
  participantName = '';

  constructor(private calcService: CalculationService, 
              private activatedRoute: ActivatedRoute,
              private assessmentService: AssessmentService) {
  }

  ngOnInit(): void {

    // get token and fetch data from database
    const token = this.activatedRoute.snapshot.paramMap.get('id')?.toString();
    this.getTokenData(token!);
  }

  getTokenData(token: string): void {
    this.assessmentService.getTokenData(token).subscribe(
      data => {
        this.assessmentName = data.assessmentName;
        this.participantName = data.participantName;
        this.getAssessmentData(token);
      },
      err => {
       console.log('error fetching token data', err);
      }
    );
 }

 getAssessmentData(token: string): void {
  this.assessmentService.getAssessmentData(token).subscribe(
    data => {
        this.formatData(data);
    },
    err => {
     console.log('error fetching assessment data', err);
    }
  );
 }

  ngOnDestroy(): void {
  }

  formatData(dbResult: []): void {

    this.dbResult = dbResult;
    const replacedData = this.dbResult[0].assessmentData.replace(/\r?\n|\r/g, '');
    const assessmentData = JSON.parse(replacedData);
    this.today = this.dbResult[0].dDateCreated.split(' ')[0];
    this.token = this.dbResult[0].sToken;

    this.arrCarInteriorClicks = assessmentData.CarInteriorClicks;
    this.arrRoadSideClicks = assessmentData.RoadSideClicks;
    this.arrRoadSignClicks = assessmentData.RoadSignClicks;

    this.landmarkClick1 = assessmentData.LANDMARK_q1;
    this.landmarkClick2 = assessmentData.LANDMARK_q2;
    this.landmarkClick3 = assessmentData.LANDMARK_q3;

    this.video1 = assessmentData.video1;
    this.video2 = assessmentData.video2;
    this.video3 = assessmentData.video3;

    //
    // TRIP PLANNING
    //
    if (assessmentData.ORIENTATION_TIME_hiddenDateTime) {
      this.timeHiddenDateTime = new Date(assessmentData.ORIENTATION_TIME_hiddenDateTime);
    } 
    if (assessmentData.ORIENTATION_TIME_month) {
      this.timeMonth = assessmentData.ORIENTATION_TIME_month;
    }
    if (assessmentData.ORIENTATION_TIME_year) {
      this.timeYear = assessmentData.ORIENTATION_TIME_year;
    }
    if (assessmentData.ORIENTATION_TIME_week_day) {
      this.timeWeekDay = assessmentData.ORIENTATION_TIME_week_day;
    }
    if (assessmentData.ORIENTATION_TIME_day) {
      this.timeDay = assessmentData.ORIENTATION_TIME_day;
    }
    if (assessmentData.ORIENTATION_TIME_no_look_now_hour) {
      this.timeNoLookHour = assessmentData.ORIENTATION_TIME_no_look_now_hour;
    }
    if (assessmentData.ORIENTATION_TIME_no_look_now_minute) {
      this.timeNoLookMinute = assessmentData.ORIENTATION_TIME_no_look_now_minute;
    }
    if (assessmentData.ORIENTATION_TIME_look_now_hour) {
      this.timeLookHour = assessmentData.ORIENTATION_TIME_look_now_hour;
    }
    if (assessmentData.ORIENTATION_TIME_look_now_minute) {
      this.timeLookMinute = assessmentData.ORIENTATION_TIME_look_now_minute;
    } 
    if (assessmentData.TIME_calculation) {
      this.timeCalculation = this.calcService.timeCalculationHandler(assessmentData.TIME_calculation);
    } 
    if (assessmentData.DIRECTION1) {
      this.totalDirections = this.totalDirections + this.calcService.directionHandler('DIRECTION1', assessmentData.DIRECTION1);
    }
    if (assessmentData.DIRECTION2) {
      this.totalDirections = this.totalDirections + this.calcService.directionHandler('DIRECTION2', assessmentData.DIRECTION2);
    }
    if (assessmentData.DIRECTION3) {
      this.totalDirections = this.totalDirections + this.calcService.directionHandler('DIRECTION3', assessmentData.DIRECTION3);
    }
    if (assessmentData.DIRECTION4) {
      this.totalDirections = this.totalDirections + this.calcService.directionHandler('DIRECTION4', assessmentData.DIRECTION4);
    }
    if (assessmentData.MONEY_MANAGEMENT_parking) {
      this.totalMoneyManagement = this.totalMoneyManagement + this.calcService.moneyParkingHandler(assessmentData.MONEY_MANAGEMENT_parking);
    } 
    if (assessmentData.MONEY_MANAGEMENT_change) {
      this.totalMoneyManagement = this.totalMoneyManagement + this.calcService.moneyChangeHandler(assessmentData.MONEY_MANAGEMENT_change);
    }
    if (assessmentData.FUEL_CALCULATION) {
      this.totalFuelCalculation = this.totalFuelCalculation + this.calcService.fuelCalculationHandler(assessmentData.FUEL_CALCULATION);
    }

    //
    // DRIVING ABILITY
    //

    if (assessmentData.IDENTIFY_PEDESTRIAN) {
      // tslint:disable-next-line:max-line-length
      this.totalIdentifySigns = this.totalIdentifySigns + this.calcService.identifySignHandler('IDENTIFY_PEDESTRIAN', assessmentData.IDENTIFY_PEDESTRIAN);
    } 
    if (assessmentData.IDENTIFY_STOP) {
      // tslint:disable-next-line:max-line-length
      this.totalIdentifySigns = this.totalIdentifySigns + this.calcService.identifySignHandler('IDENTIFY_STOP', assessmentData.IDENTIFY_STOP);
    }
    if (assessmentData.IDENTIFY_CIRCLE) {
      // tslint:disable-next-line:max-line-length
      this.totalIdentifySigns = this.totalIdentifySigns + this.calcService.identifySignHandler('IDENTIFY_CIRCLE', assessmentData.IDENTIFY_CIRCLE);
    }
    if (assessmentData.IDENTIFY_RIGHT_TURN) {
      // tslint:disable-next-line:max-line-length
      this.totalIdentifySigns = this.totalIdentifySigns + this.calcService.identifySignHandler('IDENTIFY_RIGHT_TURN', assessmentData.IDENTIFY_RIGHT_TURN);
    }
    if (assessmentData.MEMORY1) {
      this.arrMemorySigns = assessmentData.MEMORY1;
    }
    if (assessmentData.MEMORY2_DIRECTION1) {
      this.totalMemory2 = this.totalMemory2 + this.calcService.memory2Handler('MEMORY2_DIRECTION1', assessmentData.MEMORY2_DIRECTION1);
    }
    if (assessmentData.MEMORY2_DIRECTION2) {
      this.totalMemory2 = this.totalMemory2 + this.calcService.memory2Handler('MEMORY2_DIRECTION2', assessmentData.MEMORY2_DIRECTION2);
    }
    if (assessmentData.MEMORY2_DIRECTION3) {
      this.totalMemory2 = this.totalMemory2 + this.calcService.memory2Handler('MEMORY2_DIRECTION3', assessmentData.MEMORY2_DIRECTION3);
    }
    if (assessmentData.MEMORY2_DIRECTION4) {
      this.totalMemory2 = this.totalMemory2 + this.calcService.memory2Handler('MEMORY2_DIRECTION4', assessmentData.MEMORY2_DIRECTION4);
    }
    if (assessmentData.INTERSECTIONS1A) {
      this.intersectionA = this.intersectionA.concat(assessmentData.INTERSECTIONS1A);
    }
    if (assessmentData.INTERSECTIONS1B) {
      this.intersectionA = this.intersectionA.concat(assessmentData.INTERSECTIONS1B);
    }
    if (assessmentData.INTERSECTIONS2A) {
      this.intersectionB = this.intersectionB.concat(assessmentData.INTERSECTIONS2A);
    }
    if (assessmentData.INTERSECTIONS2B) {
      this.intersectionB = this.intersectionB.concat(assessmentData.INTERSECTIONS2B);
    }
    if (assessmentData.INTERSECTIONS2C) {
      this.intersectionB = this.intersectionB.concat(assessmentData.INTERSECTIONS2C);
    }
    if (assessmentData.INTERSECTIONS3A) {
      this.intersectionC = this.intersectionC.concat(assessmentData.INTERSECTIONS3A);
    }
    if (assessmentData.INTERSECTIONS3B) {
      this.intersectionC = this.intersectionC.concat(assessmentData.INTERSECTIONS3B);
    }
    if (assessmentData.INTERSECTIONS3C) {
      this.intersectionC = this.intersectionC.concat(assessmentData.INTERSECTIONS3C);
    }
    if (assessmentData.INTERSECTIONS4A) {
      this.intersectionD = this.intersectionD.concat(assessmentData.INTERSECTIONS4A);
    }
    if (assessmentData.INTERSECTIONS4B) {
      this.intersectionD = this.intersectionD.concat(assessmentData.INTERSECTIONS4B);
    }
    if (assessmentData.ROADRULES) {
      this.totalRoadRules =  this.calcService.roadRulesHandler(Object.entries(assessmentData.ROADRULES));
    }
    if (assessmentData.ROADSIDEFACTORS_q1) {
      if (assessmentData.ROADSIDEFACTORS_q1 === 'One way') {
        this.totalRoadSideFactors = this.totalRoadSideFactors + 1;
      }
    } 
    if (assessmentData.DRIVING_MANOEUVRE_triangular) {
      if (assessmentData.DRIVING_MANOEUVRE_triangular === 'One road lane is merging into another.') {
        this.totalDrivingManoeuvre = this.totalDrivingManoeuvre + 1;
      }
    }
    if (assessmentData.ORDERTAXI1) {
      this.totalTaxi = this.totalTaxi + this.calcService.orderTaxiHander('ORDERTAXI1', assessmentData.ORDERTAXI1);
    }
    if (assessmentData.ORDERTAXI2) {
      this.totalTaxi = this.totalTaxi + this.calcService.orderTaxiHander('ORDERTAXI2', assessmentData.ORDERTAXI2);
    }
    if (assessmentData.ORDERTAXI3) {
      this.totalTaxi = this.totalTaxi + this.calcService.orderTaxiHander('ORDERTAXI3', assessmentData.ORDERTAXI3);
    }
    if (assessmentData.ORDERTAXI4) {
      this.totalTaxi = this.totalTaxi + this.calcService.orderTaxiHander('ORDERTAXI4', assessmentData.ORDERTAXI4);
    } 
    if (assessmentData.SELF_ASSESSMENT_active_driver || assessmentData.SELF_ASSESSMENT_non_active_driver || assessmentData.SELF_ASSESSMENT_performance) {
      if (assessmentData.SELF_ASSESSMENT_active_driver) {
        const answers = Object.entries(assessmentData.SELF_ASSESSMENT_active_driver);
        this.selfAssessmentHandler(answers, 'SELF_ASSESSMENT_active_driver');
      }  
      if (assessmentData.SELF_ASSESSMENT_non_active_driver) {
        const answers = Object.entries(assessmentData.SELF_ASSESSMENT_non_active_driver);
        this.selfAssessmentHandler(answers, 'SELF_ASSESSMENT_non_active_driver');
      }  
      if (assessmentData.SELF_ASSESSMENT_performance) {
        const answers = Object.entries(assessmentData.SELF_ASSESSMENT_performance);
        this.selfAssessmentHandler(answers, 'SELF_ASSESSMENT_performance');
      } 
    } 
    if (assessmentData.LANDMARK_q1) {
      this.landmark = this.landmark + this.calcService.landmarkHandler('LANDMARK_q1', assessmentData.LANDMARK_q1);
    }
    if (assessmentData.LANDMARK_q2) {
      this.landmark = this.landmark + this.calcService.landmarkHandler('LANDMARK_q2', assessmentData.LANDMARK_q2);
    }
    if (assessmentData.LANDMARK_q3) {
      this.landmark = this.landmark + this.calcService.landmarkHandler('LANDMARK_q3', assessmentData.LANDMARK_q3);
    }

    // trip instructions - codris employer
    if (assessmentData.p18q6) {
      if (assessmentData.p18q6 === '23') {
        this.totalTripInstructions++;
      }
    }
    if (assessmentData.p18q7) {
      if (assessmentData.p18q7 === '10 Boxes 4 Bags') {
        this.totalTripInstructions++;
      }
    }
    if (assessmentData.p18q8) {
      if (assessmentData.p18q8 === 'Alpha') {
        this.totalTripInstructions++;
      }
    }
    if (assessmentData.p18q9) {
      if (assessmentData.p18q9 === 'No') {
        this.totalTripInstructions++;
      }
    }


    this.totalVisualMemory = this.calcService.getVisualMemory(this.arrMemorySigns);
    this.totalAuditoryMemory = this.totalMemory2;
    this.totalMemory = this.calcService.identifyMemorySignsHandler(this.arrMemorySigns, this.totalMemory2);
    this.totalTimeOrientation =  this.calcService.timeOrientationHandler(this.timeYear,
                                                                        this.timeHiddenDateTime,
                                                                        this.timeNoLookHour,
                                                                        this.timeNoLookMinute,
                                                                        this.timeLookHour,
                                                                        this.timeLookMinute,
                                                                        this.timeMonth,
                                                                        this.timeWeekDay,
                                                                        this.timeDay,
                                                                        '');

    
    this.totalFindRoadTrafficSign = this.calcService.findRoadTrafficSignHandler(this.arrRoadSignClicks);
    this.totalRoadSideFactors = this.totalRoadSideFactors + this.calcService.roadSideFactorsHandler(this.arrRoadSideClicks);
    this.totalDrivingManoeuvre = this.totalDrivingManoeuvre + this.calcService.drivingManoeuvreHandler(this.arrCarInteriorClicks);
    this.totalVideoLeftToRightClicks = this.calcService.dividedAttentionHandler(this.video2, this.video3, 'left');
    this.totalVideoRightToLeftClicks = this.calcService.dividedAttentionHandler(this.video2, this.video3, 'right');
    this.totalIntersections = this.calcService.intersectionHandler(this.intersectionA, this.intersectionB, this.intersectionC, this.intersectionD);
    
    this.buildTripPlanning();
    this.buildDrivingAbility();
    if (this.assessmentName === 'CoDriS') {
      this.buildCommunity();
    }
    this.setTotals();
  }
  
  buildTripPlanning(): void {

    if (this.assessmentName === 'CoDriS') {
      this.tripPlanningItems.push({itemName: 'Orientation to time', score: this.totalTimeOrientation, scoreOutOf: 3 });
      this.tripPlanningItems.push({itemName: 'Time calculation (Problem solving)', score: this.timeCalculation, scoreOutOf: 1 });
      this.tripPlanningItems.push({itemName: 'Money management (Problem solving)', score: this.totalMoneyManagement, scoreOutOf: 2 });
      this.tripPlanningItems.push({itemName: 'Fuel calculation (Problem solving and decision making)', score: this.totalFuelCalculation, scoreOutOf: 1 });
      this.tripPlanningItems.push({itemName: 'Directions (Immediate recall and working memory)', score: this.totalDirections, scoreOutOf: 4 });
      this.tripPlanningItems.push({itemName: 'Order a taxi (Following instructions)', score: this.totalTaxi, scoreOutOf: 4 });

    } else {
      this.tripPlanningItems.push({itemName: 'Orientation to time', score: this.totalTimeOrientation, scoreOutOf: 3 });
      this.tripPlanningItems.push({itemName: 'Time calculation', score: this.timeCalculation, scoreOutOf: 1 });
      this.tripPlanningItems.push({itemName: 'Money management', score: this.totalMoneyManagement, scoreOutOf: 2 });
      this.tripPlanningItems.push({itemName: 'Fuel calculation', score: this.totalFuelCalculation, scoreOutOf: 1 });
      this.tripPlanningItems.push({itemName: 'Directions', score: this.totalDirections, scoreOutOf: 4 });
      this.tripPlanningItems.push({itemName: 'Trip instructions', score: this.totalTripInstructions, scoreOutOf: 4 });
    }
    for (var i=0; i < this.tripPlanningItems.length; i++) {
      this.totalTripPlanningOutOf += this.tripPlanningItems[i].scoreOutOf;
    }
    this.totalDrivingFitnessOutOf += this.totalTripPlanningOutOf;
  }

  buildDrivingAbility(): void {

    if (this.assessmentName === 'CoDriS') {
      this.drivingAbilityItems.push({itemName: 'Identifying traffic signs (Object recognition and scanning)', score: this.totalIdentifySigns, scoreOutOf: 4 });
      this.drivingAbilityItems.push({itemName: 'Visual memory (Delayed recall)', score: this.totalVisualMemory, scoreOutOf: 2 });
      this.drivingAbilityItems.push({itemName: 'Auditory memory (Delayed recall)', score: this.totalAuditoryMemory, scoreOutOf: 2 });
      this.drivingAbilityItems.push({itemName: 'Find the traffic sign (Form constancy and figure ground)', score: this.totalFindRoadTrafficSign, scoreOutOf: 3 });
      this.drivingAbilityItems.push({itemName: 'Intersections (Procedural memory)', score: this.totalIntersections, scoreOutOf: 4 });
      this.drivingAbilityItems.push({itemName: 'Landmark navigation (Spatial orientation)', score: this.landmark, scoreOutOf: 3 });
      this.drivingAbilityItems.push({itemName: 'Road rules (Semantic, learnt memory)', score: this.totalRoadRules, scoreOutOf: 4 });
      this.drivingAbilityItems.push({itemName: 'Roadside factors (Scanning and reasoning)', score: this.totalRoadSideFactors, scoreOutOf: 4 });
      this.drivingAbilityItems.push({itemName: 'Driving manoeuvre (Object recognition and procedural memory)', score: this.totalDrivingManoeuvre, scoreOutOf: 4 });
    } else {
      this.drivingAbilityItems.push({itemName: 'Identifying traffic signs', score: this.totalIdentifySigns, scoreOutOf: 4 });
      this.drivingAbilityItems.push({itemName: 'Visual memory', score: this.totalVisualMemory, scoreOutOf: 2 });
      this.drivingAbilityItems.push({itemName: 'Auditory memory', score: this.totalAuditoryMemory, scoreOutOf: 2 });
      this.drivingAbilityItems.push({itemName: 'Find the traffic sign', score: this.totalFindRoadTrafficSign, scoreOutOf: 3 });
      this.drivingAbilityItems.push({itemName: 'Intersections', score: this.totalIntersections, scoreOutOf: 4 });
      this.drivingAbilityItems.push({itemName: 'Landmark navigation', score: this.landmark, scoreOutOf: 3 });
      this.drivingAbilityItems.push({itemName: 'Road rules', score: this.totalRoadRules, scoreOutOf: 4 });
      this.drivingAbilityItems.push({itemName: 'Roadside factors', score: this.totalRoadSideFactors, scoreOutOf: 4 });
      this.drivingAbilityItems.push({itemName: 'Driving manoeuvre', score: this.totalDrivingManoeuvre, scoreOutOf: 4 });
    }
    for (var i=0; i < this.drivingAbilityItems.length; i++) {
      this.totalDrivingAbilityOutOf += this.drivingAbilityItems[i].scoreOutOf;
    }
    this.totalDrivingFitnessOutOf += this.totalDrivingAbilityOutOf;
  }

  buildCommunity(): void {
    this.communityItems.push({itemName: 'Orientation to time', score: this.totalTimeOrientation, scoreOutOf: 3 });
    this.communityItems.push({itemName: 'Time calculation (Problem solving)', score: this.timeCalculation, scoreOutOf: 1 });
    this.communityItems.push({itemName: 'Money management (Problem solving)', score: this.totalMoneyManagement, scoreOutOf: 2 });
    this.communityItems.push({itemName: 'Fuel calculation (Problem solving and decision making)', score: this.totalFuelCalculation, scoreOutOf: 1 });
    this.communityItems.push({itemName: 'Directions (Immediate recall and working memory)', score: this.totalDirections, scoreOutOf: 4 });
    this.communityItems.push({itemName: 'Identifying traffic signs (Object recognition and scanning)', score: this.totalIdentifySigns, scoreOutOf: 4 });
    this.communityItems.push({itemName: 'Memory (Delayed recall)', score: this.totalMemory, scoreOutOf: 4 });
    this.communityItems.push({itemName: 'Find the traffic sign (Form constancy and figure ground)', score: this.totalFindRoadTrafficSign, scoreOutOf: 3 });
    this.communityItems.push({itemName: 'Landmark navigation (Spatial orientation)', score: this.landmark, scoreOutOf: 3 });
    this.communityItems.push({itemName: 'Roadside factors (Scanning and reasoning)', score: this.totalRoadSideFactors, scoreOutOf: 4 });
    this.communityItems.push({itemName: 'Order a taxi (Following instructions)', score: this.totalTaxi, scoreOutOf: 4 });

    for (var i=0; i < this.communityItems.length; i++) {
      this.totalCommunityMobilityOutOf += this.communityItems[i].scoreOutOf;
    }
    
  }

  selfAssessmentHandler(arr: any[] = [], sType: string): void {
    // console.log('selfAssessmentHandler', arr, sType);
    let answer = '';

    if (sType === 'SELF_ASSESSMENT_active_driver') {
      // tslint:disable-next-line:prefer-for-of
       for (let i = 0; i < arr.length; i++) {
        if (arr[i][1] === 1) {
          answer = 'Sometimes';
          this.totalSelfAssessmentData = this.totalSelfAssessmentData + 1;
        } else if (arr[i][1] === 2) {
          answer = 'Always';
        } else {
          answer = 'Never';
          this.totalSelfAssessmentData = this.totalSelfAssessmentData + 2;
        }
        this.selfAssessmentData.push({label: arr[i][0], value: answer});
       }
    }
    if (sType === 'SELF_ASSESSMENT_non_active_driver') {
       // tslint:disable-next-line:prefer-for-of
       for (let i = 0; i < arr.length; i++) {
        if (arr[i][1] === 1) {
          answer = 'Maybe';
          this.totalSelfAssessmentData = this.totalSelfAssessmentData + 1;
        } else if (arr[i][1] === 2) {
          answer = 'Yes';
        } else {
          answer = 'No';
          this.totalSelfAssessmentData = this.totalSelfAssessmentData + 2;
        }
        this.selfAssessmentData.push({label: arr[i][0], value: answer});
       }
    }
    if (sType === 'SELF_ASSESSMENT_performance') {
      // tslint:disable-next-line:prefer-for-of
      for (let i = 0; i < arr.length; i++) {
        if (arr[i][1] === '0') {
          answer = 'Poor';
        }
        if (arr[i][1] === '1') {
          answer = 'Average';
          this.totalSelfAssessmentData = this.totalSelfAssessmentData + 1;
        }
        if (arr[i][1] === '2'){
          answer = 'Great';
          this.totalSelfAssessmentData = this.totalSelfAssessmentData + 2;
        }
        this.selfAssessmentData.push({label: arr[i][0], value: answer});
       }
    }
    this.percSelfAssessmentData = (this.totalSelfAssessmentData / 16 * 100).toFixed(0);
  }

  setTotals(): void {
    //
    // TRIP PLANNING
    //

    this.totalTripPlanning = this.totalTripPlanning + this.totalTimeOrientation;
    this.totalTripPlanning = this.totalTripPlanning + this.timeCalculation;
    this.totalTripPlanning = this.totalTripPlanning + this.totalDirections;
    this.totalTripPlanning = this.totalTripPlanning + this.totalMoneyManagement;
    this.totalTripPlanning = this.totalTripPlanning + this.totalFuelCalculation;
    this.totalTripPlanning = this.totalTripPlanning + this.totalTaxi;
    this.totalTripPlanning = this.totalTripPlanning + this.totalTripInstructions;
    this.percTripPlanning = (this.totalTripPlanning / this.totalTripPlanningOutOf * 100).toFixed(0);

    //
    // DRIVING ABILITY
    //

    this.totalDrivingAbility = this.totalDrivingAbility + this.totalIdentifySigns;
    this.totalDrivingAbility = this.totalDrivingAbility + this.totalMemory;
    this.totalDrivingAbility = this.totalDrivingAbility + this.totalFindRoadTrafficSign;
    this.totalDrivingAbility = this.totalDrivingAbility + this.totalIntersections;
    this.totalDrivingAbility = this.totalDrivingAbility + this.landmark;
    this.totalDrivingAbility = this.totalDrivingAbility + this.totalRoadRules;
    this.totalDrivingAbility = this.totalDrivingAbility + this.totalRoadSideFactors;
    this.totalDrivingAbility = this.totalDrivingAbility + this.totalVideoLeftToRightClicks;
    this.totalDrivingAbility = this.totalDrivingAbility + this.totalVideoRightToLeftClicks;
    this.totalDrivingAbility = this.totalDrivingAbility + this.totalDrivingManoeuvre;
    this.percDrivingAbility = (this.totalDrivingAbility / this.totalDrivingAbilityOutOf * 100).toFixed(0);

    //
    // COMMUNITY MOBILITY
    //

    this.totalCommunityMobility = this.totalCommunityMobility + this.totalTimeOrientation;
    this.totalCommunityMobility = this.totalCommunityMobility + this.timeCalculation;
    this.totalCommunityMobility = this.totalCommunityMobility + this.totalMoneyManagement;
    this.totalCommunityMobility = this.totalCommunityMobility + this.totalFuelCalculation;
    this.totalCommunityMobility = this.totalCommunityMobility + this.totalDirections;
    this.totalCommunityMobility = this.totalCommunityMobility + this.totalIdentifySigns;
    this.totalCommunityMobility = this.totalCommunityMobility + this.totalMemory;
    this.totalCommunityMobility = this.totalCommunityMobility + this.totalFindRoadTrafficSign;
    this.totalCommunityMobility = this.totalCommunityMobility + this.landmark;
    this.totalCommunityMobility = this.totalCommunityMobility + this.totalRoadSideFactors;
    this.totalCommunityMobility = this.totalCommunityMobility + this.totalTaxi;
    this.percCommunityMobility = (this.totalCommunityMobility / this.totalCommunityMobilityOutOf * 100).toFixed(0);


    //
    // TRANSPORTATION NETWORK SERVICES
    //

    this.totalTransportation = this.totalTransportation + this.totalTimeOrientation;
    this.totalTransportation = this.totalTransportation + this.timeCalculation;
    this.totalTransportation = this.totalTransportation + this.totalDirections;
    this.totalTransportation = this.totalTransportation + this.totalTaxi;
    this.percTransportation = (this.totalTransportation / 12 * 100).toFixed(0);

    //
    // DRIVING FITNESS
    //

    this.totalDrivingFitness = this.totalDrivingFitness + this.totalTripPlanning;
    this.totalDrivingFitness = this.totalDrivingFitness + this.totalDrivingAbility;
    this.percDrivingFitness = (this.totalDrivingFitness / 45 * 100).toFixed(0);

    this.column1Perc = +this.percSelfAssessmentData;
    this.column2Perc = +this.percDrivingFitness;

    this.totalDiff = this.column1Perc - this.column2Perc;

    this.barChartData = [
      { data: [this.column1Perc, this.column2Perc, 100],
        label: '',
        backgroundColor: ['#1ab394', '#63666A'],
        hoverBackgroundColor: ['#1ab394', '#63666A'],
        borderColor: 'transparent'
      }
    ];
  }

  getChart(): void {
    const graph = document.getElementById('selfChart');
    if (graph) {
      html2canvas(graph).then(canvas => {
        this.sendURI(canvas.toDataURL('image/png'));
      });
    }
  }

  sendURI(htmlURI: string) {
    this.chartURI = htmlURI;
    this.print();
  }

  print(): void {
    // window.print();
    // get token and fetch data from database
    const id = this.activatedRoute.snapshot.paramMap.get('id');
    const postData: any =  {};
    postData.token = id;
    postData.today = this.today;

    postData.tripPlanningItems = this.tripPlanningItems;
    postData.totalTripPlanning = this.totalTripPlanning;
    postData.totalTripPlanningOutOf = this.totalTripPlanningOutOf;
    postData.percTripPlanning = this.percTripPlanning;

    postData.drivingAbilityItems = this.drivingAbilityItems;
    postData.totalDrivingAbility = this.totalDrivingAbility;
    postData.totalDrivingAbilityOutOf = this.totalDrivingAbilityOutOf;
    postData.percDrivingAbility = this.percDrivingAbility;

    postData.totalDrivingFitness = this.totalDrivingFitness;
    postData.totalDrivingFitnessOutOf = this.totalDrivingFitnessOutOf;
    postData.percDrivingFitness = this.percDrivingFitness;

    postData.selfAssessmentData = this.selfAssessmentData;
    postData.totalSelfAssessmentData = this.totalSelfAssessmentData;
    postData.percSelfAssessmentData = this.percSelfAssessmentData;
    postData.chartURI = this.chartURI;
    postData.column1Perc = this.column1Perc;
    postData.column2Perc = this.column2Perc;
    postData.totalDiff = this.totalDiff;

    postData.totalMemory = this.totalMemory;
    postData.totalTaxi = this.totalTaxi;
    postData.communityItems = this.communityItems;
    postData.totalCommunityMobility = this.totalCommunityMobility;
    postData.totalCommunityMobilityOutOf = this.totalCommunityMobilityOutOf;
    postData.percCommunityMobility = this.percCommunityMobility;

    postData.downloadReport = this.downloadReport;

    postData.toAddress = this.toAddress;
    postData.ccAddress = this.ccAddress;
    postData.participantName = this.participantName;
    postData.assessmentName = this.assessmentName;

    this.assessmentService.generatePDF(postData).subscribe(
      data => {
        this.showEmailBox = false;
        this.downloadPdfFile(data);
      },
      err => {
        console.log('error generatePDF', err);
      }
    );
    

  }

  downloadPdfFile(url: any): void {

   // window.location.assign(url)

   const a = document.createElement("a");
    a.setAttribute('href', url);
    a.setAttribute('target', '_blank');
    a.click();
    //document.body.removeChild(a);

  //   setTimeout(() => {
  //     window.open(url, '_blank');
  // })
  }

  sendEmail(): void {
    this.downloadReport = false;
    this.showEmailBox = false;
    this.emailStatus = 'Email has been sent!';
    this.getChart();
  }

  download(): void {
    this.emailStatus = '';
    this.downloadReport = true;
    this.getChart();
  }
}
