<app-header [showLogout]="false" [showTitle]="false" [showBack]="true" [showResults]="false"></app-header>


<div class="container-new">
    <div class="row">
        <div class="col-xs-12 col-sm-12 text-center">
            <button class="btn btn-primary main" (click)="exportToExcel()">Download Report</button>
        </div>
    </div>
    <br>

    <my-tabs (tabClick)="changeTab($event)">
        <my-tab tabTitle="CoDriS">
        
            <div class="col-md-12 table-responsive" #tab1>
                <h2 style="color: green;cursor: pointer;" (click)="showDemographics = !showDemographics">Demographics </h2>
                <app-demographics [demographics]="demographics" *ngIf="showDemographics"></app-demographics>
            </div>
        
            <div class="col-md-12 table-responsive" #tab2>
                <h2 style="color: green;cursor: pointer;" (click)="showDriving = !showDriving">Driving Fitness Scores</h2>
                <app-driving-fitness [drivingFitness]="drivingFitness" *ngIf="showDriving" [reportType]="reportType"></app-driving-fitness>
            </div>
            
            <div class="col-md-12 table-responsive" #tab3>
                <h2 style="color: green;cursor: pointer;" (click)="showCommunity = !showCommunity">Community Mobility Scores</h2>
                <app-community-mobility [communityMobilities]="communityMobilities" *ngIf="showCommunity"></app-community-mobility>
                
            </div>
            <div class="col-md-12 table-responsive">
                <h2 style="color: green;cursor: pointer;" #tab4 (click)="showTest = !showTest">Test Item Scores</h2>
                <app-test-item [testScores]="testScores" *ngIf="showTest" [reportType]="reportType"></app-test-item>
            </div>
            
            <div class="col-md-12 table-responsive" #tab5>
                <h2 style="color: green;cursor: pointer;" (click)="showRaw = !showRaw">Raw Data</h2>
                <app-raw-data [rawData]="rawData" *ngIf="showRaw" [reportType]="reportType"></app-raw-data>
            </div>
        
            <div class="col-md-12 table-responsive" #tab6>
                <h2 style="color: green;cursor: pointer;" (click)="showTimes = !showTimes">Times</h2>
                <app-times [timeItems]="timeItems" *ngIf="showTimes" [reportType]="reportType"></app-times>
            </div>
        
            <div class="col-md-12 table-responsive" #tab7>
                <h2 style="color: green;cursor: pointer;"  (click)="showTClient = !showTClient">Therapist Client Feedback</h2>
                <table class="table table-bordered" *ngIf="showTClient">
                    <thead class="thead-dark">
                    <tr>
                        <th>Date</th>
                        <th>TherapistRefNo</th>
                        <th>TherapistRefNo</th>
                        <th>Therapist</th>
                        <th>Diagnosis</th>
                        <th>OTP</th>
                        <th>Approach</th>
                        <th>Mood</th>
                        <th>Concentration</th>
                        <th>ProcessingSpeed</th>
                        <th>Insight</th>
                        <th>MocaTotal</th>
                        <th>MocaVisospatial</th>
                        <th>MocaNaming</th>
                        <th>MocaDigit</th>
                        <th>MocaTapping</th>
                        <th>MocaSerial7</th>
                        <th>MocaSentence</th>
                        <th>MocaFluency</th>
                        <th>MocaAbstract</th>
                        <th>MocaMemory</th>
                        <th>MocaOTP</th>
                        <th>MazeT</th>
                        <th>MazeM</th>
                        <th>BellsT</th>
                        <th>BellsM</th>
                        <th>TrialsBT</th>
                        <th>TrailsBM</th>
                        <th>FTDOutcome</th>
                        <th>ClinObs</th>
                    </tr>
                   </thead>
                   <tbody>
                        <tr *ngFor="let item of clientFeedback; let i = index;">
                            <td>{{item.dDateCreated}}</td>
                            <td>{{item.sToken}}</td>
                            <td>{{item.sToken2}}</td>
                            <td>{{item.pseudonym}}</td>
                            <td>{{item.diagnosis}}</td>
                            <td>{{item.orientation}}</td>
                            <td>{{item.approach}}</td>
                            <td>{{item.mood}}</td>
                            <td>{{item.concentration}}</td>
                            <td>{{item.processingspeed}}</td>
                            <td>{{item.insight}}</td>
                            <td>{{item.mocascore}}</td>
                            <td>{{item.MocaVisospatial}}</td>
                            <td>{{item.MocaNaming}}</td>
                            <td>{{item.MocaDigit}}</td>
                            <td>{{item.MocaTapping}}</td>
                            <td>{{item.MocaSerial7}}</td>
                            <td>{{item.MocaSentence}}</td>
                            <td>{{item.MocaFluency}}</td>
                            <td>{{item.MocaAbstract}}</td>
                            <td>{{item.MocaMemory}}</td>
                            <td>{{item.MocaOTP}}</td>
                            <td>{{item.mazetimeinseconds}}</td>
                            <td>{{item.mazenumberofmistakes}}</td>
                            <td>{{item.bellstimeinseconds}}</td>
                            <td>{{item.bellsnumberofmistakes}}</td>
                            <td>{{item.trailsbtimeinseconds}}</td>
                            <td>{{item.trailsnumberofmistakes}}</td>
                            <td>{{item.fitness2}}</td>
                            <td>{{item.clinicalobservation}}</td>
                        </tr>
                   </tbody>
                </table>
            </div>
        
            <div class="col-md-12 table-responsive" #tab8>
                <h2 style="color: green;cursor: pointer;"  (click)="showTTest = !showTTest">Therapist Test Feedback</h2>
                <table class="table table-bordered" *ngIf="showTTest">
                    <thead class="thead-dark">
                    <tr>
                        <th>Date</th>
                        <th>TherapistRefNo</th>
                        <th>ClientRefNo</th>
                        <th>IndRead</th>
                        <th>TherRead</th>
                        <th>DeviceFx</th>
                        <th>TestFeedback</th>
                        <th>GenFeedback</th>
                    </tr>
                   </thead>
                   <tbody>
                    <tr *ngFor="let item of theraphistFeedback; let i = index;">
                        <td>{{item.dDateCreated}}</td>
                        <td>{{item.sToken}}</td>
                        <td>{{item.sToken2}}</td>
                        <td>{{item.readinstructions1}}</td>
                        <td>{{item.readinstructions2}}</td>
                        <td>{{item.operatedevice}}</td>
                        <td>{{item.struggle2}}</td>
                        <td>{{item.otherfeedbackcomment}}</td>
                    </tr>
                   </tbody>
                </table>
            </div>
        </my-tab>
        <my-tab tabTitle="CoDriS Employment">
          
            <div class="col-md-12 table-responsive" #tab1>
                <h2 style="color: green;cursor: pointer;" (click)="showDemographics = !showDemographics">Demographics </h2>
                <app-demographics [demographics]="demographics" *ngIf="showDemographics"></app-demographics>
            </div>
        
            <div class="col-md-12 table-responsive" #tab2>
                <h2 style="color: green;cursor: pointer;" (click)="showDriving = !showDriving">Driving Fitness Scores</h2>
                <app-driving-fitness [drivingFitness]="drivingFitness" *ngIf="showDriving"></app-driving-fitness>
            </div>
            <div class="col-md-12 table-responsive" #tab4>
                <h2 style="color: green;cursor: pointer;" (click)="showTest = !showTest">Test Item Scores</h2>
                <app-test-item [testScores]="testScores" *ngIf="showTest" [reportType]="reportType"></app-test-item>
            </div>
            
            <div class="col-md-12 table-responsive" #tab5>
                <h2 style="color: green;cursor: pointer;" (click)="showRaw = !showRaw">Raw Data</h2>
                <app-raw-data [rawData]="rawData" *ngIf="showRaw" [reportType]="reportType"></app-raw-data>
            </div>
        
            <div class="col-md-12 table-responsive" #tab6>
                <h2 style="color: green;cursor: pointer;" (click)="showTimes = !showTimes">Times</h2>
                <app-times [timeItems]="timeItems" *ngIf="showTimes" [reportType]="reportType"></app-times>
            </div>
        </my-tab>
      </my-tabs>
</div>
    




    

