import { LandMarkSvg3Component } from './pages/fun-cog/land-mark-svg3.component';
import { LandMarkSvg2Component } from './pages/fun-cog/land-mark-svg2.component';
import { ResultsComponent } from './pages/results/results.component';
import { TherapistReportComponent } from './pages/therapist/therapist-report.component';
import { VideoButtons3Component } from './pages/fun-cog/video-buttons.component3';
import { VideoButtons2Component } from './pages/fun-cog/video-buttons.component2';
import { TherapistComponent } from './pages/therapist/therapist.component';
import { TokensComponent } from './pages/tokens/tokens.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { FunCogComponent } from './pages/fun-cog/fun-cog.component';
import { ReportComponent } from './pages/report/report.component';
import { CompleteComponent } from './pages/complete/complete.component';
import { VideoButtonsComponent } from './pages/fun-cog/video-buttons.component';
import { CarInteriorSvgComponent } from './pages/fun-cog/car-interior-svg.components';
import { RoadSignSvgComponent } from './pages/fun-cog/road-sign-svg.component';
import { RoadsideFactorsSvgComponent } from './pages/fun-cog/roadside-factors.component';
import { HomeComponent } from './pages/home/home.component';
import { TherapistCompleteComponent } from './pages/therapist/therapist-complete.component';
import { LandMarkSvgComponent } from './pages/fun-cog/land-mark-svg.component';
import { LoginComponent } from './pages/login/login.component';
import { RegisterComponent } from './pages/register/register.component';
import { ForgotPasswordComponent } from './pages/forgot-password/forgot-password.component';
import { ResetPasswordComponent } from './pages/reset-password/reset-password.component';
import { IntroComponent } from './pages/intro/intro.component';
import { AuthGuard } from './pages/_services/auth.guard';
// import { AuthGuard } from './pages/_services/auth.guard';


const routes: Routes = [
  { path: '', redirectTo: '/intro', pathMatch: 'full'},
  { path: 'home', component: HomeComponent, pathMatch: 'full', canActivate: [AuthGuard]  },
  { path: 'tokens', component: TokensComponent },
  { path: 'funCog/:token', component: FunCogComponent},
  { path: 'therapist', component: TherapistComponent },
  { path: 'trafficSignSvg', component: RoadSignSvgComponent },
  { path: 'roadsideFactorsSvg1', component: RoadsideFactorsSvgComponent },
  { path: 'roadsideFactorsSvg2', component: RoadsideFactorsSvgComponent },
  { path: 'carInteriorSvg', component: CarInteriorSvgComponent },
  { path: 'landmarkSvg', component: LandMarkSvgComponent },
  { path: 'landmarkSvg2', component: LandMarkSvg2Component },
  { path: 'landmarkSvg3', component: LandMarkSvg3Component },
  { path: 'complete/:id', component: CompleteComponent, pathMatch: 'full' },
  { path: 'tcomplete', component: TherapistCompleteComponent },
  { path: 'report/:id', component: ReportComponent, pathMatch: 'full'},
  { path: 'treport', component: TherapistReportComponent },
  { path: 'video1', component: VideoButtonsComponent },
  { path: 'video2', component: VideoButtons2Component },
  { path: 'video3', component: VideoButtons3Component },
  { path: 'results', component: ResultsComponent },
  { path: 'login', component: LoginComponent },
  { path: 'register', component: RegisterComponent },
  { path: 'forgot-password', component: ForgotPasswordComponent },
  { path: 'reset-password/:token', component: ResetPasswordComponent },
  { path: 'intro', component: IntroComponent}

];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {  }
