<div class="container">
    <div class="text-center">
          <img src="./assets/images/logo/GOINGPLACES_B&W-HR.jpg" height="120px">
          <h2 class="heading-title">OCCUPATIONAL THERAPIST FEEDBACK</h2>
    </div>
    
    <div class="row">
        <div class="col-sm-12 div-but">
            <button class="btn btn-primary" (click)="print()">Save</button>
        </div>
    </div>
    <br>

    <div class="row">
        <div class="col-xs-3 col-sm-3"><b>Reference number:</b></div>
        <div class="col-xs-5 col-sm-5"><small>{{token}}</small></div>
        <div class="col-xs-1 col-sm-1"><b>Date:</b></div>
        <div class="col-xs-2 col-sm-2"><small>{{today}}</small></div>
    </div>


    <H3>CLIENT INFORMATION</H3>
    <H3>Demograhics</H3>
    <div class="row">
        <div class="col-xs-12 col-sm-12"><b>Therapist pseudonym:</b></div>
    </div>
    <div class="row">
        <div class="col-xs-12 col-sm-12">{{surveyResults['q_pseudonym']}}</div>
    </div>
    <div class="row">
        <div class="col-xs-12 col-sm-12"><b>Client diagnosis:</b></div>
    </div>
    <div class="row">
        <div class="col-xs-12 col-sm-12">{{surveyResults['q_diagnosis']}}</div>
    </div>

    <H3>Clinical observations</H3>
    <p><b>Based on your clinical observations of the client’s participation during the CDA, rate the following:</b></p>
    <div class="row">
        <div class="col-xs-7 col-sm-7"><b>Orientation to place:</b></div>
        <div class="col-xs-1 col-sm-1">{{surveyResults['q_orientation']}}</div>
    </div>
    <div class="row">
        <div class="col-xs-7 col-sm-7"><b>Approach:</b></div>
        <div class="col-xs-1 col-sm-1">{{surveyResults['q_approach']}}</div>
    </div>
    <div class="row">
        <div class="col-xs-7 col-sm-7"><b>Mood:</b></div>
        <div class="col-xs-1 col-sm-1">{{surveyResults['q_mood']}}</div>
    </div>
    <div class="row">
        <div class="col-xs-7 col-sm-7"><b>Concentration:</b></div>
        <div class="col-xs-1 col-sm-1">{{surveyResults['q_concentration']}}</div>
    </div>
    <div class="row">
        <div class="col-xs-7 col-sm-7"><b>Processing speed:</b></div>
        <div class="col-xs-1 col-sm-1">{{surveyResults['q_processingspeed']}}</div>
    </div>
    <div class="row">
        <div class="col-xs-7 col-sm-7"><b>Insight:</b></div>
        <div class="col-xs-1 col-sm-1">{{surveyResults['q_insight']}}</div>
    </div>

    <H3>Clinical observation comments</H3>
    <p><b>Comment on any other clinical observations you think are relevant.</b></p>
    <div class="row">
        <div class="col-xs-12 col-sm-12">{{surveyResults['q_clinicalobservation']}}</div>
    </div>


    <H3>Other test scores</H3>
    <div class="row">
        <div class="col-xs-4 col-sm-4"><b>MOCA:</b></div>
        <div class="col-xs-1 col-sm-1 score-text"></div>
    </div>
    <div class="row">
        <div class="col-xs-7 col-sm-7">Visuospatial/Executive</div>
        <div class="col-xs-1 col-sm-1 score-text">{{surveyResults['q_moca_visuospatial']}}/5</div>
    </div>
    <div class="row">
        <div class="col-xs-7 col-sm-7">Naming</div>
        <div class="col-xs-1 col-sm-1 score-text">{{surveyResults['q_moca_naming']}}/3</div>
    </div>
    <div class="row">
        <div class="col-xs-7 col-sm-7">Attention: Digits</div>
        <div class="col-xs-1 col-sm-1 score-text">{{surveyResults['q_moca_attention_digits']}}/2</div>
    </div>
    <div class="row">
        <div class="col-xs-7 col-sm-7">Attention: Tapping</div>
        <div class="col-xs-1 col-sm-1 score-text">{{surveyResults['q_moca_attention_tapping']}}/1</div>
    </div>
    <div class="row">
        <div class="col-xs-7 col-sm-7">Attention: Serial 7</div>
        <div class="col-xs-1 col-sm-1 score-text">{{surveyResults['q_moca_attention_serial']}}/3</div>
    </div>
    <div class="row">
        <div class="col-xs-7 col-sm-7">Language: Sentences</div>
        <div class="col-xs-1 col-sm-1 score-text">{{surveyResults['q_moca_language_sentences']}}/2</div>
    </div>
    <div class="row">
        <div class="col-xs-7 col-sm-7">Language: Fluency</div>
        <div class="col-xs-1 col-sm-1 score-text">{{surveyResults['q_moca_language_fluency']}}/1</div>
    </div>
    <div class="row">
        <div class="col-xs-7 col-sm-7">Abstraction</div>
        <div class="col-xs-1 col-sm-1 score-text">{{surveyResults['q_moca_abstraction']}}/2</div>
    </div>
    <div class="row">
        <div class="col-xs-7 col-sm-7">Delayed recall</div>
        <div class="col-xs-1 col-sm-1 score-text">{{surveyResults['q_moca_delayed_recall']}}/5</div>
    </div>
    <div class="row">
        <div class="col-xs-7 col-sm-7">Orientation</div>
        <div class="col-xs-1 col-sm-1 score-text">{{surveyResults['q_moca_orientation']}}/6</div>
    </div>
    <div class="row">
        <div class="col-xs-7 col-sm-7 bold">Total Score</div>
        <div class="col-xs-1 col-sm-1 bold score-text">{{surveyResults['q_mocatotal']}}/30</div>
    </div>
    <br>

    <div class="row">
        <div class="col-xs-7 col-sm-7"><b>Maze: Time in seconds:</b></div>
        <div class="col-xs-1 col-sm-1 score-text">{{surveyResults['q_mazetimeinseconds']}}</div>
    </div>
    <div class="row">
        <div class="col-xs-7 col-sm-7"><b>Maze: Number of mistakes:</b></div>
        <div class="col-xs-1 col-sm-1 bold score-text">{{surveyResults['q_mazenumberofmistakes']}}</div>
    </div>
    <div class="row">
        <div class="col-xs-7 col-sm-7"><b>Bells: Time in seconds:</b></div>
        <div class="col-xs-1 col-sm-1 bold score-text">{{surveyResults['q_bellstimeinseconds']}}</div>
    </div>
    <div class="row">
        <div class="col-xs-7 col-sm-7"><b>Bells: Number of mistakes:</b></div>
        <div class="col-xs-1 col-sm-1 bold score-text">{{surveyResults['q_bellsnumberofmistakes']}}</div>
    </div>
    <div class="row">
        <div class="col-xs-7 col-sm-7"><b>Trails B: Time in seconds:</b></div>
        <div class="col-xs-1 col-sm-1 bold score-text">{{surveyResults['q_trailsbtimeinseconds']}}</div>
    </div>
    <div class="row">
        <div class="col-xs-7 col-sm-7"><b>Trails B: Number of mistakes:</b></div>
        <div class="col-xs-1 col-sm-1 bold score-text">{{surveyResults['q_trailsnumberofmistakes']}}</div>
    </div>

    <H3>Fitness-to-drive assessment outcome</H3>
    <div class="row">
        <div class="col-xs-12 col-sm-12"><b>Once the client has completed the entire fitness to drive assessment what was the outcome of the assessment?</b></div>
    </div>
    <div class="row">
        <div class="col-xs-12 col-sm-12">{{surveyResults['q_fitness2']}}</div>
    </div>
    
    <H3 style="page-break-before: always;">TEST FEEDBACK</H3>
    <H3>Test administration</H3>
    <div class="row">
        <div class="col-xs-7 col-sm-7"><b>Was the client able to read the instructions independently?</b></div>
        <div class="col-xs-1 col-sm-1 bold score-text">{{surveyResults['q_readinstructions1']}}</div>
    </div>
    <div class="row">
        <div class="col-xs-7 col-sm-7"><b>Did you read the instructions to the client?</b></div>
        <div class="col-xs-1 col-sm-1 bold score-text">{{surveyResults['q_readinstructions2']}}</div>
    </div>
    <div class="row">
        <div class="col-xs-7 col-sm-7"><b>Was the client able to operate the electronic device functions independently?</b></div>
        <div class="col-xs-1 col-sm-1 bold score-text">{{surveyResults['q_operatedevice']}}</div>
    </div>

    <H3>Test items</H3>
    <div class="row">
        <div class="col-xs-12 col-sm-12"><b>Was the client able to understand the questions and instructions?</b></div>
    </div>
    <div class="row">
        <div class="col-xs-12 col-sm-12">{{surveyResults['q_understandquestions']}}</div>
    </div><br>
    <div class="row">
        <div class="col-xs-12 col-sm-12"><b>Which questions did they struggle with?</b></div>
    </div>
    <div class="row">
        <div class="col-xs-12 col-sm-12">{{surveyResults['q_struggle2']}}</div>
    </div><br>
    <div class="row">
        <div class="col-xs-12 col-sm-12"><b>How can the question be improved to enhance the client’s understanding?</b></div>
    </div>
    <div class="row">
        <div class="col-xs-12 col-sm-12">{{surveyResults['q_questionimprove']}}</div>
    </div>
    
    <H3>Other feedback you think may be helpful</H3>
    <div class="row">
        <div class="col-xs-12 col-sm-12">{{surveyResults['q_otherfeedbackcomment']}}</div>
    </div>
    <br>

    <div class="row">
        <div class="col-sm-12 div-but">
            <button class="btn btn-primary" (click)="print()">Save</button>
        </div>
    </div>
    <br>
   

    
</div>