import { Component, OnInit, OnDestroy } from '@angular/core';
import { ApiService } from 'src/app/api.service';

@Component({
  selector: 'app-roadside-factor-svg',
  templateUrl: './roadside-factors-svg.component.svg',
  styleUrls: ['./fun-cog.component.css']
})
export class RoadsideFactorsSvgComponent implements OnInit, OnDestroy {

  constructor(private apiService: ApiService){}

  dayTime = 'transparent';
  buildings = 'transparent';
  trafficLights = 'transparent';
  pedestrian = 'transparent';
  carsOnSide = 'transparent';
  interSection = 'transparent';
  onComingTraffic = 'transparent';
  trafficSign1 = 'transparent';
  rightArrow = 'transparent';
  infoSign = 'transparent';
  roadArrow = 'transparent';
  // picknpaySign = 'transparent';
  // parkingSign = 'transparent';
  // sign1 = 'transparent';
  // sign2 = 'transparent';
  // sign3 = 'transparent';
  // sign4 = 'transparent';
  trees = 'transparent';
  silvercar = 'transparent';
  signs = 'transparent';
  tarroad = 'transparent';

  arrRoadSideClicks: string[] = [];

  ngOnInit(): void {
    localStorage.setItem('arrRoadSideClicks', '');
  }

  ngOnDestroy(): void {
  }

  clickImageArea(sName: string): void {

    const blockColor = '#1ab394';
    let bAdd = true;
    // const blockColor = 'red';

    // console.log('svg: clickImageArea', sName);
    // this.apiService.roadSideClicks(sName);

    if (sName === 'Day Time') {
      this.dayTime =  (this.dayTime === 'transparent' ? blockColor : 'transparent');
      bAdd = (this.dayTime === 'transparent' ? false : true);
    } else if (sName === 'Buildings') {
      this.buildings = (this.buildings === 'transparent' ? blockColor : 'transparent');
      bAdd = (this.buildings === 'transparent' ? false : true);
    } else if (sName === 'Traffic Light') {
      this.trafficLights = (this.trafficLights === 'transparent' ? blockColor : 'transparent');
      bAdd = (this.trafficLights === 'transparent' ? false : true);
    } else if (sName === 'Pedestrian') {
      this.pedestrian = (this.pedestrian === 'transparent' ? blockColor : 'transparent');
      bAdd = (this.pedestrian === 'transparent' ? false : true);
    } else if (sName === 'Cars On Side') {
      this.carsOnSide = (this.carsOnSide === 'transparent' ? blockColor : 'transparent');
      bAdd = (this.carsOnSide === 'transparent' ? false : true);
    } else if (sName === 'Intersection') {
      this.interSection = (this.interSection === 'transparent' ? blockColor : 'transparent');
      bAdd = (this.interSection === 'transparent' ? false : true);
    } else if (sName === 'On Coming Traffic') {
      this.onComingTraffic = (this.onComingTraffic === 'transparent' ? blockColor : 'transparent');
      bAdd = (this.onComingTraffic === 'transparent' ? false : true);
    } else if (sName === 'Traffic Sign') {
      this.trafficSign1 = (this.trafficSign1 === 'transparent' ? blockColor : 'transparent');
      bAdd = (this.trafficSign1 === 'transparent' ? false : true);
    } else if (sName === 'Right Arrow') {
      this.rightArrow = (this.rightArrow === 'transparent' ? blockColor : 'transparent');
      bAdd = (this.rightArrow === 'transparent' ? false : true);
    } else if (sName === 'Info Sign') {
      this.infoSign = (this.infoSign === 'transparent' ? blockColor : 'transparent');
      bAdd = (this.infoSign === 'transparent' ? false : true);
    } else if (sName === 'Road Arrow') {
      this.roadArrow = (this.roadArrow === 'transparent' ? blockColor : 'transparent');
      bAdd = (this.roadArrow === 'transparent' ? false : true);
    } else if (sName === 'signs') {
      this.signs = (this.signs === 'transparent' ? blockColor : 'transparent');
      bAdd = (this.signs === 'transparent' ? false : true);
    } else if (sName === 'Trees') {
      this.trees = (this.trees === 'transparent' ? blockColor : 'transparent');
      bAdd = (this.trees === 'transparent' ? false : true);
    } else if (sName === 'Silver Car') {
      this.silvercar = (this.silvercar === 'transparent' ? blockColor : 'transparent');
      bAdd = (this.silvercar === 'transparent' ? false : true);
    } else if (sName === 'Tar Road') {
      this.tarroad = (this.tarroad === 'transparent' ? blockColor : 'transparent');
      bAdd = (this.tarroad === 'transparent' ? false : true);
    }

    if (bAdd) {
      this.arrRoadSideClicks.indexOf(sName) === -1 ? this.arrRoadSideClicks.push(sName) : console.log('This item already exists');
    } else {
        const arrIndex = this.arrRoadSideClicks.indexOf(sName);
        if (arrIndex > -1) {
            this.arrRoadSideClicks.splice(arrIndex, 1);
        }
    }

    localStorage.setItem('arrRoadSideClicks', JSON.stringify(this.arrRoadSideClicks));
  }

}
