
<app-header [showLogout]="false" [showTitle]="true" [showDescription]=true></app-header>
<div class="col-md-12">
  <div class="card card-container">
    <form
      *ngIf="!isLoggedIn"
      name="form"
      (ngSubmit)="f.form.valid && onSubmit()"
      #f="ngForm"
      novalidate
    >
      <div class="form-group">
        <label for="username">Email</label>
        <input
          type="text"
          class="form-control"
          name="username"
          [(ngModel)]="form.username"
          required
          #username="ngModel"
        />
        <div
          class="alert alert-danger"
          role="alert"
          *ngIf="username.errors && f.submitted"
        >
          Email is required
        </div>
      </div>
      <div class="form-group">
        <label for="password">Password</label>
        <input
          type="password"
          class="form-control"
          name="password"
          [(ngModel)]="form.password"
          required
          minlength="6"
          #password="ngModel"
        />
        <div
          class="alert alert-danger"
          role="alert"
          *ngIf="password.errors && f.submitted"
        >
          <div *ngIf="password.errors.required">Password is required</div>
          <div *ngIf="password.errors.minlength">
            Password must be at least 6 characters
          </div>
        </div>
      </div>
      <div class="form-group text-center">
        <button class="btn btn-primary btn-block">
          Login
        </button>
        <button class="btn btn-link" (click)="goToRegister()">Sign Up</button>
        <button class="btn btn-link" (click)="goToResetPassword()">Reset password</button>
      </div>
      <div class="form-group">
        <div
          class="alert alert-danger"
          role="alert"
          *ngIf="f.submitted && isLoginFailed"
        >
          Login failed: {{ errorMessage }}<br><br>
          Please make sure you are registered on the system before logging in.
        </div>
      </div>
    </form>
  </div>
</div>

<app-footer></app-footer>

