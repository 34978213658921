import { Component, Input } from '@angular/core';
import { IDemographics } from '../results.component';

@Component({
  selector: 'app-demographics',
  styles: [
    `
    .pane{
      padding: 1em;
    }
  `
  ],
  template: `
    <table class="table table-bordered" border="1" style="visibility: visible;">
        <thead class="thead-dark">
        <tr>
            <th>Date</th>
            <th>ClientRefNo</th>
            <th>Consent</th>
            <th>Gender</th>
            <th>Age</th>
            <th>English</th>
            <th>Schooling</th>
            <th>PostSchooling</th>
        </tr>
        </thead>
        <tbody>
            <tr *ngFor="let item of demographics; let i = index;">
                <td>{{item.dDateCreated}}</td>
                <td>{{item.sToken}}</td>
                <td>{{item.consent}}</td>
                <td>{{item.gender}}</td>
                <td>{{item.age}}</td>
                <td>{{item.english}}</td>
                <td>{{item.scooling}}</td>
                <td>{{item.education}}</td>
            </tr>
        </tbody>
    </table>
  `
})
export class DemographicsComponent {
  @Input() demographics: IDemographics[] = [];
}
