import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})

export class CalculationService {


    constructor() {
    }

    timeOrientationHandler(timeYear: number,
                           timeHiddenDateTime: Date,
                           timeNoLookHour: number,
                           timeNoLookMinute: number,
                           timeLookHour: number,
                           timeLookMinute: number,
                           timeMonth: number,
                           timeWeekDay: number,
                           timeDay: number,
                           question: string): number {

        // tslint:disable-next-line:max-line-length
        // console.log('time args', timeYear, timeHiddenDateTime, timeNoLookHour, timeNoLookMinute, timeLookHour, timeLookMinute, timeMonth, timeWeekDay, timeDay);
        let totalTimeOrientation = 0;
        let q1 = 0;
        let q2 = 0;
        let q3 = 0;
        let q4 = 0;
        let q5 = 0;
        let q6 = 0;

        if (timeYear !== 0) {
            const scoreDate = timeHiddenDateTime;
            const year = timeHiddenDateTime.getFullYear();
            const month = timeHiddenDateTime.getMonth();
            const day = timeHiddenDateTime.getDay();
            const day2 = timeHiddenDateTime.getDate();

            // tslint:disable-next-line:max-line-length
            const noLookDate: Date = new Date(scoreDate.getFullYear(), scoreDate.getMonth(), scoreDate.getDate(), timeNoLookHour, timeNoLookMinute);
            const noLookDateDiff = this.diff_minutes(noLookDate, scoreDate);

            // tslint:disable-next-line:max-line-length
            const lookDate: Date = new Date(scoreDate.getFullYear(), scoreDate.getMonth(), scoreDate.getDate(), timeLookHour, timeLookMinute);
            const lookDateDiff = this.diff_minutes(lookDate, scoreDate);

            // console.log('timeOrientation year', timeYear.toString(), year.toString());
            // console.log('timeOrientation month', timeMonth.toString(), month.toString());
            // console.log('timeOrientation day', timeWeekDay.toString(), day.toString());
            // console.log('timeOrientation day', timeDay.toString(), day2.toString());
            // console.log('timeOrientation noLookDateDiff', noLookDateDiff);
            // console.log('timeOrientation lookDateDiff', lookDateDiff);

            if (timeYear.toString() === year.toString()) {
                totalTimeOrientation = totalTimeOrientation + 0.5;
                q1 = 0.5;
            }
            if (timeMonth.toString() === month.toString()) {
                totalTimeOrientation = totalTimeOrientation + 0.5;
                q2 = 0.5;
            }
            if (timeWeekDay.toString() === day.toString()) {
                totalTimeOrientation = totalTimeOrientation + 0.5;
                q3 = 0.5;
            }
            if (timeDay.toString() === day2.toString()) {
                totalTimeOrientation = totalTimeOrientation + 0.5;
                q4 = 0.5;
            }
            if (noLookDateDiff <= 120) {
                totalTimeOrientation = totalTimeOrientation + 0.5;
                q5 = 0.5;
            }
            if (lookDateDiff <= 10) {
                totalTimeOrientation = totalTimeOrientation + 0.5;
                q6 = 0.5;
            }
        }
        if (question === 'q1') {
          return q1;
        } else if (question === 'q2') {
          return q2;
        } else if (question === 'q3') {
          return q3;
        } else if (question === 'q4') {
          return q4;
        } else if (question === 'q5') {
          return q5;
        } else if (question === 'q6') {
          return q6;
        } else {
          return totalTimeOrientation;
        }
    }

    diff_minutes(dt2: Date, dt1: Date): any {
    let diff = (dt2.getTime() - dt1.getTime()) / 1000;
    diff /= 60;
    return Math.abs(Math.round(diff));
    }

    timeCalculationHandler(answer: string): number {
    let result = 0;
    if (answer === '9h10') {
        result = 1;
    }
    return result;
    }

    moneyParkingHandler(answer: number): number {
    let result = 0;
    if (answer === 30) {
        result = 1;
    }
    return result;
    }

    moneyChangeHandler(answer: number): number {
    let result = 0;
    if (answer === 20) {
        result = 1;
    }
    return result;
    }

    fuelCalculationHandler(answer: string): number {
    let result = 0;
    if (answer === 'I have to fill up my car on my way to work.') {
        result = 1;
    }
    return result;
    }

    directionHandler(key: string, answer: string): number {
    let result = 0;
    if (key === 'DIRECTION1' && answer === 'third') {
        result = 1;
    }
    if (key === 'DIRECTION2' && answer === 'right') {
        result = 1;
    }
    if (key === 'DIRECTION3' && answer === 'firstleft') {
        result = 1;
    }
    if (key === 'DIRECTION4' && answer === 'postoffice') {
        result = 1;
    }
    return result;
    }

    identifySignHandler(key: string, answer: string): number {
    let result = 0;
    if (key === 'IDENTIFY_PEDESTRIAN' && answer === 'pedestrian1') {
        result = 1;
    }
    if (key === 'IDENTIFY_STOP' && answer === 'stop2') {
        result = 1;
    }
    if (key === 'IDENTIFY_CIRCLE' && answer === 'circle3') {
        result = 1;
    }
    if (key === 'IDENTIFY_RIGHT_TURN' && answer === 'norighturn4') {
        result = 1;
    }
    return result;
    }

    memory2Handler(key: string, value: string): number {
      let result = 0;

      if (key === 'MEMORY2_DIRECTION1' && value === 'third') {
          result = 0.5;
      }
      if (key === 'MEMORY2_DIRECTION2' && value === 'right') {
          result = 0.5;
      }
      if (key === 'MEMORY2_DIRECTION3' && value === 'firstleft') {
          result = 0.5;
      }
      if (key === 'MEMORY2_DIRECTION4' && value === 'postoffice') {
          result = 0.5;
      }
      return result;
    }

    getVisualMemory(arrMemorySigns: any[]): number {
      let result = 0;

      let totalCorrect = 0;
      let totalInCorrect = 0;

      // totalIdentifySigns
      // tslint:disable-next-line:prefer-for-of
      for (let i = 0; i < arrMemorySigns.length; i++) {
        // correct
        if (arrMemorySigns[i] === 'stop') {
            totalCorrect = totalCorrect + 0.5;
        }
        if (arrMemorySigns[i] === 'pedestrianCrossing') {
            totalCorrect = totalCorrect + 0.5;
        }
        if (arrMemorySigns[i] === 'circle') {
            totalCorrect = totalCorrect + 0.5;
        }
        if (arrMemorySigns[i] === 'rightTurn') {
            totalCorrect = totalCorrect + 0.5;
        }
        // incorrect
        if (arrMemorySigns[i] === 'noEntry') {
            totalInCorrect = totalInCorrect + 0.5;
        }
        if (arrMemorySigns[i] === 'trafficLight') {
            totalInCorrect = totalInCorrect + 0.5;
        }
        if (arrMemorySigns[i] === 'noParking') {
            totalInCorrect = totalInCorrect + 0.5;
        }
        if (arrMemorySigns[i] === 'leftTurn') {
            totalInCorrect = totalInCorrect + 0.5;
        }
      }

      if (arrMemorySigns.length > 4) {
        if (totalCorrect == 2) {
          result = totalCorrect - totalInCorrect;
        } else {
          result = totalCorrect;
        }
      } else {
        result = totalCorrect;
      }
      // console.log('arrMemorySigns', arrMemorySigns);
      // console.log('totalCorrect', totalCorrect);
      // console.log('totalInCorrect', totalInCorrect);
      // console.log('result', result);
      // console.log('-----------------');
      return result;
    }

    identifyMemorySignsHandler(arrMemorySigns: any[], totalMemory2: number): number {
      let result = 0;

      let totalCorrect = 0;
      let totalInCorrect = 0;

      // totalIdentifySigns
      // tslint:disable-next-line:prefer-for-of
      for (let i = 0; i < arrMemorySigns.length; i++) {
      // correct
      if (arrMemorySigns[i] === 'stop') {
          totalCorrect = totalCorrect + 1;
      }
      if (arrMemorySigns[i] === 'pedestrianCrossing') {
          totalCorrect = totalCorrect + 1;
      }
      if (arrMemorySigns[i] === 'circle') {
          totalCorrect = totalCorrect + 1;
      }
      if (arrMemorySigns[i] === 'rightTurn') {
          totalCorrect = totalCorrect + 1;
      }
      // incorrect
      if (arrMemorySigns[i] === 'noEntry') {
          totalInCorrect = totalInCorrect + 1;
      }
      if (arrMemorySigns[i] === 'trafficLight') {
          totalInCorrect = totalInCorrect + 1;
      }
      if (arrMemorySigns[i] === 'noParking') {
          totalInCorrect = totalInCorrect + 1;
      }
      if (arrMemorySigns[i] === 'leftTurn') {
          totalInCorrect = totalInCorrect + 1;
      }
      }

      if (totalCorrect === 0) {
          result = 0;
      } else if (totalCorrect === 1) {
          result = 0.5;
      } else if (totalCorrect === 2) {
          result = 1;
      } else if (totalCorrect === 3) {
          result = 1.5;
      } else if (totalCorrect === 4) {
          result = 2;
      }

      if (totalCorrect === 4 && totalInCorrect === 4) {
          result =  0;
      }
      if (totalCorrect === 4 && totalInCorrect === 3) {
          result = 0.5;
      }
      if (totalCorrect === 4 && totalInCorrect === 2) {
          result = 1;
      }
      if (totalCorrect === 4 && totalInCorrect === 1) {
          result = 1.5;
      }
      if (totalCorrect === 4 && totalInCorrect === 0) {
          result = 2;
      }
      // add memory 2 to memory 1
      result = result + totalMemory2;

      return result;
    }

    findRoadTrafficSignHandler(arrRoadSignClicks: any[]): number {
      // changed with CDA updates 04.08.23 
      let totalFindRoadTrafficSign  = 0;
      let nineSigns = 9;

      let totalNoEntries: number = arrRoadSignClicks.filter(x => x.indexOf('NOENTRY') > -1).length;
      let totalOther: number = arrRoadSignClicks.filter(x => x.indexOf('NOENTRY') === -1).length;

      if (totalOther === 0 && totalNoEntries === 9) {
          totalFindRoadTrafficSign = 3;
      } else {

        var myNewScoreNoEntries = ((nineSigns - totalNoEntries) + totalOther) * 0.5;
        totalFindRoadTrafficSign = 3 - myNewScoreNoEntries;
      }
      return totalFindRoadTrafficSign < 1 ? 0 : totalFindRoadTrafficSign;


        // let totalFindRoadTrafficSign  = 0;

        // const totalNoEntries = arrRoadSignClicks.filter(x => x.indexOf('NOENTRY') > -1).length;
        // const totalOther = arrRoadSignClicks.filter(x => x.indexOf('NOENTRY') === -1).length;

        // if (totalOther === 0 && totalNoEntries === 9) {
        //     totalFindRoadTrafficSign = 3;
        // } else {
        //     if ((totalNoEntries === 8 && totalOther === 0) || (totalNoEntries === 9 && totalOther === 1)) {
        //     totalFindRoadTrafficSign = 2;
        //     } else {
        //     if ((totalNoEntries === 7 && totalOther === 0 ) ||
        //         (totalNoEntries === 8 && totalOther === 1 ) ||
        //         (totalNoEntries === 9 && totalOther === 2 )){
        //         totalFindRoadTrafficSign = 1;
        //     }
        //     }
        // }
        // return totalFindRoadTrafficSign;
    }

    intersectionHandler(intersectionA: string, intersectionB: string,
                        intersectionC: string, intersectionD: string, question: string = ''): number {
        let totalIntersections =  0;

        if (question === '') {
          if (intersectionA === 'BA') {
            totalIntersections = totalIntersections + 1;
          }
          if (intersectionB === 'BAC') {
            totalIntersections = totalIntersections + 1;
          }
          if (intersectionC === 'CAB') {
            totalIntersections = totalIntersections + 1;
          }
          if (intersectionD === 'BA') {
            totalIntersections = totalIntersections + 1;
          }
        } else if (question === 'q1') {
          if (intersectionA === 'BA') {
            totalIntersections = totalIntersections + 1;
          }
        } else if (question === 'q2') {
          if (intersectionB === 'BAC') {
            totalIntersections = totalIntersections + 1;
          }
        } else if (question === 'q3') {
          if (intersectionC === 'CAB') {
            totalIntersections = totalIntersections + 1;
          }
        } else if (question === 'q4') {
          if (intersectionD === 'BA') {
            totalIntersections = totalIntersections + 1;
          }
        }
        return totalIntersections;
    }

    landmarkHandler(key: string, value: string): number {
        let result = 0;
        if (key === 'LANDMARK_q1' && (value === 'Shop 3' || value === 'shop3')) {
            result = 1;
        }
        if (key === 'LANDMARK_q2' && (value === 'Go over a bridge, crossing a river.' || value === 'shop1')) {
            result = 1;
        }
        if (key === 'LANDMARK_q3' && (value === 'Restaurant 2' || value === 'restaurant2')) {
            result = 1;
        }
        // console.log('landmarkHandler', key, value, result);
        return result;
    }

    roadRulesHandler(selectedItems: any[], question: string = ''): number {
        let totalRoadRules = 0;

        // console.log('roadRulesHandler1', selectedItems);

        // tslint:disable-next-line:prefer-for-of
        for (let i = 0; i < selectedItems.length; i++) {
            if (question === '') {

              if (selectedItems[i][0] === 'Keep right, pass left.' && selectedItems[i][1] === 0) {
                totalRoadRules = totalRoadRules + 1;
              }
              if ((selectedItems[i][0] === 'When I overtake a car, I donu2019t have to use my indicator.' ||  selectedItems[i][0] === 'When I overtake a car, I don’t have to use my indicator.') && selectedItems[i][1] === 0) {
                totalRoadRules = totalRoadRules + 1;
              }
              if (selectedItems[i][0] === 'When I change lanes, I must check my rear and side view mirrors.' && selectedItems[i][1] === 1) {
                totalRoadRules = totalRoadRules + 1;
              }
              if (selectedItems[i][0] === 'When I arrive first at a 4 way stop, I have right of way.' && selectedItems[i][1] === 1) {
                totalRoadRules = totalRoadRules + 1;
              }
            } else if (question === 'q1') {
              if (selectedItems[i][0] === 'Keep right, pass left.' && selectedItems[i][1] === 0) {
                totalRoadRules = totalRoadRules + 1;
              }
            } else if (question === 'q2') {
              if ((selectedItems[i][0] === 'When I overtake a car, I donu2019t have to use my indicator.' ||  selectedItems[i][0] === 'When I overtake a car, I don’t have to use my indicator.') && selectedItems[i][1] === 0) {
                totalRoadRules = totalRoadRules + 1;
              }
            } else if (question === 'q3') {
              if (selectedItems[i][0] === 'When I change lanes, I must check my rear and side view mirrors.' && selectedItems[i][1] === 1) {
                totalRoadRules = totalRoadRules + 1;
              }
            } else if (question === 'q4') {
              if (selectedItems[i][0] === 'When I arrive first at a 4 way stop, I have right of way.' && selectedItems[i][1] === 1) {
                totalRoadRules = totalRoadRules + 1;
              }
            }
          }

        // console.log('roadRulesHandler2', totalRoadRules);
        return totalRoadRules;
    }

    roadSideFactorsHandler(arrRoadSideClicks: any[]): number {
        let pictureClicks = 0;

        if (arrRoadSideClicks.indexOf('Silver Car') > -1 && pictureClicks < 3) {
          pictureClicks = pictureClicks + 1;
        }
        if (arrRoadSideClicks.indexOf('Buildings') > -1 && pictureClicks < 3) {
          pictureClicks = pictureClicks + 1;
        }
        if (arrRoadSideClicks.indexOf('Pedestrian') > -1 && pictureClicks < 3) {
          pictureClicks = pictureClicks + 1;
        }
        if (arrRoadSideClicks.indexOf('Traffic Light') > -1 && pictureClicks < 3) {
          pictureClicks = pictureClicks + 1;
        }
        if (arrRoadSideClicks.indexOf('Cars On Side') > -1 && pictureClicks < 3) {
          pictureClicks = pictureClicks + 1;
        }
        if (arrRoadSideClicks.indexOf('On Coming Traffic') > -1 && pictureClicks < 3) {
          pictureClicks = pictureClicks + 1;
        }
        if (arrRoadSideClicks.indexOf('Road Arrow') > -1 && pictureClicks < 3) {
          pictureClicks = pictureClicks + 1;
        }
        if (arrRoadSideClicks.indexOf('Day Time') > -1 && pictureClicks < 3) {
          pictureClicks = pictureClicks + 1;
        }
        if (arrRoadSideClicks.indexOf('Trees') > -1 && pictureClicks < 3) {
          pictureClicks = pictureClicks + 1;
        }
        if (arrRoadSideClicks.indexOf('Intersection') > -1 && pictureClicks < 3) {
          pictureClicks = pictureClicks + 1;
        }
        if (arrRoadSideClicks.indexOf('signs') > -1 && pictureClicks < 3) {
          pictureClicks = pictureClicks + 1;
        }
        if (arrRoadSideClicks.indexOf('Tar Road') > -1 && pictureClicks < 3) {
          pictureClicks = pictureClicks + 1;
        }
        return pictureClicks;
      }

      dividedAttentionHandler(video2: any, video3: any, type: string, question: string = ''): number {

        // This is my suggestion. Each button is worth 1.5 points per video i.e a total of 3 for both videos.
        // Therefore per video each button is attributed:
        // (Mistakes are - or + than the correct number)
        // 0-1 mistake  = 1.5
        // 2 mistakes = 1
        // 3 mistakes = 0.5
        // 4 or more mistakes = 0
        // video 2 (left 13 and right 24)
        // video 3 (left 17 and right 23)
        let answer = 0;

        const total2Left = 13 - video2.leftClicks;
        const total2Right = 24 - video2.rightClicks;

        const total3Left = 17 - video3.leftClicks;
        const total3Right = 23 - video3.rightClicks;

        let totalVideoLeftToRightClicks2 = 0;
        let totalVideoLeftToRightClicks3 = 0;
        let totalVideoRightToLeftClicks2 = 0;
        let totalVideoRightToLeftClicks3 = 0;

        // left clicks
        if (video2.leftClicks === 12 || video2.leftClicks === 13 || video2.leftClicks === 14) {
          totalVideoLeftToRightClicks2 =  totalVideoLeftToRightClicks2 + 1.5;
        }

        if (video2.leftClicks === 11 || video2.leftClicks === 15) {
          totalVideoLeftToRightClicks2 =  totalVideoLeftToRightClicks2 + 1;
        }

        if (video2.leftClicks === 10 || video2.leftClicks === 16) {
          totalVideoLeftToRightClicks2 =  totalVideoLeftToRightClicks2 + 0.5;
        }

        if (video3.leftClicks === 16 || video3.leftClicks === 17 || video3.leftClicks === 18) {
          totalVideoLeftToRightClicks3 =  totalVideoLeftToRightClicks3 + 1.5;
        }

        if (video3.leftClicks === 15 || video3.leftClicks === 19) {
          totalVideoLeftToRightClicks3 =  totalVideoLeftToRightClicks3 + 1;
        }

        if (video3.leftClicks === 14 || video3.leftClicks === 20) {
          totalVideoLeftToRightClicks3 =  totalVideoLeftToRightClicks3 + 0.5;
        }

        // right clicks
        if ((video2.rightClicks === 23 || video2.rightClicks === 24 || video2.rightClicks === 25)) {
            totalVideoRightToLeftClicks2 = totalVideoRightToLeftClicks2 + 1.5;
        }

        if ((video2.rightClicks === 22 || video2.rightClicks === 26)) {
          totalVideoRightToLeftClicks2 = totalVideoRightToLeftClicks2 + 1;
        }

        if ((video2.rightClicks === 21 || video2.rightClicks === 27)) {
          totalVideoRightToLeftClicks2 = totalVideoRightToLeftClicks2 + 0.5;
        }

        if ((video3.rightClicks === 22 || video3.rightClicks === 23 || video3.rightClicks === 24)) {
          totalVideoRightToLeftClicks3 = totalVideoRightToLeftClicks3 + 1.5;
        }

        if ((video3.rightClicks === 21 || video3.rightClicks === 25)) {
          totalVideoRightToLeftClicks3 = totalVideoRightToLeftClicks3 + 1;
        }

        if ((video3.rightClicks === 20 || video3.rightClicks === 26)) {
          totalVideoRightToLeftClicks3 = totalVideoRightToLeftClicks3 + 0.5;
        }

        if (type === 'right') {
          if (question === '') {
            answer = totalVideoRightToLeftClicks2 + totalVideoRightToLeftClicks3;
          } else if (question === 'q1') {
            answer = totalVideoRightToLeftClicks2;
          } else if (question === 'q2') {
            answer = totalVideoRightToLeftClicks3;
          }
        } else {
          if (question === '') {
            answer = totalVideoLeftToRightClicks2 + totalVideoLeftToRightClicks3;
          } else if (question === 'q1') {
            answer = totalVideoLeftToRightClicks2;
          } else if (question === 'q2') {
            answer = totalVideoLeftToRightClicks3;
          }
        }
        return answer;
      }

      drivingManoeuvreHandler(arrCarInteriorClicks: any[]): number {

        let totalDrivingManoeuvre = 0;
        let totalCorrect = 0;
        let totalIncorrect = 0;
        let localCalc = 0;

        // add
        if (arrCarInteriorClicks.indexOf('Turn Signal Indicator') > -1) {
          totalCorrect = totalCorrect + 1;
        }
        if (arrCarInteriorClicks.indexOf('Rear Mirror') > -1) {
          totalCorrect = totalCorrect + 0.5;
        }
        if (arrCarInteriorClicks.indexOf('Side Mirrors') > -1) {
          totalCorrect = totalCorrect + 0.5;
        }
        if (arrCarInteriorClicks.indexOf('Steering Wheel') > -1) {
          totalCorrect = totalCorrect + 0.5;
        }
        if (arrCarInteriorClicks.indexOf('Petrol') > -1 ||
            arrCarInteriorClicks.indexOf('Break') > -1 ||
            arrCarInteriorClicks.indexOf('Clutch') > -1) {
          totalCorrect = totalCorrect + 0.5;
        }

        // subtract
        if (arrCarInteriorClicks.indexOf('Hand Break') > -1) {
          totalIncorrect = totalIncorrect + 1;
        }
        if (arrCarInteriorClicks.indexOf('Windscreen Wipers') > -1) {
          totalIncorrect = totalIncorrect + 1;
        }
        if (arrCarInteriorClicks.indexOf('Wiper Leaver') > -1) {
          totalIncorrect = totalIncorrect + 1;
        }
        if (arrCarInteriorClicks.indexOf('Left Door Handle') > -1) {
          totalIncorrect = totalIncorrect + 1;
        }
        if (arrCarInteriorClicks.indexOf('Right Door Handle') > -1) {
          totalIncorrect = totalIncorrect + 1;
        }
        if (arrCarInteriorClicks.indexOf('Screen') > -1) {
          // totalIncorrect = totalIncorrect + 1;
        }
        if (arrCarInteriorClicks.indexOf('Emergency Flasher') > -1) {
          totalIncorrect = totalIncorrect + 1;
        }
        if (arrCarInteriorClicks.indexOf('Glove Box') > -1) {
          totalIncorrect = totalIncorrect + 1;
        }
        if (arrCarInteriorClicks.indexOf('Dashboard') > -1) {
          totalIncorrect = totalIncorrect + 1;
        }
        if (arrCarInteriorClicks.indexOf('Middle Vent') > -1 ||
            arrCarInteriorClicks.indexOf('Side Vent') > -1 ||
            arrCarInteriorClicks.indexOf('Top Vent') > -1) {
              totalIncorrect = totalIncorrect + 1;
        }

        if (totalIncorrect >= 3) {
          localCalc = 0;
        } else {
          localCalc = totalCorrect - totalIncorrect;
        }

        if (localCalc < 0) {
          localCalc = 0;
        }

        totalDrivingManoeuvre = totalDrivingManoeuvre + localCalc;
        return totalDrivingManoeuvre;
      }

      selfAssessmentHandler(arr: any[] = [], sType: string, question: string = ''): number {
        let totalSelfAssessmentData = 0;
        let answer = '';
        let q = 0;
        // console.log('selfAssessmentHandler', arr, sType, question);

        if (sType === 'SELF_ASSESSMENT_active_driver') {
          if (question === '') {
            // tslint:disable-next-line:prefer-for-of
            for (let i = 0; i < arr.length; i++) {
               if (arr[i][1] === 1) {
                 answer = 'Sometimes';
                 totalSelfAssessmentData = totalSelfAssessmentData + 1;
               } else if (arr[i][1] === 2) {
                 answer = 'Always';
               } else {
                 answer = 'Never';
                 totalSelfAssessmentData = totalSelfAssessmentData + 2;
               }
             }
           } else {
            if (question === 'q1' && arr.length >= 1) {
              q = 0;
            } else if (question === 'q2' && arr.length >= 2) {
              q = 1;
            } else if (question === 'q3' && arr.length >= 3) {
              q = 2;
            } else if (question === 'q4' && arr.length >= 4) {
              q = 3;
            } else if (question === 'q5' && arr.length >= 5) {
              q = 4;
            } else if (question === 'q6' && arr.length >= 6) {
              q = 5;
            } else if (question === 'q7' && arr.length >= 7) {
              q = 6;
            }

            if (arr[q][1] === 1) {
              answer = 'Sometimes';
              totalSelfAssessmentData = totalSelfAssessmentData + 1;
            } else if (arr[q][1] === 2) {
              answer = 'Always';
            } else {
              answer = 'Never';
              totalSelfAssessmentData = totalSelfAssessmentData + 2;
            }
        }
        }
        if (sType === 'SELF_ASSESSMENT_non_active_driver') {

          if (question === '') {
           // tslint:disable-next-line:prefer-for-of
           for (let i = 0; i < arr.length; i++) {
            if (arr[i][1] === 1) {
              answer = 'Maybe';
              totalSelfAssessmentData = totalSelfAssessmentData + 1;
            } else if (arr[i][1] === 2) {
              answer = 'Yes';
            } else {
              answer = 'No';
              totalSelfAssessmentData = totalSelfAssessmentData + 2;
            }
           }
          } else {
            if (question === 'q1' && arr.length >= 1) {
              q = 0;
            } else if (question === 'q2' && arr.length >= 2) {
              q = 1;
            } else if (question === 'q3' && arr.length >= 3) {
              q = 2;
            } else if (question === 'q4' && arr.length >= 4) {
              q = 3;
            } else if (question === 'q5' && arr.length >= 5) {
              q = 4;
            } else if (question === 'q6' && arr.length >= 6) {
              q = 5;
            } else if (question === 'q7' && arr.length >= 7) {
              q = 6;
            }
            if (arr[q][1] === 1) {
              answer = 'Maybe';
              totalSelfAssessmentData = totalSelfAssessmentData + 1;
            } else if (arr[q][1] === 2) {
              answer = 'Yes';
            } else {
              answer = 'No';
              totalSelfAssessmentData = totalSelfAssessmentData + 2;
            }
          }
        }
        if (sType === 'SELF_ASSESSMENT_performance') {
          // tslint:disable-next-line:prefer-for-of
          for (let i = 0; i < arr.length; i++) {
            if (arr[i][1] === '0') {
              answer = 'Poor';
            }
            if (arr[i][1] === '1') {
              answer = 'Average';
              totalSelfAssessmentData = totalSelfAssessmentData + 1;
            }
            if (arr[i][1] === '2'){
              answer = 'Great';
              totalSelfAssessmentData = totalSelfAssessmentData + 2;
            }
           }
        }
        return totalSelfAssessmentData;
      }

    orderTaxiHander(key: string, value: string): number {
        let result = 0;

        if (key === 'ORDERTAXI1' && value.toLowerCase() === 'taxilogo3') {
            result = 1;
        }
        if (key === 'ORDERTAXI2' && value.toLowerCase() === 'a2z mall') {
            result = 1;
        }
        if (key === 'ORDERTAXI3' && value.toLowerCase() === 'saver: r115') {
            result = 1;
        }
        if (key === 'ORDERTAXI4' && (value.toLowerCase() === 'yes, thank you.' || value.toLowerCase() === 'yes')) {
            result = 1;
        }

        return result;
    }

}
