import { Component, ElementRef, HostListener, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

export enum KEY_CODE {
  N_KEY = 78,
  B_KEY = 66
}

@Component({
  selector: 'app-video-buttons3',
  templateUrl: './video-buttons.component3.html',
  styleUrls: ['./video-buttons.component.css']
})
export class VideoButtons3Component implements OnInit, OnDestroy {

  @ViewChild('hidden')
  hiddenElement!: ElementRef;

  videoName = '';
  nrLeftClicks = 0;
  nrLeftClicksDisplay = '00';
  nrRightClicks = 0;
  nrRightClicksDisplay = '00';
  greenCircle = 'transparent';
  greyCircle = 'transparent';
  bShowGreenButtonBorder = 0;
  bShowGreyButtonBorder = 0;
  objArrowButtonClicks = {};

  urlSafe: SafeResourceUrl = '';
  // tslint:disable-next-line:max-line-length
  // url = 'https://www.dailymotion.com/embed/video/k5w5wLZvFopetNwSrXv?sharing-enable=0&scaleMode=fill&queue-enable=false&autoplay=false&related=0&queue-autoplay-next=false';
  // url = 'https://screencast-o-matic.com/player/c3Qq2uVO42Y?width=100%&height=100%&ff=1&title=0&controls=0';
  url = 'https://screencast-o-matic.com/player/c3Qq2uVO42Y?width=100%&height=100%&ff=1&title=0&controls=0';

  constructor(private route: ActivatedRoute, private sanitizer: DomSanitizer) {
  }

  @HostListener('window:keyup', ['$event'])

  keyEvent(event: KeyboardEvent): void {

    // console.log('event', event);

    if (event.key === 'b' || event.key === 'B') {
      this.clickImageArea('rightToLeft');
    }

    if (event.key === 'n' || event.key === 'N') {
      this.clickImageArea('leftToRight');
    }
  }

  ngOnInit(): void {
    this.videoName = this.route.snapshot.url[0].path;
    if (this.videoName === 'video1') {
      localStorage.setItem('video1', '');
    }
    if (this.videoName === 'video2') {
      localStorage.setItem('video2', '');
    }
    if (this.videoName === 'video3') {
      localStorage.setItem('video3', '');
    }
    this.urlSafe = this.sanitizer.bypassSecurityTrustResourceUrl(this.url);
  }


  ngOnDestroy(): void {
  }

  onIframeClick(): void {
    this.hiddenElement.nativeElement.focus();
  }

  clickImageArea(sName: string): void {

    const color = '#ccc';

    if (sName === 'rightToLeft') {
      this.nrLeftClicks++;
      this.greenCircle = (this.greenCircle === 'transparent' ? color : 'transparent');
      this.greyCircle = 'transparent';
      this.nrLeftClicksDisplay = this.nrLeftClicks.toString();
      if (this.nrLeftClicks < 10) {
        this.nrLeftClicksDisplay = '0' + this.nrLeftClicks;
      }
      this.bShowGreenButtonBorder = 1;
      this.bShowGreyButtonBorder = 0;
    } else {
      this.nrRightClicks++;
      this.greyCircle = (this.greyCircle === 'transparent' ? color : 'transparent');
      this.greenCircle = 'transparent';
      this.nrRightClicksDisplay = this.nrRightClicks.toString();
      if (this.nrRightClicks < 10) {
        this.nrRightClicksDisplay = '0' + this.nrRightClicks;
      }
      this.bShowGreyButtonBorder = 1;
      this.bShowGreenButtonBorder = 0;
    }

    this.objArrowButtonClicks = {videoName: this.videoName, rightClicks: this.nrRightClicks, leftClicks: this.nrLeftClicks};

    localStorage.setItem(this.videoName, JSON.stringify(this.objArrowButtonClicks));
  }

  onKeyRightToLeft(event: any): void {
    // console.log('onKeyRightToLeft', event);
  }

}
