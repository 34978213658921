import { Component, OnInit, OnDestroy } from '@angular/core';

@Component({
  selector: 'app-car-interior-svg',
  templateUrl: './car-interior-svg.component.svg',
  styleUrls: []
})
export class CarInteriorSvgComponent implements OnInit, OnDestroy {

  sideMirrors = 'transparent';
  steeringWheel = 'transparent';
  gearLever = 'transparent';
  turnSignalLeaver = 'transparent';
  wiperLeaver = 'transparent';
  clutch = 'transparent';
  break = 'transparent';
  petrol = 'transparent';
  rearMirror = 'transparent';
  emergencyFlasher = 'transparent';
  handbreak = 'transparent';
  windscreenWipers = 'transparent';
  hooter = 'transparent';
  screen = 'transparent';
  leftDoorHandle = 'transparent';
  rightDoorHandle = 'transparent';
  middleVent = 'transparent';
  sideVents = 'transparent';
  topVent = 'transparent';
  gloveBox = 'transparent';
  dashboard = 'transparent';
  speedometer = 'transparent';

  arrCarInteriorClicks: string[] = [];

  constructor(){}

  ngOnInit(): void {
    localStorage.setItem('arrCarInteriorClicks', '');
  }

  ngOnDestroy(): void {
  }

  clickImageArea(sName: string): void {

    const blockColor = '#1ab394';
    let bAdd = true;

    if (sName === 'Steering Wheel') {
      this.steeringWheel = (this.steeringWheel === 'transparent' ? blockColor : 'transparent');
      bAdd = (this.steeringWheel === 'transparent' ? false : true);
    } else if (sName === 'Gear Leaver') {
      this.gearLever = (this.gearLever === 'transparent' ? blockColor : 'transparent');
      bAdd = (this.gearLever === 'transparent' ? false : true);
    } else if (sName === 'Turn Signal Indicator') {
      this.turnSignalLeaver = (this.turnSignalLeaver === 'transparent' ? blockColor : 'transparent');
      bAdd = (this.turnSignalLeaver === 'transparent' ? false : true);
    } else if (sName === 'Wiper Leaver') {
      this.wiperLeaver = (this.wiperLeaver === 'transparent' ? blockColor : 'transparent');
      bAdd = (this.wiperLeaver === 'transparent' ? false : true);
    } else if (sName === 'Clutch') {
      this.clutch = (this.clutch === 'transparent' ? blockColor : 'transparent');
      bAdd = (this.clutch === 'transparent' ? false : true);
      this.break = (this.break === 'transparent' ? blockColor : 'transparent');
      bAdd = (this.break === 'transparent' ? false : true);
      this.petrol = (this.petrol === 'transparent' ? blockColor : 'transparent');
      bAdd = (this.petrol === 'transparent' ? false : true);

    } else if (sName === 'Break') {
      this.clutch = (this.clutch === 'transparent' ? blockColor : 'transparent');
      bAdd = (this.clutch === 'transparent' ? false : true);
      this.break = (this.break === 'transparent' ? blockColor : 'transparent');
      bAdd = (this.break === 'transparent' ? false : true);
      this.petrol = (this.petrol === 'transparent' ? blockColor : 'transparent');
      bAdd = (this.petrol === 'transparent' ? false : true);

    } else if (sName === 'Petrol') {
      this.clutch = (this.clutch === 'transparent' ? blockColor : 'transparent');
      bAdd = (this.clutch === 'transparent' ? false : true);
      this.break = (this.break === 'transparent' ? blockColor : 'transparent');
      bAdd = (this.break === 'transparent' ? false : true);
      this.petrol = (this.petrol === 'transparent' ? blockColor : 'transparent');
      bAdd = (this.petrol === 'transparent' ? false : true);

    } else if (sName === 'Rear Mirror') {
      this.rearMirror = (this.rearMirror === 'transparent' ? blockColor : 'transparent');
      bAdd = (this.rearMirror === 'transparent' ? false : true);
    } else if (sName === 'Emergency Flasher') {
      this.emergencyFlasher = (this.emergencyFlasher === 'transparent' ? blockColor : 'transparent');
      bAdd = (this.emergencyFlasher === 'transparent' ? false : true);
    } else if (sName === 'Hand Break') {
      this.handbreak = (this.handbreak === 'transparent' ? blockColor : 'transparent');
      bAdd = (this.handbreak === 'transparent' ? false : true);
    } else if (sName === 'Windscreen Wipers') {
      this.windscreenWipers = (this.windscreenWipers === 'transparent' ? blockColor : 'transparent');
      bAdd = (this.windscreenWipers === 'transparent' ? false : true);
    } else if (sName === 'Hooter') {
      this.hooter = (this.hooter === 'transparent' ? blockColor : 'transparent');
      bAdd = (this.hooter === 'transparent' ? false : true);
    } else if (sName === 'Screen') {
      this.screen = (this.screen === 'transparent' ? blockColor : 'transparent');
      bAdd = (this.screen === 'transparent' ? false : true);
    } else if (sName === 'Side Mirrors') {
      this.sideMirrors = (this.sideMirrors === 'transparent' ? blockColor : 'transparent');
      bAdd = (this.sideMirrors === 'transparent' ? false : true);
    } else if (sName === 'Left Door Handle') {
      this.leftDoorHandle = (this.leftDoorHandle === 'transparent' ? blockColor : 'transparent');
      bAdd = (this.leftDoorHandle === 'transparent' ? false : true);
    } else if (sName === 'Right Door Handle') {
      this.rightDoorHandle = (this.rightDoorHandle === 'transparent' ? blockColor : 'transparent');
      bAdd = (this.rightDoorHandle === 'transparent' ? false : true);
    } else if (sName === 'Middle Vent') {
      this.middleVent = (this.middleVent === 'transparent' ? blockColor : 'transparent');
      bAdd = (this.middleVent === 'transparent' ? false : true);
    } else if (sName === 'Side Vent') {
      this.sideVents = (this.sideVents === 'transparent' ? blockColor : 'transparent');
      bAdd = (this.sideVents === 'transparent' ? false : true);
    } else if (sName === 'Top Vent') {
      this.topVent = (this.topVent === 'transparent' ? blockColor : 'transparent');
      bAdd = (this.topVent === 'transparent' ? false : true);
    } else if (sName === 'Glove Box') {
      this.gloveBox = (this.gloveBox === 'transparent' ? blockColor : 'transparent');
      bAdd = (this.gloveBox === 'transparent' ? false : true);
    } else if (sName === 'Dashboard') {
      this.dashboard = (this.dashboard === 'transparent' ? blockColor : 'transparent');
      bAdd = (this.dashboard === 'transparent' ? false : true);
    } else if (sName === 'Speedometer') {
      this.speedometer = (this.speedometer === 'transparent' ? blockColor : 'transparent');
      bAdd = (this.speedometer === 'transparent' ? false : true);
    }
    if (bAdd) {
        this.arrCarInteriorClicks.indexOf(sName) === -1 ? this.arrCarInteriorClicks.push(sName) : console.log('This item already exists');
    } else {
        const arrIndex = this.arrCarInteriorClicks.indexOf(sName);
        if (arrIndex > -1) {
            this.arrCarInteriorClicks.splice(arrIndex, 1);
        }
    }

    localStorage.setItem('arrCarInteriorClicks', JSON.stringify(this.arrCarInteriorClicks));
  }

}
