import { Component, OnInit } from '@angular/core';
import { AuthService } from '../_services/auth.service';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.css']
})
export class ForgotPasswordComponent implements OnInit {

  public frmSignup: FormGroup;

  errorMessage = '';
  isResetPasswordFailed = false;

  constructor(private authService: AuthService, private router: Router, private fb: FormBuilder) { 
    this.frmSignup = this.createSignupForm();
  }

  ngOnInit(): void {
  }

  createSignupForm(): FormGroup {
    return this.fb.group(
      {
        email: [
          null,
          Validators.compose([Validators.email, Validators.required])
        ],
      },
    );
  }

  onSubmit(): void {

    const passwordResetUrl = environment.passwordResetUrl;

    this.authService.resetPasswordToken(this.frmSignup.value.email, passwordResetUrl).subscribe(
      data => {
          this.isResetPasswordFailed = false;
          this.reloadPage();
      },
      err => {
        if (err.status !== 200) {
          this.errorMessage = err.error.message;
          this.isResetPasswordFailed = true;
        } else {
          this.errorMessage = 'Request for new password was successful';
          this.isResetPasswordFailed = false;
        }
      }
    );
  }

  reloadPage(): void {
    window.location.reload();
  }

  goToLogin(): void {
    this.router.navigate(['login/']);
  }
}
